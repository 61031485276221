import React, {useState, useEffect} from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './TermsAndConditions.css'
import $ from "jquery";
import { endpoints } from '../../endpoints/endpoint';


function TermsAndConditions() {
    const [terms, setTerms] = useState()
    const [allConditions, setAllConditions] = useState([])
    const [addErrorMessage, setAddErrorMessage] = useState()

    useEffect(() => {
        listAllTermsAndConditions()
    }, []);
    const listAllTermsAndConditions = (event, values) => {
      const obj = JSON.parse(localStorage.getItem("adminResponse"))
      
      $.ajax({
        url: endpoints.allTermsAndConditions,
        dataType: 'json',
        contentType: 'application/json',
        type: 'get',
        headers: {
          'Authorization': 'Bearer ' + obj.accessToken
        },
        // data: JSON.stringify(payload),
        processData: false,
        success: function( data, textStatus, jQxhr ){
          
          if(data && data.length > 0) {
            setAllConditions(data)
            }
        },
    });
    }
    const addTermsAndConditions = (e) =>{
        e.preventDefault(e);
        
        const obj = JSON.parse(localStorage.getItem("adminResponse"))
        const payload= {
            termsandcondition: terms
        }
        $.ajax({
          url: endpoints.addTermsAndConditions,
          type: 'post',
          headers: {
            'Authorization': 'Bearer ' + obj.accessToken
          },
          data: JSON.stringify(payload),
          contentType: 'application/json',
          processData: false,
          success: function( data, textStatus, jQxhr ){
            
            if(data.message == "terms and condition added successfully") {
                setAddErrorMessage("terms and condition added successfully")
            } else {
                setAddErrorMessage("terms and condition not added ")
            }
          },
      });
    

    }
    return (
        <>
        <Sidebar currentPage="t_c"/>
        <div class="content-wrapper" style={{backgroundColor:'white'}}>
        <button type="button" class="btn btn-success btn-new" data-toggle="modal" data-target="#modal-default">Add New</button>
        <div class="card " style={{width:'50rem'}}>
            <div class="card-header" style={{borderBottom:'0px'}}>
            <h3 className='card-header'>Terms and Conditions</h3>
            </div>
            <div className='card-body'>
            {allConditions.map((item, index) => { return <div class="form-group"   key={index} style={{padding:'20px'}}>
                    <p>{allConditions.termsandcondition}</p>
                </div>
            })}
                <div class="card-body">
            <span class="right badge-ban badge badge-success"  data-toggle="modal" data-target="#modal-default1">Edit</span>
            <span class="right badge-ban badge badge-danger">Delete</span>
            </div>
             </div>
             
        </div>
        </div>
        <div className="modal fade show" id="modal-default">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add Terms and Conditions</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
            <div class="modal-body">
            <label>Terms and Conditions</label>
            <textarea type="textarea" name="message"  value={terms} onChange={(e)=>{setTerms(e.target.value)}} placeholder="Full Name" class="form-control"  style={{marginBottom:'10px'}} required/>
            {addErrorMessage && <div class="alert alert-success" style={{height:'50px'}} role="alert"><p>{addErrorMessage}</p></div> }
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              <button type="submit" onClick={addTermsAndConditions} class="btn btn-primary">Add Terms and Conditions</button>
            </div>
            </form>
          </div>
         
        </div>
        
      </div>
        </>
    )
}

export default TermsAndConditions
