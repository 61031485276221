import { useState, useEffect } from "react";
import { endpoints } from "../endpoints/endpoint";
import $ from "jquery";
import useLocalStorage from "./useLocalStorage";
const useSettings = () => {
  const [prevCreditLimit, setPrevCreditLimit] = useState("0");
  const [creditLimit, setCreditLimit] = useState("0");
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [creditPurchasePlans, setCreditPurchasePlans] = useState([]);
  const [giftingPlans, setGiftingPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionloading, setActionLoading] = useState(false);
  const { user } = useLocalStorage();

  useEffect(() => {
    getCurrentCreditLimit();
    getCreditsPurchasePlans();
    getGiftingPlans();
  }, []);

  useEffect(() => {
    if (prevCreditLimit === creditLimit) {
      setEnableSaveBtn(false);
    } else {
      setEnableSaveBtn(true);
    }
  }, [creditLimit]);

  useEffect(() => console.log("enableSaveBtn", enableSaveBtn), [enableSaveBtn]);

  const getCurrentCreditLimit = async () => {
    await $.ajax({
      url: endpoints.getCurrentWithdrawalLimit,
      dataType: "json",
      type: "get",
      contentType: "application/json",
      processData: false,
      success: function (res, textStatus, jQxhr) {
        if (res.error == false && res.data) {
          const creditlimit = res.data.creditlimit.toString();
          setPrevCreditLimit(creditlimit);
          setCreditLimit(creditlimit);
        }
      },
      error: function (err) {
        console.error("err", err);
      },
    });
  };

  const updateCreditLimit = async ({ onSuccess, onFailure }) => {
    setActionLoading(true);
    await $.ajax({
      url: endpoints.setCreditLimit,
      dataType: "json",
      type: "post",
      contentType: "application/json",
      data: JSON.stringify({ creditlimit: creditLimit }),
      processData: false,
      success: async function (res, textStatus, jQxhr) {
        if (!res.error && res.data.acknowledged) {
          setEnableSaveBtn(false);
          await getCurrentCreditLimit();
          onSuccess();
          return;
        }
        onFailure();
      },
      error: function (err) {
        console.error("err", err);
        onFailure();
      },
      complete: function () {
        setActionLoading(false);
      },
    });
  };

  const getCreditsPurchasePlans = async () => {
    await $.ajax({
      url: endpoints.getCreditsPurchasePlans,
      dataType: "json",
      type: "get",
      contentType: "application/json",
      processData: false,
      success: function (res, textStatus, jQxhr) {
        if (res.data && Array.isArray(res.data)) {
          setCreditPurchasePlans(res.data);
          return;
        }
        throw "Invalid Response, Expected Array !!";
      },
      error: function (err) {
        console.error("err", err);
      },
    });
  };

  const updateCreditPurchasePlan = (plan, { onSuccess, onFailure }) => {
    setActionLoading(true);
    const { _id, status } = plan;
    const enable = status === "Enabled" ? false : true;
    const payload = { gifttokenId: _id, enable: enable };
    $.ajax({
      url: "http://localhost:2001/admin/enablecreditspurchaseplans",
      dataType: "json",
      type: "post",
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: async function (res, textStatus, jQxhr) {
        if (!res.error && res.data.acknowledged) {
          await getCreditsPurchasePlans();
          onSuccess();
          return;
        }
        onFailure();
      },
      error: function (err) {
        console.log("Err", err);
        onFailure();
      },
      complete: function () {
        setActionLoading(false);
      },
    });
  };

  const getGiftingPlans = async () => {
    setLoading(true);
    await $.ajax({
      url: endpoints.getGiftingPlans,
      dataType: "json",
      type: "get",
      contentType: "application/json",
      processData: false,
      success: function (res, textStatus, jQxhr) {
        if (res.data && Array.isArray(res.data)) {
          setGiftingPlans(res.data);
          return;
        }
        throw "Invalid Response, Expected Array !!";
      },
      error: function (err) {
        console.log("err", err);
      },
      complete: function () {
        setLoading(false);
      },
    });
  };

  const addGiftingPlan = (data, { onSuccess, onFailure }) => {
    if (!user || !data) return;
    const { accessToken } = user;
    const { giftName, credits, file } = data;

    const bodyFormData = new FormData();
    bodyFormData.append("giftname", giftName);
    bodyFormData.append("credit", credits);
    bodyFormData.append("file", file);

    setActionLoading(true);
    $.ajax({
      url: endpoints.addGiftingPlan,
      type: "post",
      data: bodyFormData,
      contentType: false,
      processData: false,
      success: async function (res, textStatus, jQxhr) {
        if (!res.error && res.data.acknowledged) {
          await getGiftingPlans();
          onSuccess();
          return;
        }
        onFailure();
      },
      error: function (err) {
        onFailure();
      },
      complete: function () {
        setActionLoading(false);
      },
    });
  };

  const updateGifting = (
    { giftId, giftName, credits },
    { onSuccess, onFailure }
  ) => {
    if ((!giftId, !giftName, !credits)) return;
    setActionLoading(true);
    const payload = { giftId: giftId, giftname: giftName, credit: credits };
    $.ajax({
      url: endpoints.editGiftingPlan,
      dataType: "json",
      type: "post",
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: async function (res, textStatus, jQxhr) {
        if (!res.error && res.data.acknowledged) {
          await getGiftingPlans();
          onSuccess();
          return;
        }
        onFailure();
      },
      error: function (err) {
        console.log("Err", err);
        onFailure();
      },
      complete: function () {
        setActionLoading(false);
      },
    });
  };

  const deleteGiftingPlan = (id, { onSuccess, onFailure }) => {
    setActionLoading(true);
    const payload = { giftId: id };
    $.ajax({
      url: endpoints.deleteGiftingPlan,
      dataType: "json",
      type: "post",
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: async function (res, textStatus, jQxhr) {
        if (!res.error && res.data.acknowledged) {
          await getGiftingPlans();
          onSuccess();
          return;
        }
        onFailure();
      },
      error: function (err) {
        console.log("Err", err);
        onFailure();
      },
      complete: function () {
        setActionLoading(false);
      },
    });
  };

  return {
    creditLimit,
    setCreditLimit,
    updateCreditLimit,
    enableSaveBtn,
    creditPurchasePlans,
    updateCreditPurchasePlan,
    giftingPlans,
    loading,
    actionloading,
    addGiftingPlan,
    updateGifting,
    deleteGiftingPlan,
  };
};

export default useSettings;
