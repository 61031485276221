import { endpoints } from "../endpoints/endpoint";
import $ from "jquery";
import { useState, useEffect } from "react";
import useLocalStorage from "./useLocalStorage";

const useTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [creditsHistory, setCreditsHistory] = useState([])

  const { getLoginResponseFromLocal } = useLocalStorage();

  useEffect(() => {
    getAllTransactions();
  }, []);

  const getAllTransactions = async () => {
    const response = getLoginResponseFromLocal();
    if (!response || !response.accessToken) return;
    setLoading(true);
    await $.ajax({
      url: endpoints.listAllPaymentHistory,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + response.accessToken,
      },
      timeout: 30000,
      processData: false,
      success: function (response, textStatus, jQxhr) {
        if (!Array.isArray(response)) return;
        setTransactions(response);
        const subscriptionTransaction = response.filter((res) => res.title === "subscription");
        const CreditsTransaction = response.filter((res) => res.title === "purchasecredit");
        setSubscriptionHistory(subscriptionTransaction)
        setCreditsHistory(CreditsTransaction)
      },
      error: function (jqXHR, exception) {
        console.error("Error", jqXHR);
      },
      complete: () => {
        setLoading(false);
      },
    });
  };

  return { loading, transactions, subscriptionHistory, creditsHistory };
};

export default useTransactions;
