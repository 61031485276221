const CreditPlans = [
  {
    id: "1",
    credit: "50",
    GBP: "14.99",
  },
  {
    id: "2",
    credit: "100",
    GBP: "24.99",
  },
  {
    id: "3",
    credit: "50",
    GBP: "59.99",
  },
  {
    id: "4",
    credit: "50",
    GBP: "114.99",
  },
];

export default CreditPlans;