"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvalidArnError = void 0;
/**
 * Error thrown when attempting to parse invalid ARNs
 */
class InvalidArnError extends Error {
    constructor(message = 'ARN must be a string on the form scheme:partition:service:region:accountId:resourcePart') {
        super(message);
    }
}
exports.InvalidArnError = InvalidArnError;
function parseResourcePart(resourcePart) {
    const parts = resourcePart.match(/^([^:/]+)\/(.+)$/) || resourcePart.match(/^(.+?):(.+?):(.+)$/);
    if (parts) {
        const [, type, id, qualifier] = parts;
        return { type, id, qualifier };
    }
    return { id: resourcePart };
}
function invalidArn(fail = false, message) {
    if (fail) {
        throw new InvalidArnError(message);
    }
    return null;
}
/**
 * Amazon Resource Names (ARNs) uniquely identify AWS resources.
 *
 * The following are the general formats for ARNs; the specific components and values used depend on the AWS service.
 * To use an ARN, replace the italicized text in the example with your own information.
 *
 * arn:partition:service:region:account-id:resource-id
 * arn:partition:service:region:account-id:resource-type/resource-id
 * arn:partition:service:region:account-id:resource-type:resource-id
 *
 * _partition_
 * The partition that the resource is in. For standard AWS Regions, the partition is aws. If you have resources in other
 * partitions, the partition is aws-partitionname. For example, the partition for resources in the China (Beijing)
 * Region is aws-cn.
 *
 * _service_
 * The service namespace that identifies the AWS product (for example, Amazon S3, IAM, or Amazon RDS).
 *
 * _region_
 * The Region that the resource resides in. The ARNs for some resources do not require a Region, so this component might
 * be omitted.
 *
 * _account-id_
 * The ID of the AWS account that owns the resource, without the hyphens. For example, 123456789012. The ARNs for some
 * resources don't require an account number, so this component might be omitted.
 *
 * _resource_ or _resource-type_
 * The content of this part of the ARN varies by service. A resource identifier can be the name or ID of the resource
 * (for example, user/Bob or instance/i-1234567890abcdef0) or a resource path. For example, some resource identifiers
 * include a parent resource (sub-resource-type/parent-resource/sub-resource) or a qualifier such as a version
 * (resource-type:resource-name:qualifier).
 *
 * https://docs.aws.amazon.com/general/latest/gr/aws-arns-and-namespaces.html
 */
class Arn {
    /**
     * Create an Arn object from ARN components
     *
     * @param components ARN components object (scheme and partition are optional)
     * @param [components.scheme=aws] Scheme
     * @param [components.partition=arn] Partition
     * @param components.service Service
     * @param components.region Region
     * @param components.accountId Account ID
     * @param components.resourcePart Resource part
     */
    constructor({ scheme = 'aws', partition = 'arn', service, region, accountId, resourcePart }) {
        this.scheme = scheme;
        this.partition = partition;
        this.service = service;
        this.region = region;
        this.accountId = accountId;
        this.resourcePart = resourcePart;
    }
    static parse(s, fail = false) {
        // noinspection SuspiciousTypeOfGuard
        if (typeof s !== 'string') {
            return invalidArn(fail);
        }
        const [scheme, partition, service, region, accountId, ...resourceParts] = s.split(':');
        const resourcePart = resourceParts.join(':');
        if (!resourcePart) {
            return invalidArn(fail);
        }
        return new Arn({
            scheme,
            partition,
            service,
            region,
            accountId,
            resourcePart
        });
    }
    /**
     * Get a parsed representation of the resource part. Note that this property cannot be mutated, but if
     * resourcePart is mutated, resource will reflect the change.
     * @returns ArnResource
     */
    get resource() {
        return parseResourcePart(this.resourcePart);
    }
    /**
     * Format this Arn object into an ARN string.
     * @returns string
     */
    format() {
        const { scheme, partition, service, region, accountId, resourcePart } = this;
        return [scheme, partition, service, region, accountId, resourcePart].join(':');
    }
    toString() {
        return this.format();
    }
}
exports.default = Arn;
