import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import "./Members.css";
import $ from "jquery";
import { animatedCoin } from "../../Lottie/gift.json";
import { endpoints } from "../../endpoints/endpoint";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import {
  Card,
  Button,
  Container,
  Col,
  Row,
  Nav,
  NavDropdown,
  Navbar,
  Badge,
  Carousel,
  // Modal,
} from "react-bootstrap";
import { MdDateRange } from "react-icons/md";
import { hover } from "@testing-library/user-event/dist/hover";
import Modal from "./Modal";

import { DatePicker, Space } from "antd";
import { AppContext } from "../../context/AppContext";
import useUserDetails from "../../hooks/useUserDetails";
import BanModal from "./BanModal";
import toast, { Toaster } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import SuspendModal from "./SuspendModal";
import Utils from "../../utils/utils";
import useLives from "../../hooks/useLives";

const { RangePicker } = DatePicker;

// moment(value).format("DD-MM-YYYY")
function Members(props) {
  const history = useHistory();
  const frontEndUrl = process.env.REACT_APP_FRONT_END_URL;
  const [banMember, setBanMember] = useState(false);
  const [suspendMember, setSuspendMember] = useState(false);
  const [selectedTab, setSelectedTab] = useState("allusers");

  const [socketEvent, setSocketEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [allChatHistory, setAllChatHistory] = useState([]);
  const [reportedStreamerDetails, setReportedStreamerDetails] = useState([]);

  const [selectedMember, setSelectedMember] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [banOpen, setBanOpen] = useState(false);
  const [banLoading, setBanLoading] = useState(-1);
  const [suspendOpen, setSuspendOpen] = useState(false);
  const [supendLoadingIndex, setSupendLoadingIndex] = useState(-1);
  const [online, setOnline] = useState(false);

  const {
    allUsersCollection,
    allCreatorsCollection,
    allMembersCollection,
    bannedCreatorsCollection,
    bannedMembersCollection,
    suspendedUsersCollection,
    reportedUsersCollection,
    listAllUsers,
    listAllBannedUsers,
    listAllSuspendedUsers,
    setAllUsersCollection,
  } = useUserDetails();

  const { liveVideos } = useContext(AppContext);

  useEffect(() => {
    console.log("livevideos", liveVideos);
  }, [liveVideos]);

  useEffect(() => {
    console.log("allUsersCollection", allUsersCollection);
  }, [allUsersCollection]);

  useEffect(() => {
    console.log("title", props.title);

    $("#searchInput").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#allUsersTable tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      $("#allCreatorsTable tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      $("#allMembersTable tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      $("#allBannedCreators tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      $("#allBannedMembers tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      $("#allSuspendedUsersTable tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
      $("#allReportedUsersTable tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  }, [selectedTab]);

  useEffect(() => {
    switch (selectedTab) {
      case "allusers":
        handleDateChange("allUsersTable");
        break;
      case "creators":
        handleDateChange("allCreatorsTable");
        break;
      case "members":
        handleDateChange("allMembersTable");
        break;
      case "bannedcreators":
        handleDateChange("allBannedCreators");
        break;
      case "bannedmembers":
        handleDateChange("allBannedMembers");
        break;
      case "suspendedmembers":
        handleDateChange("allSuspendedUsersTable");
        break;
      case "reportedcreators":
        handleDateChange("allReportedUsersTable");
        break;
    }
  }, [selectedDateRange, selectedTab]);

  const handleDateChange = (tableId) => {
    const startDateStr = selectedDateRange.length > 0 && selectedDateRange[0];
    const endDateStr = selectedDateRange.length > 0 && selectedDateRange[1];

    const startDate = startDateStr
      ? Utils.parseStringDateToObject(startDateStr)
      : null;
    const endDate = endDateStr
      ? Utils.parseStringDateToObject(endDateStr)
      : null;

    console.log("SelectedDateRange", startDate, endDate);

    $(`#${tableId} tr`).each(function (rowIndex, row) {
      const dateText = $(row).find("td:first").text();
      const newDate = Utils.changeDateStringFormat(dateText);
      const parsedDate = new Date(newDate);

      console.log("parsedDate", parsedDate);

      if (!startDate && !endDate) {
        $(row).show();
      } else if (
        startDate &&
        parsedDate >= startDate &&
        endDate &&
        parsedDate <= endDate
      ) {
        $(row).show();
      } else {
        $(row).hide();
      }
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeModal1 = () => {
    setShowModal1(false);
  };

  const listAllMembers = (event, values) => {};

  const memberBanClick = (userId) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: userId,
      adminId: obj.adminId,
    };

    $.ajax({
      url: endpoints.bannMembers,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data.message == "user baned") {
          setBanMember(true);
          listAllMembers();
        } else {
          setBanMember(false);
        }
      },
    });
  };
  const memberSuspendClick = (userId) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: userId,
      adminId: obj.adminId,
    };

    $.ajax({
      url: endpoints.suspendMembers,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data.message == "user suspended") {
          setSuspendMember(true);
          listAllMembers();
        } else {
          setSuspendMember(false);
        }
      },
    });
  };
  const memberUnbanClick = async (userId, index) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: userId,
      adminId: obj.adminId,
    };

    setBanLoading(index);
    await $.ajax({
      url: endpoints.UnbanMember,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        listAllBannedUsers();
        toast.success("Unban Sucessfully");
      },
      complete: function () {
        setBanLoading(-1);
        listAllUsers();
      },
    });
  };

  const memberUnsuspendClick = async (userId, index) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: userId,
      adminId: obj.adminId,
    };
    setSupendLoadingIndex(index);
    await $.ajax({
      url: endpoints.unSuspendMember,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        toast.success("Unsuspended Successfully!");
        listAllSuspendedUsers();
      },
      complete: function () {
        setSupendLoadingIndex(-1);
        listAllUsers();
      },
    });
  };
  const viewChatClick = (item) => {
    setUserName(item.reportuserName);
    setShowModal(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      room: item.room,
      userId: item.userId,
    };

    $.ajax({
      url: endpoints.userChatHistory,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setChatHistory(data);
      },
      //    complete: function() {
      //     listAllSuspendedMembers()
      // },
    });
  };

  const onViewProfileClicked = (e, id) => {
    e.preventDefault();
    try {
      const adminLoginResponse = JSON.parse(
        localStorage.getItem("adminResponse")
      );
      const loginRespone = {
        message: adminLoginResponse.message,
        accessToken: adminLoginResponse.accessToken,
        refreshToken: adminLoginResponse.refreshToken,
        userId: adminLoginResponse.adminId,
        userName: adminLoginResponse.userName,
        role: adminLoginResponse.role,
        roomID: adminLoginResponse.roomID,
        profilepic: adminLoginResponse.profilepic,
      };
      const base64String = btoa(JSON.stringify(loginRespone));
      const loginUrl = `${frontEndUrl}/userprofile/${id}/${encodeURIComponent(
        base64String
      )}/123`;
      window.open(loginUrl);
    } catch (e) {}
  };

  const viewStreamClick = (item) => {
    setUserName(item.reportuserName);
    setShowModal1(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      room: item.room,
    };

    $.ajax({
      url: endpoints.allChatHistory,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setAllChatHistory(data);
      },
      //    complete: function() {
      //     listAllSuspendedMembers()
      // },
    });
  };

  const viewMember = (member) => {
    setSelectedMember(member);
  };

  const handleBanMember = (member) => {
    setSelectedMember(member);
    setBanOpen(true);
  };

  const handleSuspendMember = (member) => {
    setSelectedMember(member);
    setSuspendOpen(true);
  };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const dateChanges = (ranges) => {
    console.log(ranges);
  };

  useEffect(() => {
    console.log("allUsersCollection", allUsersCollection);
  }, [allUsersCollection]);

  const isThisUserLive = (id) => {
    return liveVideos.find((video) => video._id == id);
  };

  return (
    <>
      <Toaster position="top-center"></Toaster>
      <Sidebar currentPage="members" />
      <BanModal
        BanModalopen={banOpen}
        onBanModalClose={() => setBanOpen(false)}
        banData={selectedMember}
        onBanSuccess={function () {
          listAllBannedUsers();
          listAllUsers();
        }}
      />
      <SuspendModal
        suspendOpenModal={suspendOpen}
        suspendCloseModal={() => setSuspendOpen(false)}
        suspendData={selectedMember}
        onSuspendSucess={() => {
          listAllSuspendedUsers();
          listAllUsers();
        }}
      />
      <div class="content-wrapper">
        <div class="card-header">
          <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>All Users</h3>
        </div>
        <Tabs
          defaultActiveKey="profile"
          style={{ marginTop: "20px" }}
          id="uncontrolled-tab-example"
          activeKey={selectedTab}
          onSelect={(k) => setSelectedTab(k)}
          className="mb-3"
        >
          <Tab eventKey="allusers" title="All Users"></Tab>
          <Tab eventKey="creators" title="Creators"></Tab>
          <Tab eventKey="members" title="Members"></Tab>
          <Tab eventKey="bannedcreators" title="Banned Creators"></Tab>
          <Tab eventKey="bannedmembers" title="Banned Members"></Tab>
          <Tab eventKey="suspendedmembers" title="Suspended Users"></Tab>
          <Tab eventKey="reportedcreators" title="Reported Creators"></Tab>
        </Tabs>
        <div class="card ">
          {selectedTab == "allusers" && (
            <>
              <div class="card-header row">
                <h3 class="card-title">All Users</h3>
              </div>
              <input
                className="form-control searchbar"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    onChange={(dayjs, dateString) => {
                      setSelectedDateRange(dateString ?? []);
                    }}
                    format="DD-MM-YYYY"
                  />
                </Space>
              </div>

              <div class="card-body">
                <table id="" class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Joined Date</th>
                      <th>Username</th>
                      <th>Nickname</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody id="allUsersTable">
                    {!loading &&
                      allUsersCollection.map((user, index) => {
                        const liveUser = isThisUserLive(user._id);

                        return (
                          <tr>
                            <td>
                              {moment(user.registration_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td style={{ position: "relative" }}>
                              {user?.username ?? "N/A"}{" "}
                              {liveUser != null && (
                                <div class="live-dot notify-live member_live_indication"></div>
                              )}
                            </td>
                            <td>{user?.nickname ?? "N/A"}</td>
                            <td>{user?.mail ?? "N/A"}</td>
                            <td>
                              {user?.mobile != ""
                                ? user?.mobile ?? "N/A"
                                : "N/A"}
                            </td>
                            <td>{user?.status ?? "N/A"}</td>
                            <td>
                              <div style={{ marginTop: "-20px", zIndex: "99" }}>
                                {user.status == "Banned" ? (
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-new"
                                    style={{ cursor: "pointer" }}
                                    disabled
                                  >
                                    Banned
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-new"
                                    onClick={() => handleBanMember(user)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Ban
                                  </button>
                                )}
                                {user.status === "Suspended" ? (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                      height: "100%",
                                      fontSize: "12px",
                                    }}
                                    disabled
                                  >
                                    Suspended
                                  </button>
                                ) : user.status == "Banned" ? (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                      height: "100%",
                                    }}
                                    disabled
                                  >
                                    Suspend
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    onClick={() => handleSuspendMember(user)}
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                    }}
                                  >
                                    Suspend
                                  </button>
                                )}
                                {/* <span class="right badge-ban badge badge-danger" onClick={() => memberBanClick(item._id)} style={{cursor:'pointer'}}>Ban</span>
                        <span class="right badge-ban badge badge-info" onClick={() => memberSuspendClick(item._id)} style={{cursor:'pointer'}}>Suspend</span> */}

                                <button
                                  type="button"
                                  className="btn btn-info btn-new"
                                  onClick={() => {
                                    history.push(`/viewmember/${user._id}`);
                                  }}
                                >
                                  View
                                </button>
                                {/* <span class="right badge-ban badge badge-danger">View</span> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </>
          )}
          {selectedTab == "creators" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">All Creators</h3>
              </div>
              <input
                className="form-control searchbar"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={(dayjs, dateString) =>
                      setSelectedDateRange(dateString ?? [])
                    }
                  />
                </Space>
              </div>

              <div class="card-body">
                <table
                  id="allMembersTable"
                  class="table table-bordered table-sm"
                >
                  <thead>
                    <tr>
                      <th>Joined Date</th>
                      <th>Username</th>
                      <th>Nickname</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="allCreatorsTable">
                    {!loading &&
                      allCreatorsCollection.map((creator, index) => {
                        const liveUser = isThisUserLive(creator._id);
                        return (
                          <tr>
                            <td>
                              {moment(creator.registration_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td style={{ position: "relative" }}>
                              {creator?.username ?? "N/A"}{" "}
                              {liveUser != null && (
                                <div class="live-dot notify-live member_live_indication"></div>
                              )}
                            </td>
                            <td>{creator?.nickname ?? "N/A"}</td>
                            <td>{creator?.mail ?? "N/A"}</td>
                            <td>
                              {creator?.mobile != ""
                                ? creator?.mobile ?? "N/A"
                                : "N/A"}
                            </td>
                            <td>{creator?.status ?? "N/A"}</td>
                            <td>
                              <div style={{ marginTop: "-20px", zIndex: "99" }}>
                                {creator?.status == "Banned" ? (
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-new"
                                    style={{ cursor: "pointer" }}
                                    disabled
                                  >
                                    Banned
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-new"
                                    onClick={() => handleBanMember(creator)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Ban
                                  </button>
                                )}
                                {creator.status == "Suspended" ? (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                      fontSize: "13px",
                                    }}
                                    disabled
                                  >
                                    Suspended
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    onClick={() => handleSuspendMember(creator)}
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                    }}
                                  >
                                    Suspend
                                  </button>
                                )}
                                {/* <span class="right badge-ban badge badge-danger" onClick={() => memberBanClick(item._id)} style={{cursor:'pointer'}}>Ban</span>
                        <span class="right badge-ban badge badge-info" onClick={() => memberSuspendClick(item._id)} style={{cursor:'pointer'}}>Suspend</span> */}

                                <button
                                  type="button"
                                  className="btn btn-info btn-new"
                                  onClick={() => {
                                    history.push(`/viewmember/${creator._id}`);
                                  }}
                                >
                                  View
                                </button>
                                {/* <span class="right badge-ban badge badge-danger">View</span> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </>
          ) : null}
          {selectedTab == "members" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">All Members</h3>
              </div>
              <input
                className="form-control searchbar"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={(dayjs, dateString) =>
                      setSelectedDateRange(dateString ?? [])
                    }
                  />
                </Space>
              </div>

              <div class="card-body">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Joined Date</th>
                      <th>Username</th>

                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="allMembersTable">
                    {!loading &&
                      allMembersCollection.map((member, index) => {
                        return (
                          <tr>
                            <td>
                              {moment(member.registration_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>{member?.username ?? "N/A"}</td>
                            <td>{member?.mail ?? "N/A"}</td>
                            <td>
                              {member?.mobile != ""
                                ? member?.mobile ?? "N/A"
                                : "N/A"}
                            </td>
                            <td>{member?.status ?? "N/A"}</td>
                            <td>
                              <div style={{ marginTop: "-20px", zIndex: "99" }}>
                                {member?.status == "Banned" ? (
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-new"
                                    style={{ cursor: "pointer" }}
                                    disabled
                                  >
                                    Banned
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-new"
                                    onClick={() => handleBanMember(member)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Ban
                                  </button>
                                )}
                                {member.status == "Suspended" ? (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                    }}
                                  >
                                    Suspended
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-success btn-new"
                                    onClick={() => handleSuspendMember(member)}
                                    style={{
                                      cursor: "pointer",
                                      width: "105px",
                                    }}
                                  >
                                    Suspend
                                  </button>
                                )}
                                {/* <span class="right badge-ban badge badge-danger" onClick={() => memberBanClick(item._id)} style={{cursor:'pointer'}}>Ban</span>
                        <span class="right badge-ban badge badge-info" onClick={() => memberSuspendClick(item._id)} style={{cursor:'pointer'}}>Suspend</span> */}
                                <button
                                  type="button"
                                  className="btn btn-info btn-new"
                                  onClick={() => {
                                    history.push(`/viewmember/${member._id}`);
                                  }}
                                >
                                  View
                                </button>
                                {/* <span class="right badge-ban badge badge-danger">View</span> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </>
          ) : null}
          {selectedTab == "bannedcreators" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">Banned Creators</h3>
              </div>
              <input
                class="form-control"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={(dayjs, dateString) => {
                      setSelectedDateRange(dateString ?? []);
                    }}
                  />
                </Space>
              </div>
              <div class="card-body col-lg-12">
                <table id="" class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th>Banned Date</th>
                      <th>Banned Creator</th>
                      <th>Banned by</th>
                      <th>Reason</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="allBannedCreators">
                    {!loading &&
                      bannedCreatorsCollection.map((creator, index) => {
                        console.log("creator", creator);
                        return (
                          <tr>
                            <td>
                              {moment(creator.banned_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{creator.username}</td>
                            <td>{creator.banedby}</td>
                            <td>{creator.reason}</td>
                            <td>
                              <div style={{ marginTop: "-20px" }}>
                                <button
                                  type="button"
                                  class="btn btn-danger btn-new"
                                  onClick={() =>
                                    memberUnbanClick(creator._id, index)
                                  }
                                  style={{ cursor: "pointer", width: "105px" }}
                                >
                                  {banLoading == index ? (
                                    <div class="loader_">
                                      <BeatLoader
                                        color="#fff"
                                        loading
                                        size={10}
                                        className="puff_loader"
                                      />
                                    </div>
                                  ) : (
                                    "Unban"
                                  )}
                                </button>
                              </div>
                            </td>
                            {/* <td><span class="right badge-ban badge badge-danger" onClick={() => memberUnbanClick(item._id)}  style={{cursor:'pointer'}}>Unban</span></td> */}
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          {selectedTab == "bannedmembers" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">Banned Members</h3>
              </div>
              <input
                class="form-control"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={(dayjs, dateString) => {
                      setSelectedDateRange(dateString ?? []);
                    }}
                  />
                </Space>
              </div>
              <div class="card-body col-lg-6">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Banned Date</th>
                      <th>Banned Member</th>
                      <th>Banned by</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="allBannedMembers">
                    {!loading &&
                      bannedMembersCollection.map((member, index) => {
                        return (
                          <tr>
                            <td>
                              {moment(member.banned_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{member.username}</td>
                            <td>{member.banedby}</td>
                            <td>
                              <div style={{ marginTop: "-20px" }}>
                                <button
                                  type="button"
                                  class="btn btn-danger btn-new"
                                  onClick={() => memberUnbanClick(member._id)}
                                  style={{ cursor: "pointer", width: "105px" }}
                                >
                                  Unban
                                </button>
                              </div>
                            </td>
                            {/* <td><span class="right badge-ban badge badge-danger" onClick={() => memberUnbanClick(item._id)}  style={{cursor:'pointer'}}>Unban</span></td> */}
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          {selectedTab == "suspendedmembers" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">Suspended Users</h3>
              </div>
              <input
                class="form-control"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={(dayjs, dateString) => {
                      setSelectedDateRange(dateString ?? []);
                    }}
                  />
                </Space>
              </div>
              <div class="card-body  col-lg-12">
                <table class="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th>Suspended Date</th>
                      <th>Suspended User</th>
                      <th>Suspended by</th>
                      <th>Suspended till</th>
                      <th>Reason</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="allSuspendedUsersTable">
                    {!loading &&
                      suspendedUsersCollection.map(function (user, index) {
                        const suspended = user.suspendedby;
                        const date = Utils.getDate(user.suspendedDuration);
                        return (
                          <tr>
                            <td>{user?.suspended_date ?? "N/A"}</td>
                            <td>{user?.username ?? "N/A"}</td>
                            <td>{suspended?.adminName ?? "N/A"}</td>
                            <td>{date}</td>
                            <td>{suspended.reason}</td>
                            <td>
                              <div style={{ marginTop: "-20px" }}>
                                <button
                                  type="button"
                                  class="btn btn-success btn-new"
                                  onClick={() =>
                                    memberUnsuspendClick(user._id, index)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    width: "130px",
                                  }}
                                >
                                  {supendLoadingIndex != index ? (
                                    "UnSuspend"
                                  ) : (
                                    <div class="loader_">
                                      <BeatLoader
                                        color="#fff"
                                        loading
                                        size={10}
                                        className="puff_loader"
                                      />
                                    </div>
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          {selectedTab == "reportedcreators" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">Reported Creators</h3>
              </div>
              <input
                class="form-control"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div className="datePicker">
                <label for="" style={{ fontWeight: "normal" }}>
                  Filter By Date
                </label>{" "}
                <br />
                <Space direction="vertical" size={12}>
                  <RangePicker
                    format="DD-MM-YYYY"
                    onChange={(dayjs, dateString) => {
                      setSelectedDateRange(dateString ?? []);
                    }}
                  />
                </Space>
              </div>
              <div class="card-body col-lg-12">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Reported Date</th>
                      <th>Reported User</th>
                      <th>Description</th>
                      <th>Reported by</th>
                    </tr>
                  </thead>
                  <tbody id="allReportedUsersTable">
                    {!loading &&
                      reportedUsersCollection.map((xUser, index) => {
                        console.log("Reported-Users", xUser);
                        return (
                          <tr>
                            <td>
                              {xUser?.date?.length > 10
                                ? Utils.getDate(xUser?.date)
                                : xUser?.date}
                            </td>
                            <td>
                              {xUser.streamer.nickname != null
                                ? `${xUser.streamer.username} @${xUser.streamer.nickname}`
                                : xUser.streamer.username}
                            </td>
                            <td>{xUser.reason}</td>
                            <td>
                              {xUser.user.nickname != null
                                ? `${xUser.user.username} @${xUser.user.nickname}`
                                : xUser.user.username}
                            </td>
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          {selectedTab == "reportedstreamers" ? (
            <>
              <div class="card-header row">
                <h3 class="card-title">Reported Streamers</h3>
              </div>
              <input
                class="form-control"
                id="searchInput"
                type="text"
                placeholder="Search.."
                style={{
                  marginBottom: "15px",
                  marginBottom: "15px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  width: "500px",
                }}
              />
              <div class="card-body col-lg-8">
                <table
                  id="reportedMembersTable"
                  class="table table-bordered table-sm"
                >
                  <thead>
                    <tr>
                      <th>Reported Date</th>
                      <th>Reported To</th>
                      <th>Reported By</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody id="reportedTable">
                    {!loading &&
                      reportedStreamerDetails.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.date}</td>
                            <td style={{ width: "190px" }}>
                              {item.streamer.username}
                            </td>
                            <td>{item.user.username}</td>
                            <td>{item.reason}</td>
                            {/* <td>
                              <span class="right badge-ban badge badge-danger" onClick={() => viewChatClick(item)} style={{cursor:'pointer'}}>Chat</span>
                              <span class="right badge-ban badge badge-info" onClick={() =>  viewStreamClick(item)} style={{cursor:'pointer'}}>All Chats</span></td> */}
                          </tr>
                        );
                      })}
                    {loading && <>Loading data....</>}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
        </div>

        {/* chat modal */}
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-head pay">
              {userName} chat history
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {chatHistory.length == 0 ? <p>No chats available</p> : null}
            {chatHistory.map((item, index) => (
              <p>
                {item.chat},{" "}
                <span style={{ marginLeft: "5px" }}>{item.time}</span>
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button className="bg-red-gradiant text-white" onClick={closeModal}>
              Close
            </Button>
            {/* <Button className="bg-red-gradiant text-white" href="/login" onClick={closeModal}>
                        login
                    </Button> */}
          </Modal.Footer>
        </Modal>

        {/*all chat modal */}

        <Modal show={showModal1} onHide={closeModal1}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-head pay">
              All chat history
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {allChatHistory.length == 0 ? <p>No chats available</p> : null}
            {allChatHistory.map((item, index) => (
              <p>
                <span style={{ marginRight: "5px" }}>{item.name}:</span>{" "}
                {item.chat} ,{" "}
                <span style={{ marginLeft: "5px" }}>{item.time}</span>
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="bg-red-gradiant text-white"
              onClick={closeModal1}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Members;
