import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Subscription.css";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";
import { Modal, Toast, ToastContainer } from "react-bootstrap";

function Subscription() {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [month, setMonth] = useState();
  const [amount, setAmount] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorColor, setErrorColor] = useState();
  const [subscriptionListDetails, setSubscriptionListDetails] = useState([]);
  const [editData, setEditData] = useState({});
  const [editErrorMessage, setEditErrorMessage] = useState();
  const [level, setLevel] = useState();
  const [benifits, setBenifits] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [goldAmount, setGoldAmount] = useState();
  const [goldDuration, setGoldDuration] = useState();
  const [goldDetails, setGoldDetails] = useState();
  const [silverAmount, setSilverAmount] = useState();
  const [silverDuration, setSilverDuration] = useState();
  const [silverDetails, setSilverDetails] = useState();
  const [bronzeAmount, setBronzeAmount] = useState();
  const [bronzeDuration, setBronzeDuration] = useState();
  const [bronzeDetails, setBronzeDetails] = useState();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState();
  const [fillError, setFillError] = useState(false);

  useEffect(() => {
    listAllSubscription();
  }, []);

  const clearAddForm = () => {
    setTitle("");
    setLevel("");
    setMonth("");
    setBenifits("");
    setAmount("");
    setErrorMessage("");
  };
  const listAllSubscription = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));

    $.ajax({
      url: endpoints.allSubscription,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data && data.response) {
          setSubscriptionListDetails(data.response);
        }
      },
    });
  };

  const addSubscription = () => {
    setErrorMessage("");
    setButtonDisabled(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    if (
      title &&
      description &&
      goldAmount &&
      goldDuration &&
      silverAmount &&
      silverDuration &&
      bronzeAmount
    ) {
      debugger;
      const payload = {
        planTitle: title,
        description: description,
        goldAmount: goldAmount,
        goldDuration: goldDuration,
        goldBenefits: goldDetails,
        silverAmount: silverAmount,
        silverDuration: silverDuration,
        silverBenefits: silverDetails,
        bronzeAmount: bronzeAmount,
        bronzeDuration: bronzeDuration,
        bronzeBenefits: bronzeDetails,
      };
      $.ajax({
        url: endpoints.addSubscription,
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        data: JSON.stringify(payload),
        contentType: "application/json",
        processData: false,
        success: function (data, textStatus, jQxhr) {
          setTitle("");
          setDescription("");
          setGoldAmount("");
          setGoldDuration("");
          setGoldDetails("");
          setGoldAmount("");
          setSilverAmount("");
          setSilverDetails("");
          setSilverDuration("");
          setBronzeAmount("");
          setBronzeDetails("");
          setBronzeDuration("");
          if (data.message == "subscription added") {
            setSubscriptionDetails(data);
            setErrorMessage("Subscription Added");
            setTimeout(() => {
              setShowAddModal(false);
              myFunction();
            }, 1000);

            setErrorColor(true);
            // showSuccessMessageToast();
          } else {
            setErrorMessage("Subscription not Added");
            setErrorColor(false);
          }
        },
        complete: function () {
          listAllSubscription();
          setButtonDisabled(false);
        },
      });
    } else {
      setFillError(true);
      setTimeout(removeFillError, 10000);
    }
  };

  const editSubscription = (subcriptionDetails) => {
    setEditData(subcriptionDetails);
  };

  const editSubscriptionClick = () => {
    setErrorMessage("");
    setButtonDisabled(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      subcriptionId: editData._id,
      planTitle: editData.planTitle,
      description: editData.description,
      goldAmount: editData.GOLD.goldAmount,
      goldDuration: editData.GOLD.goldDuration,
      goldBenefits: editData.GOLD.goldBenefits,
      silverAmount: editData.SILVER.silverAmount,
      silverDuration: editData.SILVER.silverDuration,
      silverBenefits: editData.SILVER.silverBenefits,
      bronzeAmount: editData.BRONZE.bronzeAmount,
      bronzeDuration: editData.BRONZE.bronzeDuration,
      bronzeBenefits: editData.BRONZE.bronzeBenefits,
    };
    debugger;
    $.ajax({
      url: endpoints.editSubscription,
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data.message == "subcription edited successfully") {
          setEditErrorMessage("subcription edited successfully");
          setErrorColor(true);
          setTimeout(() => {
            removeEditError();
            $("#modal-default1").hide();
          }, 2000);
          listAllSubscription();
          // showSuccessMessageToast();
        } else {
          setEditErrorMessage("Failed");
          setErrorColor(false);
        }
      },
      error: function (err) {
        console.error("err", err);
      },
      complete: function () {
        setButtonDisabled(false);
      },
    });
  };

  const deleteSubscription = (subcriptionId) => {
    setErrorMessage("");
    setButtonDisabled(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      subcriptionId: subcriptionId,
      // title: title,
      // month: month,
      // amount: amount
    };
    $.ajax({
      url: endpoints.deleteSubscription,
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data.message == "subcription deleted") {
          setSubscriptionDetails(data);
          setDeleteErrorMessage("subcription deleted");
          setErrorColor(true);
          listAllSubscription();
          // showSuccessMessageToast();
        } else {
          setErrorMessage("subcription not deleted");
          setErrorColor(false);
          // showErrorMessageToast()
        }
      },
      complete: function () {
        listAllSubscription();
        setButtonDisabled(false);
      },
    });
  };
  //   const showSuccessMessageToast = () =>{
  //     debugger
  //     setShowErrorToast(false);
  //     setShowSuccessToast(true);
  //     setTimeout(() => {
  //       setShowSuccessToast(false);
  //     }, 5000);
  //   }
  // const showErrorMessageToast = () =>{
  //   setShowErrorToast(true);
  //   setShowSuccessToast(false);
  //   setTimeout(() => {
  //     setShowErrorToast(false);
  //   }, 5000);
  // }

  const updateTitle = (title) => {
    const existingData = { ...editData };
    existingData.title = title;
    setEditData(existingData);
  };

  const updateMonth = (month) => {
    const existingData = { ...editData };
    existingData.month = month;
    setEditData(existingData);
  };

  const updateAmount = (amount) => {
    const existingData = { ...editData };
    existingData.amount = amount;
    setEditData(existingData);
  };
  const updateLevel = (level) => {
    const existingData = { ...editData };
    existingData.level = level;
    setEditData(existingData);
  };
  const updateBenifits = (benifits) => {
    const existingData = { ...editData };
    existingData.benifits = benifits;
    setEditData(existingData);
  };

  const updateEditObject = (primaryKey, secondaryKey, value) => {
    let updateObj = { ...editData };
    if (secondaryKey) {
      let secondaryObj = { ...editData[primaryKey] };
      secondaryObj = { ...secondaryObj, [secondaryKey]: value };
      updateObj = { ...editData, [primaryKey]: secondaryObj };
    } else {
      updateObj = { ...editData, [primaryKey]: value };
    }
    setEditData(updateObj);
  };

  const myFunction = () => {
    setErrorMessage("");
  };
  const removeEditError = () => {
    setEditErrorMessage("");
  };
  const removeFillError = () => {
    setFillError(false);
  };
  return (
    <>
      <Sidebar currentPage="subscription" />
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        <div class="card-header">
          <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Subscription Plans
          </h3>
        </div>
        <button
          type="button"
          class="btn btn-success btn-new"
          onClick={() => {
            setShowAddModal(true);
          }}
        >
          Add New
        </button>

        <div class="row" style={{ marginTop: "20px", marginLeft: "20px" }}>
          {subscriptionListDetails.map((item, index) => {
            return (
              <div
                class="card card-style"
                key={index}
                style={{
                  width: "18rem",
                  border: "1px solid black",
                  marginRight: "20px",
                }}
              >
                {/* <div class="card" style={{width:'18rem'}}> */}
                <ul style={{ marginRight: "30px", marginTop: "30px" }}>
                  <li class="list-group-item">
                    <b>Plan Title {item.planTitle}</b>
                  </li>
                  {item.description && (
                    <li class="list-group-item">
                      <p>Description : {item.description}</p>
                    </li>
                  )}
                  <li class="list-group-item" style={{ color: "red" }}>
                    GOLD
                  </li>
                  <li class="list-group-item">
                    <p>Duration : {item.GOLD.goldDuration}</p>
                  </li>
                  <li class="list-group-item">
                    <p>Amount : GBP {item.GOLD.goldAmount}</p>
                  </li>
                  <li class="list-group-item">
                    <p>{item.GOLD.goldBenefits}</p>
                  </li>
                  <li class="list-group-item" style={{ color: "red" }}>
                    SILVER
                  </li>
                  <li class="list-group-item">
                    <p>Duration : {item.SILVER.silverDuration} </p>
                  </li>
                  <li class="list-group-item">
                    <p>Amount : GBP {item.SILVER.silverAmount}</p>
                  </li>
                  <li class="list-group-item">
                    <p>{item.SILVER.silverBenefits}</p>
                  </li>
                  <li class="list-group-item" style={{ color: "red" }}>
                    BRONZE
                  </li>
                  <li class="list-group-item">
                    <p>Duration : {item.BRONZE.bronzeDuration} </p>
                  </li>
                  <li class="list-group-item">
                    <p>Amount : GBP {item.BRONZE.bronzeAmount}</p>
                  </li>
                  <li class="list-group-item">
                    <p>{item.BRONZE.bronzeBenefits}</p>
                  </li>

                  <div class="card-body row">
                    <button
                      type="button"
                      class="btn btn-success btn-new col-sm"
                      style={{ cursor: "pointer" }}
                      data-toggle="modal"
                      data-target="#modal-default1"
                      onClick={() => editSubscription(item)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-new col-sm"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteSubscription(item._id)}
                    >
                      Delete
                    </button>
                    {/* <span class="right badge-ban badge badge-success" style={{cursor:"pointer"}} data-toggle="modal" data-target="#modal-default1" onClick={() => editSubscription(item)}>Edit</span>
            <span class="right badge-ban badge badge-danger" style={{cursor:"pointer"}} onClick={() => deleteSubscription(item._id)}>Delete</span> */}
                  </div>
                </ul>
              </div>
            );
          })}
        </div>
        {showAddModal && (
          <Modal
            show={true}
            onHide={() => {
              setShowAddModal(false);
              clearAddForm();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>Plan Title</label>
              <input
                type="text"
                name="message"
                placeholder="Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Plan Description</label>
              <input
                type="text"
                name="message"
                placeholder="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Gold Subcription Amount</label>
              <input
                type="number"
                name="message"
                placeholder="Amount"
                value={goldAmount}
                onChange={(e) => {
                  setGoldAmount(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Gold Subcription Duration</label>
              <input
                type="number"
                name="message"
                placeholder="In Months"
                value={goldDuration}
                onChange={(e) => {
                  setGoldDuration(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />

              <label>Gold Subcription Details</label>
              <input
                type="text"
                name="message"
                placeholder="Benifits"
                value={goldDetails}
                onChange={(e) => {
                  setGoldDetails(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Silver Subcription Amount</label>
              <input
                type="number"
                name="message"
                placeholder="Amount"
                value={silverAmount}
                onChange={(e) => {
                  setSilverAmount(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>SilverSubcription Duration</label>
              <input
                type="number"
                name="message"
                placeholder="In Months"
                value={silverDuration}
                onChange={(e) => {
                  setSilverDuration(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Silver Subcription Details</label>
              <input
                type="text"
                name="message"
                placeholder="Benifits"
                value={silverDetails}
                onChange={(e) => {
                  setSilverDetails(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Bronze Subcription Amount</label>
              <input
                type="number"
                name="message"
                placeholder="Amount"
                value={bronzeAmount}
                onChange={(e) => {
                  setBronzeAmount(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Bronze Subcription Duration</label>
              <input
                type="number"
                name="message"
                placeholder="In Months"
                value={bronzeDuration}
                onChange={(e) => {
                  setBronzeDuration(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              <label>Bronze Subcription Details</label>
              <input
                type="text"
                name="message"
                placeholder="Benifits"
                value={bronzeDetails}
                onChange={(e) => {
                  setBronzeDetails(e.target.value);
                }}
                class="form-control"
                style={{ marginBottom: "10px" }}
                required
              />
              {fillError ? (
                <p style={{ color: "red" }}>Please fill all the field</p>
              ) : null}
              {errorMessage && (
                <div
                  class="alert alert-success"
                  style={{ height: "50px", marginTop: "20px" }}
                  role="alert"
                >
                  <p>{errorMessage}</p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <button
                type="button"
                class="btn btn-default"
                onClick={() => {
                  setShowAddModal(false);
                }}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={addSubscription}
                class="btn btn-primary"
              >
                Save changes
              </button>
            </Modal.Footer>
          </Modal>
        )}

        <div className="modal fade show" id="modal-default1">
          <div class="modal-dialog">
            <div
              class="modal-content"
              style={{ overflowY: "auto", height: "95vh" }}
            >
              <div class="modal-header">
                <h4 class="modal-title">Edit Subscription</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <label>Plan Title</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Title"
                  value={editData && editData.planTitle}
                  onChange={(e) => {
                    updateEditObject("planTitle", null, e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Plan Description</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Title"
                  value={editData && editData.description}
                  onChange={(e) => {
                    updateEditObject("description", null, e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Gold Subcription Amount</label>
                <input
                  type="number"
                  name="message"
                  placeholder="Amount"
                  value={editData && editData.GOLD && editData.GOLD.goldAmount}
                  onChange={(e) => {
                    updateEditObject("GOLD", "goldAmount", e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Gold Subcription Duration</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Month"
                  value={
                    editData && editData.GOLD && editData.GOLD.goldDuration
                  }
                  onChange={(e) => {
                    updateEditObject("GOLD", "goldDuration", e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Gold Subcription Details</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Benifits"
                  value={
                    editData && editData.GOLD && editData.GOLD.goldBenefits
                  }
                  onChange={(e) => {
                    updateEditObject("GOLD", "goldBenefits", e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Silver Subcription Amount</label>
                <input
                  type="number"
                  name="message"
                  placeholder="Amount"
                  value={
                    editData && editData.SILVER && editData.SILVER.silverAmount
                  }
                  onChange={(e) => {
                    updateEditObject("SILVER", "silverAmount", e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>SilverSubcription Duration</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Month"
                  value={
                    editData &&
                    editData.SILVER &&
                    editData.SILVER.silverDuration
                  }
                  onChange={(e) => {
                    updateEditObject(
                      "SILVER",
                      "silverDuration",
                      e.target.value
                    );
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Silver Subcription Details</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Benifits"
                  value={
                    editData &&
                    editData.SILVER &&
                    editData.SILVER.silverBenefits
                  }
                  onChange={(e) => {
                    updateEditObject(
                      "SILVER",
                      "silverBenefits",
                      e.target.value
                    );
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Bronze Subcription Amount</label>
                <input
                  type="number"
                  name="message"
                  placeholder="Amount"
                  value={
                    editData && editData.BRONZE && editData.BRONZE.bronzeAmount
                  }
                  onChange={(e) => {
                    updateEditObject("BRONZE", "bronzeAmount", e.target.value);
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Bronze Subcription Duration</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Month"
                  value={
                    editData &&
                    editData.BRONZE &&
                    editData.BRONZE.bronzeDuration
                  }
                  onChange={(e) => {
                    updateEditObject(
                      "BRONZE",
                      "bronzeDuration",
                      e.target.value
                    );
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <label>Bronze Subcription Details</label>
                <input
                  type="text"
                  name="message"
                  placeholder="Benifits"
                  value={
                    editData &&
                    editData.BRONZE &&
                    editData.BRONZE.bronzeBenefits
                  }
                  onChange={(e) => {
                    updateEditObject(
                      "BRONZE",
                      "bronzeBenefits",
                      e.target.value
                    );
                  }}
                  class="form-control"
                  style={{ marginBottom: "10px" }}
                  required
                />
                {editErrorMessage && (
                  <div
                    class="alert alert-success"
                    style={{ height: "50px", marginTop: "20px" }}
                    role="alert"
                  >
                    <p>{editErrorMessage}</p>
                  </div>
                )}
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={editSubscriptionClick}
                  class="btn btn-primary"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {errorMessage && <ToastContainer className="p-3" position='top-end' style={{zIndex: 1002}}>
          <Toast show={showSuccessToast || showErrorToast} bg={showSuccessToast ? "success" : 'danger'} style={{marginBottom:'20px'}}>
          <Toast.Header closeButton={false}>
          <strong className="me-auto">Message</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{errorMessage}</Toast.Body>
          </Toast>
      </ToastContainer>   } */}
    </>
  );
}

export default Subscription;
