import { Badge } from "react-bootstrap";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const endpoints = {
  adminLogin: baseUrl + "/admin/login",
  allMembers: baseUrl + "/admin/allmembers",
  bannMembers: baseUrl + "/admin/banmember",
  suspendMembers: baseUrl + "/admin/suspendmember",
  addSubscription: baseUrl + "/admin/addsubcription",
  editSubscription: baseUrl + "/admin/editsubcription",
  deleteSubscription: baseUrl + "/admin/deletesubcription",
  allSubscription: baseUrl + "/admin/allsubcriptions",
  allEmails: baseUrl + "/admin/allemails",
  replyMail: baseUrl + "/admin/replayemail",
  addCategory: baseUrl + "/admin/addcategory ",
  listCategory: baseUrl + "/admin/allcategories",
  deleteCategory: baseUrl + "/admin/deletecategory",
  addSubUser: baseUrl + "/admin/addsubuser",
  editSubUser: baseUrl + "/admin/editsubuser",
  deleteSubUser: baseUrl + "/admin/deletesubuser",
  listSubUser: baseUrl + "/admin/allsubuser",
  addTermsAndConditions: baseUrl + "/admin/addtermsandcondition",
  editTermsAndConditions: baseUrl + "/admin/edittermsandcondition",
  deleteTermsAndConditions: baseUrl + "/admin/delettermsandcondition",
  allTermsAndConditions: baseUrl + "/admin/alltermsandcondition",
  userReport: baseUrl + "/admin/userreport",
  memberReport: baseUrl + "/admin/memberreport",
  trafficReport: baseUrl + "/admin/trafficreport",
  subscriptionReport: baseUrl + "/admin/subscriptionreport",
  liveViewersReport: baseUrl + "/admin/liveviewersreport",
  bannedMemberList: baseUrl + "/admin/banedmembers",
  suspendedMemberList: baseUrl + "/admin/suspendedmembers",
  unSuspendMember: baseUrl + "/admin/unsuspend",
  UnbanMember: baseUrl + "/admin/unban",
  reportedMembers: baseUrl + "/admin/reportedMembers",
  liveStatistics: baseUrl + "/admin/livestatistics",
  trafficStatistics: baseUrl + "/admin/trafficstatistics",
  addAdvertisement: baseUrl + "/admin/addadvertisement",
  editAdvertisement: baseUrl + "/admin/editadvertisement",
  deleteAdvertisement: baseUrl + "/admin/deleteadvertisement",
  deleteVideoAds: baseUrl + "/admin/deleteinventoryvideoads",
  listAdvertisement: baseUrl + "/admin/alladvertisement",
  userChatHistory: baseUrl + "/admin/userchats",
  allChatHistory: baseUrl + "/admin/chatshistory",
  reportedStreamers: baseUrl + "/admin/reportedstreamers",
  listCountries: baseUrl + "/admin/listcountries",
  adminEmails: baseUrl + "/admin/adminemails",
  getInvoices: baseUrl + "/admin/allinvoices",
  modifypaymentstatus: baseUrl + "/admin/modifypaymentstatus",
  transferapproved: baseUrl + "/admin/transferapproved",
  getCurrentWithdrawalLimit: baseUrl + "/admin/getcreditlimit",
  setCreditLimit: baseUrl + "/admin/setcreditlimit",
  getGiftingPlans: baseUrl + "/admin/giftings",
  addGiftingPlan: baseUrl + "/admin/addgiftings",
  deleteGiftingPlan: baseUrl + "/admin/deletegiftings",
  editGiftingPlan: baseUrl + "/admin/editgiftings",
  getCreditsPurchasePlans: baseUrl + "/admin/getcreditspurchaseplans",
  editcreditspurchaseplans: baseUrl + "/admin/enablecreditspurchaseplans",
  listAllLives: baseUrl + "/admin/listalllives",
  listAllPaymentHistory: baseUrl + "/admin/listallpaymenthistory",
  getVideoAdsSettings: baseUrl + "/admin/getvideoadvertisments",
  addInventoryVideoAds: baseUrl + "/admin/addinventoryvideoads",
  addInventoryAdSettings: baseUrl + "/admin/addinventoryadsettings",
  giftingHistory: baseUrl + "/admin/gifthistory",
  deleteMember: baseUrl + "/admin/deletemember/",
  getSingleMember: baseUrl+"/admin/getuseralldetailsbyId",
  deletePhotoById:baseUrl+"/admin/deletephotosbyId",
  createChatToken: baseUrl + "/admin/createchattoken",
  viewersCount:baseUrl+"/admin/ivsviewcount"
};
