import { useState, useEffect } from "react";

const useLocalStorage = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(getLoginResponseFromLocal());
  }, []);

  const getLoginResponseFromLocal = () => {
    return JSON.parse(localStorage.getItem("adminResponse"));
  };

  // To store data
  const storeValueToLocal = (id, value) => {
    localStorage.setItem(id, value);
  };

  // To clear a specific item
  const removeValueFromLocal = (id) => {
    return localStorage.removeItem(id);
  };
  const checkValueIsExist = () => {
    return localStorage.getItem("adminResponse") != null;
  };
  return {
    user,
    getLoginResponseFromLocal,
    storeValueToLocal,
    removeValueFromLocal,
    checkValueIsExist,
  };
};

export default useLocalStorage;
