import React, { useState } from "react";
import * as Ai from "react-icons/ai";
import { endpoints } from "../../endpoints/endpoint";
import { BeatLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import $ from "jquery";

function SuspendModal(props) {
  const [reason, setReason] = useState("");
  const [suspendLoading, setSuspendLoading] = useState(false);
  const [suspendError, setSuspendError] = useState(false);
  const [suspendedDuration, setSuspendedDuration] = useState(10);

  const handleSuspendMember = async (id) => {
    if (!reason || reason.length < 0) {
      setSuspendError(true);
      return;
    }

    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: id,
      adminId: obj.adminId,
      duration: suspendedDuration,
      reason: reason,
    };
    setSuspendLoading(true);
    await $.ajax({
      url: endpoints.suspendMembers,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        props.onSuspendSucess();
        toast.success("Suspended Successfully!");
        setTimeout(() => {
          props.suspendCloseModal();
        }, 1000);
      },
      complete: () => {
        setSuspendLoading(false);
        setReason("");
        setSuspendedDuration(10);
      },
    });
  };

  const onHanldeDuration = (value) => {
    if (value.length === 0) {
      setSuspendedDuration("");
      return;
    }
    setSuspendedDuration(value.replace(/[^0-9]/g, ""));
  };

  const handleReason = (value) => {
    if (value.length < 0 && value === "") {
      setSuspendError(true);
      return;
    }
    setReason(value);
  };

  if (!props.suspendOpenModal) return null;
  return (
    <>
      <Toaster position="top-center"></Toaster>
      <div class="modal-wrapper">
        <div class="modal-container suspend-modal">
          <div
            className="close"
            onClick={() => {
              props.suspendCloseModal();
              setSuspendError(false);
              setReason("");
            }}
          >
            <Ai.AiOutlineClose />
          </div>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th>Username</th>
                <th>Nickname</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{props.suspendData?.username ?? "N/A"}</td>
                <td>{props.suspendData?.nickname ?? "N/A"}</td>
                <td>{props.suspendData?.mail ?? "N/A"}</td>
                <td>
                  {props.suspendData?.mobile != ""
                    ? props.suspendData?.mobile ?? "N/A"
                    : "N/A"}
                </td>
                <td>{props.suspendData?.status ?? "N/A"}</td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label for="">Suspend Days</label>
                <input
                  type="number"
                  name=""
                  id=""
                  value={suspendedDuration}
                  className="form-control"
                  placeholder="in days"
                  onChange={(e) => onHanldeDuration(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="Suspending Reason">Suspending Reason</label>
            <textarea
              className="form-control"
              value={reason}
              onChange={(e) => handleReason(e.target.value)}
              placeholder="Report your reson here"
              required={true}
            />
            {suspendError ? (
              <span style={{ color: "red" }}>Reason is required!</span>
            ) : (
              ""
            )}
          </div>
          <button
            class="btn btn-success ban_btn"
            onClick={() => handleSuspendMember(props.suspendData._id)}
            disabled={!suspendLoading ? false : true}
          >
            {!suspendLoading ? (
              "Suspend Member"
            ) : (
              <div class="loader_">
                <BeatLoader
                  color="#fff"
                  loading
                  size={10}
                  className="puff_loader"
                />
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default SuspendModal;
