import moment from "moment";

export default class Utils {
  static formatDecimal(value) {
    return value ? value.toFixed(2) : "NA";
  }
  static isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  static titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }
  static parseDate(strDate) {
    if (!strDate) return;
    const [day, month, year] = strDate.split("-");
    return new Date(day, month, year);
  }

  static parseMonthAndYearFromDate(strDate, day, reduce) {
    if (!strDate) return;
    const [year, month] = strDate.split("-");
    return new Date(year, month - reduce, day);
  }

  static getTokenConvertion(token, net) {
    return token && token != "NA" && net != "NA" && net
      ? (token / net).toFixed(2)
      : "NA";
  }
  static getDate(date) {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;
    return today;
  }

  static changeDateStringFormat(dateText) {
    var datearray = dateText.split("-");
    var newdate = datearray[1] + "-" + datearray[0] + "-" + datearray[2];
    // console.log("FormattedDate", dateText, newdate);
    return newdate;
  }

  static changeSlashDateFormat(dateStr) {
    var datearray = dateStr.split("/");
    var newDate = datearray[1] + "-" + datearray[0] + "-" + datearray[2];

    return newDate;
  }

  static parseStringDateToObject(dateString) {
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  }

  static checkAndFormatDate(dateString) {
    const isDateFormatMMDDYYYY = moment(
      dateString,
      "MM-DD-YYYY",
      true
    ).isValid();

    if (!isDateFormatMMDDYYYY) return dateString;

    return moment(dateString, "MM-DD-YYYY").format("DD-MM-YYYY");
  }
}
