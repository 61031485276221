import { useRef, useState } from "react";
import { React, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { SocketContext } from "../../context/SocketContext";
import { AppContext } from "../../context/AppContext";
import { endpoints } from "../../endpoints/endpoint";
import $ from "jquery";
import useLocalStorage from "../../hooks/useLocalStorage";
import Player from "../../Component/player/player";
import Sidebar from "../Sidebar/Sidebar";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { PuffLoader } from "react-spinners";
import Lottie from "react-lottie";
import StopSign from "../../image/6656-warning-sign.json";
import "./Lives.css";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Ri from "react-icons/ri";
import EmojiPicker from "emoji-picker-react";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import useUserDetails from "../../hooks/useUserDetails";
import BeatLoader from "react-spinners/BeatLoader";
import useChat from "../../hooks/useChat";

function ViewLive() {
  const params = useParams();
  const history = useHistory();
  const { socket } = useContext(SocketContext);

  const { liveVideos, loading } = useContext(AppContext);
  const { listAllUsers } = useUserDetails();
  const [selectedLive, setSelectedLive] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [socketEvent, setSocketEvent] = useState(null);
  const [offline, setOffline] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emoji, setEmoji] = useState("");
  const [offChat, setOffChat] = useState(false);
  const [viewersCount, setViewersCount] = useState(0);

  const [showFullText, setShowFullText] = useState(false);
  const [showModal, setShowModal] = useState({
    action: "",
    status: false,
    message: "",
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [actionReason, setActionReason] = useState("");
  const [suspendedAction, setSuspendedAction] = useState({
    status: false,
    duration: "10",
  });

  const { requestToken, sendMessage, newChat, setNewChat, chats } = useChat();
  const { getLoginResponseFromLocal } = useLocalStorage();

  useEffect(() => {
    const localStorageValue = getLoginResponseFromLocal();
    if (
      selectedLive != null &&
      localStorageValue != null &&
      localStorageValue?.adminId != null
    ) {
      requestToken({
        userId: localStorageValue.adminId,
        roomId: selectedLive?.chatroomarn,
      });
    }
  }, [selectedLive]);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    console.log("selectedLive", selectedLive);
  }, [selectedLive]);

  useEffect(() => {
    let stopIntervalId;
    if (params.id && params.channelId) {
      listenToSocket();
      getViewersCount();
      stopIntervalId = setInterval(() => {
        emitSocketEvent();
      }, 10000);
    }

    return () => {
      clearInterval(stopIntervalId);
      socket.offAny();
    };
  }, []);

  useEffect(() => {
    findSelectedVideo();
  }, [liveVideos, loading]);

  useEffect(() => {
    console.log("selectedLive", selectedLive);
  }, [selectedLive]);

  useEffect(() => {
    console.log("socketEvent", socketEvent);
    handleSocketEvent();
    return () => {
      socket.offAny();
    };
  }, [socketEvent]);

  const handleMessageChange = (event) => {
    setNewChat(event.target.value);
  };

  const onNewChatKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendMessage();
    }
  };

  const handleEmojiClick = (e) => {
    console.log("emoji", e.emoji);
    setNewChat(newChat + e.emoji);
  };
  const findSelectedVideo = () => {
    if (!params.id) {
      history.push("/");
      return;
    }

    const locatedVideo = liveVideos.find((video) => video._id === params.id);

    if (loading == true) return;

    if (locatedVideo === undefined && loading == false) {
      setSelectedLive("Not Available");
      return;
    }

    setSelectedLive(locatedVideo);
    return;
  };
  // console.log(selectedLive, "selectedLive")

  const listenToSocket = () => {
    socket.on("streamStopped", (data) => {
      console.log("Stream Stopped");
      setSocketEvent({ event: "streamStopped", data: data });
    });

    socket.on("streamdata", (data) => {
      setSocketEvent({ event: "streamdata", data });
    });
  };

  const handleSocketEvent = () => {
    if (!socketEvent || !socketEvent.event || !selectedLive) return;

    console.log("socketEvent 2", socketEvent);

    switch (socketEvent.event) {
      case "streamStopped":
        let stoppedStreamerId =
          socketEvent.data["userId"] ?? socketEvent.data["data"]["userId"];
        console.log("stoppedStreamerId", stoppedStreamerId);
        if (selectedLive._id === stoppedStreamerId) {
          setOffline(true);
        }
        break;
      case "streamdata":
        setViewersCount(socketEvent?.data?.data?.stream?.viewerCount ?? 0);
    }
  };

  const emitSocketEvent = () => {
    if (!params.channelId) return;
    socket.emit("getstreamdata", params.channelId);
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: StopSign,
  };
  const description =
    selectedLive?.stream?.description ?? "Description not available.";
  const truncatedDescription = description.slice(0, 220);

  const adminAction = (value) => {
    setSuspendedAction({ ...suspendedAction, status: false });
    if (value == "ban") {
      setShowModal({
        action: value,
        status: true,
        message: "Are You Sure You want to Ban this user? ",
      });

      return;
    }
    if (value == "suspend") {
      setSuspendedAction({ ...suspendedAction, status: true });
      setShowModal({
        action: value,
        status: true,
        message: "Are You Sure You want to Suspend this user? ",
      });
      console.log("suspended action", suspendedAction.status);
      return;
    }
    if (value == "report") {
      setSuspendedAction({ ...suspendedAction, status: false });
      setShowModal({
        action: value,
        status: true,
        message: "Are You Sure You want to Report this user? ",
      });
      return;
    }
  };

  const getViewersCount = () => {
    const channelId = params.channelId;
    if (!channelId) return;
    $.ajax({
      url: endpoints.viewersCount,
      dataType: "json",
      type: "post",
      data: JSON.stringify({ channelId: channelId }),
      contentType: "application/json",
      processData: false,
      success: function (res, textStatus, jQxhr) {
        console.log("ViewerCount", res);
        setViewersCount(res.data?.stream?.viewerCount ?? 0);
      },
      error: function (err) {
        console.log("err", err);
      },
    });
  };

  const handleModalClose = () => {
    setShowModal({
      status: false,
      message: "",
    });
    setActionReason("");
    setSuspendedAction({
      status: !suspendedAction.status,
      duration: "10",
    });
  };

  const handleAction = async (e) => {
    e.preventDefault();
    if (actionReason.length == 0) {
      toast.error("Reason is Required!");
      return;
    }
    const id = params.id;
    const obj = JSON.parse(localStorage.getItem("adminResponse"));

    if (showModal.action == "ban") {
      const payload = {
        userId: id,
        adminId: obj.adminId,
        reason: actionReason,
      };
      setActionLoading(true);
      await $.ajax({
        url: endpoints.bannMembers,
        dataType: "json",
        data: JSON.stringify(payload),
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        contentType: "application/json",
        processData: false,
        success: function (data, textStatus, jQxhr) {
          toast.success("Banned successfully!");
          setTimeout(() => {
            window.location.href = "/users";
            listAllUsers();
          }, 1000);
        },
        complete: () => {
          setActionLoading(false);
          setActionReason("");
        },
      });
      return;
    }
    if (showModal.action == "suspend") {
      const payload = {
        userId: id,
        adminId: obj.adminId,
        reason: actionReason,
      };

      setActionLoading(true);
      await $.ajax({
        url: endpoints.suspendMembers,
        dataType: "json",
        data: JSON.stringify(payload),
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        contentType: "application/json",
        processData: false,
        success: function (data, textStatus, jQxhr) {
          toast.success("Suspended successfully!");
          setTimeout(() => {
            window.location.href = "/users";
            listAllUsers();
          }, 1000);
        },
        complete: () => {
          setActionLoading(false);
          setActionReason("");
        },
      });
      return;
    }
  };

  return (
    <>
      <Toaster position="top-center"></Toaster>
      <Modal
        footer={null}
        open={showModal.status}
        onCancel={handleModalClose}
        centered
        className="action_modal"
      >
        <p id="modal_header">Confirm Action</p>
        <h4 id="modal_title">{showModal.message}</h4>
        <form onSubmit={handleAction}>
          <div className="form-group">
            <label className="form-label">Reason</label>
            <textarea
              className="form-control"
              rows={5}
              cols={20}
              value={actionReason}
              required={true}
              onChange={(e) => setActionReason(e.target.value)}
            />
          </div>
          {suspendedAction.status && (
            <div className="form-group">
              <label className="form-label">Days</label>
              <input
                type="number"
                className="form-control"
                placeholder="in days"
                value={suspendedAction.duration}
                onChange={(e) =>
                  setSuspendedAction({ status: true, duration: e.target.value })
                }
                required
              />
            </div>
          )}
          <div className="btn-grp">
            <button type="submit" className="action-btn danger">
              {actionLoading ? (
                <div class="loader_">
                  <BeatLoader
                    color="#fff"
                    loading
                    size={10}
                    className="puff_loader"
                  />
                </div>
              ) : (
                "Confirm"
              )}
            </button>
            <button
              type="button"
              className="action-btn cancel_"
              onClick={handleModalClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      <Sidebar />
      <div class="content-wrapper subjects">
        <div className="row">
          <div className="col-md-8">
            {loading && (
              <h4>
                <div className="loader">
                  <PuffLoader color="#eb100c" loading size={70} />
                </div>
              </h4>
            )}
            {offline && (
              <>
                <Lottie
                  options={lottieOptions}
                  height={200}
                  width={200}
                  className="lot"
                />
                <h6 style={{ textAlign: "center" }}>
                  Stream has been Ended...
                </h6>
              </>
            )}
            {selectedLive == "Not Available" && !offline && (
              <div className="stop-stream">
                <Lottie options={lottieOptions} height={200} width={200} />
                <h6 style={{ textAlign: "center" }}>Stream not Available</h6>
              </div>
            )}
            {selectedLive && selectedLive != "Not Available" && (
              <div
                className={
                  !offline
                    ? "container live-continer"
                    : "container live-continer hide"
                }
              >
                <div className="liveScreen">
                  <Player
                    thumbnail={selectedLive?.stream?.thumbnailUrl}
                    playbackUrl={selectedLive?.playbackUrl}
                  />
                </div>

                <div className="card live_card mb-3">
                  <>
                    <p className="title mb-4">{selectedLive.stream?.title}</p>
                    <div className="row">
                      <div className="col-md-6  col-sm-12">
                        <div className="row">
                          <div className="col-md-2  col-sm-12">
                            {selectedLive?.profilepic != null ? (
                              <img
                                src={
                                  process.env.REACT_APP_AWS_RESOURCE_PATH_IMAGES +
                                  selectedLive?.profilepic
                                }
                                className="profile-im"
                                alt="Profile Photo"
                              />
                            ) : (
                              <img
                                src={require("../../image/img-placeholder.jpg")}
                                className="profile-im"
                                alt="Profile Photo"
                              />
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            {" "}
                            <p className="datas- username">
                              {selectedLive?.username ?? "Not Available"}
                            </p>
                            <p className="datas- nick">
                              @{selectedLive?.nickname}
                            </p>{" "}
                          </div>
                          <div className="col-md-4  col-sm-12 d-flex btn2_grp">
                            <p className="eye">
                              <Ai.AiFillEye /> <span>{`${viewersCount} viewers`}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4"></div> */}
                      <div className="col-md-3">
                        <div className="row button_grp">
                          <button
                            className="col participants ban-btn"
                            onClick={() => adminAction("ban")}
                          >
                            <span>Ban</span>
                          </button>
                          <button
                            className="col participants suspend-btn"
                            onClick={() => adminAction("suspend")}
                          >
                            <span>Suspend</span>
                          </button>
                        </div>
                        <div className="row"></div>
                      </div>
                    </div>
                    {selectedLive.stream?.description &&
                      selectedLive.stream?.description !== "undefined" ? (
                      <div className="mt-3">
                        <div
                          className={`live-cont ${showFullText ? "show-full-text" : ""
                            }`}
                        >
                          {description.length > 220 ? (
                            <>
                              <p>
                                {showFullText
                                  ? description
                                  : truncatedDescription}
                              </p>
                              {!showFullText && (
                                <button
                                  type="text"
                                  className="read-more"
                                  onClick={toggleText}
                                >
                                  Read More...
                                </button>
                              )}
                              {showFullText && (
                                <button
                                  type="text"
                                  className="read-less"
                                  onClick={toggleText}
                                >
                                  Read Less
                                </button>
                              )}
                            </>
                          ) : (
                            description
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>
            )}
          </div>

          {!offline && selectedLive != "Not Available" && (
            <div className="col-xl-4 col-md-6 col-sm-7 chat-col">
              <div className="chat_main">
                <div className="chat-head">
                  <div className="chat-heading">
                    <h3 className="chat-heading">Messages</h3>
                  </div>
                </div>
                <div className="chat_area_left" id="chat">
                  {/* <div class="chat d-flex">
                      <div class="" id="left">
                        <img
                          src={require("../../image/chat-img.jpg")}
                          alt=""
                          className="avt"
                        />
                      </div>
                      <div class="txt">
                        <p className="chat_member ms-2">
                          {" "}
                          Member1{" "}
                          <i>
                            <Fa.FaUser />
                          </i>
                        </p>
                        <div className="txt_cont">are you there</div>
                      </div>
                    </div> */}

                  {chats.map((chat, index) => {

                    return (
                      <div
                        class={
                          chat?.role == "admin"
                            ? "chat-right d-flex"
                            : "chat d-flex"
                        }
                      >
                        {chat?.role != "admin" && (
                          <div style={{ marginRight: "10px" }}>
                            <img
                              src={
                                chat.image != null &&
                                  !chat.image.startsWith("/assets/")
                                  ? process.env
                                    .REACT_APP_AWS_RESOURCE_PATH_IMAGES +
                                  chat.image
                                  : "/image/user_placeholder.png"
                              }
                              alt={chat?.name}
                              className="avt ms-2"
                            />
                          </div>
                        )}
                        <div class="txt">
                          {chat?.role == "admin" ? (
                            <p className="chat_member">
                              <i>
                                <Ai.AiFillCrown />
                              </i>{" "}
                              Admin
                            </p>
                          ) : (
                            <p className="chat_member">
                              <Ri.RiUserFill className="me-2" />
                              {chat?.name}
                            </p>
                          )}
                          <p className={`txt_cont ${chat?.role == "admin" ? "right" : "left"}`}>{chat?.isEmoji ? chat?.attributes.emoji : chat?.chat}</p>
                        </div>
                        <div className="admin_avatar" id="right">
                          {chat?.role == "admin" && (
                            <img
                              src={
                                process.env.REACT_APP_AWS_RESOURCE_PATH_IMAGES +
                                "Admin.jpg"
                              }
                              alt=""
                              className="avt mt-3"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {showEmojiPicker && (

                    <EmojiPicker onEmojiClick={(e) => handleEmojiClick(e)} className="picker-stick" />


                  )}
                  {/* chat content end */}
                </div>
                {/* Chat Area end */}
                <div className="sent-box">
                  <div className="emoji-container">
                    <button
                      className="emoji-btn"
                      onClick={() => {
                        console.log("Clicked");
                        setShowEmojiPicker(!showEmojiPicker)
                      }}
                    >
                      <Bs.BsEmojiSmile />
                    </button>
                  </div>
                  <input
                    className="p-3 msg_input"
                    placeholder="Write a message...."
                    value={newChat}
                    onChange={handleMessageChange}
                    onKeyDown={onNewChatKeyDown}
                  />

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewLive;
