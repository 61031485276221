import React, { useState } from "react";
import * as Ai from "react-icons/ai";
import format from "date-fns/format";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";
import toast, { Toaster } from "react-hot-toast";

function Modal({ open, onClose, data }) {
  const [loading, setLoading] = useState(false);

  const deleteMember = (data) => {
    setLoading(false);
    const id = data._id;
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    $.ajax({
      url: endpoints.deleteMember + id,
      dataType: "json",
      contentType: "application/json",
      type: "delete",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      success: function (res, textStatus, jQxhr) {
        //   debugger;
        setLoading(true);
        toast.success("Member Deleted Successfully!");

        setTimeout(() => {
          onClose();
        }, 1000);
      },
      complete: function () {
        setLoading(false);
      },
    });
  };
  if (!open) return null;
  return (
    <>
      <Toaster position="top-center"></Toaster>
      <div class="modal-wrapper">
        <div class="modal-container">
          <div className="close" onClick={onClose}>
            <Ai.AiOutlineClose />
          </div>
          <h2>Member Details</h2>
          <div class="form-group">
            <label for="Username">Username</label>
            <input
              type="text"
              className="form-control member_details"
              value={data?.username ?? "Not Available"}
              disabled
            />
          </div>
          <div class="form-group">
            <label for="Nickname">Nickname</label>
            <input
              type="text"
              className="form-control member_details"
              value={data?.nickname ?? "Not Available"}
              disabled
            />
          </div>
          <div class="form-group">
            <label for="Email">Email</label>
            <input
              type="email"
              className="form-control member_details"
              value={data?.mail ?? "Not Available"}
              disabled
            />
          </div>
          <div class="form-group">
            <label for="Phone">Phone</label>
            <input
              type="text"
              className="form-control member_details"
              value={data?.mobile ?? "Not Available"}
              disabled
            />
          </div>
          <div class="form-group">
            <label for="Date">Joining Date</label>
            <input
              type="text"
              className="form-control"
              value={
                data?.registration_date != null
                  ? format(Date.parse(data?.registration_date), "dd/MM/yyyy")
                  : "Not Available"
              }
              disabled
            />
          </div>
          <div class="form-group">
            <label for="Status">Status</label>
            <input
              type="text"
              className="form-control"
              value={data?.status ?? "Not Available"}
              disabled
            />
          </div>
          <br />
          <div class="row">
            <div class="col-lg-3 col-sm-none"></div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <button className="btn btn-primary form_btn" onClick={onClose}>
                OK
              </button>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
              <button
                className="btn btn-danger form_btn"
                onClick={() => deleteMember(data)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
