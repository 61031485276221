import React, {useState, useEffect} from "react";
import Sidebar from '../Sidebar/Sidebar'
// import './MemberDetails.css'
// import $ from "jquery";
// import { endpoints } from '../../endpoints/endpoint';


const MemberDetails = () =>{

    return(
        <>
         <Sidebar currentPage="members"/>
         
        </>
    )
}
export default MemberDetails