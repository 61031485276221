import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Utils from "../../utils/utils";
import "./Pagination.css";
import * as Gr from "react-icons/gr";
import * as Md from "react-icons/md";
function Pagination(props) {
  const { data } = props;

  const itemPerPage = 10;
  const [currentItems, setCurrentItems] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10; // change this to the number of items you want to display per page
  useEffect(() => {
    const endOffset = itemOffset + itemPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemPerPage));
  }, [itemOffset, itemPerPage, data]);
  const handleClick = (e) => {
    const newOffset = (e.selected * itemPerPage) % data.length + 1;
    setItemOffset(newOffset);

  };

  return (
    <>
    
      {currentItems != null &&
        currentItems.length > 0 &&
        currentItems.map((items, index) => {
          return (
            <tr key={index}>
                  <td>{index+1}</td>
              <td>
                {items.streamdate != null
                  ? Utils.getDate(items.streamdate)
                  : "N/A"}
              </td>
              <td>{items.streamtitle}</td>
            </tr>
          );
        })}
      
    
       < div className="page-cont" >
       <ReactPaginate
       activeClassName={'item active '}
       breakClassName={'item break-me'}
       breakLabel={'...'}
       containerClassName={'pagination'}
       disabledClassName={'disabled-page'}
       marginPagesDisplayed={2}
       nextClassName={"item next"}
       nextLabel={<Gr.GrFormNext style={{ fontSize: 18, width: 150 }} />}
       pageClassName={'item pagination-page '}
       pageRangeDisplayed={3}
       previousClassName={"item previous"}
       previousLabel={<Md.MdArrowBackIos style={{ fontSize: 18, width: 150 }} />}
       onPageChange={handleClick}
       pageCount={pageCount}
       renderOnZeroPageCount={null}
      />
       </div>
    
    </>
  );
}

export default Pagination;
