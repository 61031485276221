import { React } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useState, useEffect } from "react";
import $ from "jquery";
import appRoutes from "./Routes/Routes";
import { ProtectAuthRoute } from "./Routes/Protector";
import { ProtectRoute } from "./Routes/Protector";
// import { Circles } from "react-loader-spinner";
import { CircleLoader } from "react-spinners";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    });
  });
  $(document).ajaxError(function myErrorHandler(
    event,
    xhr,
    ajaxOptions,
    thrownError
  ) {
    if (thrownError === "Forbidden") {
      localStorage.removeItem("adminResponse");
      window.location.href = "/";
    }
  });

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <CircleLoader
            height="150"
            width="150"
            color="#c4d4bc"
            ariaLabel="circles-loading"
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <Router>
          <Switch>
            {appRoutes.map((route, index) => {
              return !route.isProtected && !route.isProtectedAuth ? (
                <Route
                  key={index}
                  path={route.path}
                  render={(props) => <route.Element {...props} />}
                  exact="true"
                />
              ) : !route.isProtected && route.isProtectedAuth ? (
                <Route
                  key={index}
                  path={route.path}
                  render={(props) => <route.Element {...props} />}
                  exact="true"
                ></Route>
              ) : (
                <Route
                  key={index}
                  path={route.path}
                  exact={true}
                  render={(props) => (
                    <ProtectRoute>
                      <route.Element {...props} role={route?.role} />
                    </ProtectRoute>
                  )}
                />
              );
            })}
          </Switch>
        </Router>
      )}
    </>
  );
}

export default App;
