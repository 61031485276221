import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Advertisement.css";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";
import * as Ri from "react-icons/ri";
import * as Md from "react-icons/md";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { Container } from "react-bootstrap";
import "../../../node_modules/video-react/dist/video-react.css";
import VideoAds from "./VideoAds";
import Utils from "../../utils/utils";

function Advertisement() {
  const [advertisement, setAdvertisement] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [file, setFile] = useState();
  const [form, setForm] = useState({});
  const [advertisementDetails, setAdvertisementDetails] = useState({});
  const [editSuccess, setEditSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [fillError, setFillError] = useState(false);
  const [addUrl, setAddUrl] = useState();
  const [locationName, setLocationName] = useState();
  const [editLocation, setEditLocation] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [editAddUrl, setEditAddUrl] = useState();
  const [locationCode, setLocationCode] = useState();
  const [editLocationCode, setEditLocationCode] = useState();
  const [position, setPosition] = useState("Home page banner");
  const [editPosition, setEditPosition] = useState();
  const [allPosition, setAllPosition] = useState([
    { value: "Home page banner", position: "Home page banner" },
    { value: "Live video section", position: "Live video section" },
    { value: "A-Z streamer", position: "A-Z Streamer" },
    { value: "Side bar", position: "Side bar" },
    { value: "Profile page", position: "Profile page" },
    { value: "Streaming page", position: "Streaming Page" },
    { value: "Pop up", position: "Pop up" },
  ]);
  const [editSelectedPosition, setEditSelectedPosition] = useState();
  const [editedPosition, setEditedPosition] = useState();
  const [locationGlobal, setLocationGlobal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewAdModal, setViewAdModal] = useState(false);
  const [tabValue, setTabvalue] = useState("1");

  useEffect(() => {
    listAllAdvertisement();
    listAllCategory();
    listAllCountries();
    document
      .querySelector(".custom-file-input")
      .addEventListener("change", function (e) {
        var fileName =
          document.getElementById("exampleInputFile").files[0].name;
        var nextSibling = e.target.nextElementSibling;
        nextSibling.innerText = fileName;
      });
  }, []);

  const tabChange = (eve, newValue) => {
    setTabvalue(newValue);
  };

  const listAllCategory = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));

    $.ajax({
      url: endpoints.listCategory,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setCategories(data);
        if (data && data.length > 0) {
          setCategory(data[0].categoryname);
        }
      },
    });
  };

  const listAllCountries = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));

    $.ajax({
      url: endpoints.listCountries,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setAllCountries(data);
      },
    });
  };

  const listAllAdvertisement = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    $.ajax({
      url: endpoints.listAdvertisement,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data && data.length > 0) {
          setAdvertisement(data);
        }
      },
    });
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const addAdvertidement = () => {
    setIsLoading(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const { file } = form;
    const bodyFormData = new FormData();
    if (file && category && fileName) {
      bodyFormData.append("file", file);
      bodyFormData.append("location", locationName);
      bodyFormData.append("filename", fileName);
      bodyFormData.append("addUrl", addUrl);
      bodyFormData.append("isocode", locationCode);
      bodyFormData.append("position", position);
      bodyFormData.append("locationGlobal", locationGlobal);
      $.ajax({
        url: endpoints.addAdvertisement,
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        data: bodyFormData,
        contentType: false,
        processData: false,
        success: function (data, textStatus, jQxhr) {
          listAllAdvertisement();
          setErrorMessage("Advertisement added successfully");
          setTimeout(myFunction, 5000);
        },
        complete: function () {
          setIsLoading(false);
          listAllAdvertisement();
        },
      });
    } else {
      setFillError(true);
      setTimeout(removeFillError, 10000);
    }
  };

  const deleteAdvertisement = (value) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      advertisementid: value._id,
    };
    $.ajax({
      url: endpoints.deleteAdvertisement,
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {},
      complete: function () {
        listAllAdvertisement();
      },
    });
  };

  const editAdvertisementCategory = (value) => {
    setAdvertisementDetails(value);
    setEditLocation(value.location);
    const selectedCountry = allCountries.filter(
      (item) => item.name === value.location
    );
    if (selectedCountry.length > 0) {
      setEditLocationCode(selectedCountry[0].isoCode);
    }
    const selectedPosition = allPosition.filter(
      (item) => item.position === value.position
    );
    if (selectedPosition.length > 0) {
      setEditSelectedPosition(selectedPosition[0].position);
    }
    setEditAddUrl(value.addUrl);

    //TODO: ADD CODE HERE
    console.log(setEditModal);
    setEditModal(true);
    setViewAdModal(true);
  };

  const editAdvertisement = () => {
    setEditSuccess("");
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      advertisementid: advertisementDetails._id,
      location: editLocation,
      isoCode: editLocationCode,
      addUrl: editAddUrl,
      position: editSelectedPosition,
    };
    debugger;
    $.ajax({
      url: endpoints.editAdvertisement,
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setEditSuccess("Edited Successfully");
        setTimeout(removeEditError, 5000);
      },
      complete: function () {
        listAllAdvertisement();
      },
    });
  };
  const myFunction = () => {
    setErrorMessage("");
  };
  const removeEditError = () => {
    setEditSuccess("");
  };

  const removeFillError = () => {
    setFillError(false);
  };

  const locationDetails = (value) => {
    const selectedCountry = allCountries.filter(
      (item) => item.isoCode === value
    );
    if (selectedCountry.length > 0) {
      // console.log(selectedCountry[0].name + " // " + selectedCountry[0].isoCode);
      setLocationName(selectedCountry[0].name);
      setLocationCode(selectedCountry[0].isoCode);
    }
  };

  const editLocationChange = (value) => {
    const selectedCountry = allCountries.filter(
      (item) => item.isoCode === value
    );
    if (selectedCountry.length > 0) {
      // console.log(selectedCountry[0].name + " // " + selectedCountry[0].isoCode);
      setEditLocation(selectedCountry[0].name);
      setEditLocationCode(selectedCountry[0].isoCode);
    }
  };

  return (
    <>
      <Sidebar currentPage="advertisement" />
      {/* <div class={!editModal ? "adModal_wrapper" : "adModal_wrapper hide"}>
        <Modal.Dialog show={editModal} onHide={() => setEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Advertisement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="modal-body">
              <div class="form-group">
                <label>Location Name</label>
                <select
                  class="form-control select2"
                  style={{ width: "100%" }}
                  value={editLocationCode}
                  onChange={(e) => editLocationChange(e.target.value)}
                >
                  {allCountries.map((item, index) => (
                    <option key={index} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div class="form-group">
                  <label>Position</label>
                  <select
                    class="form-control select2"
                    style={{ width: "100%" }}
                    value={editSelectedPosition}
                    onChange={(e) => setEditSelectedPosition(e.target.value)}
                  >
                    <option value="Home page banner">Home page banner</option>
                    <option value="Live video section">
                      Live video section
                    </option>
                    <option value="A-Z streamer">A-Z Streamer</option>
                    <option value="Side bar">Side bar</option>
                    <option value="Profile page">Profile page</option>
                    <option value="Streaming page">Streaming Page</option>
                    <option value="Pop up">Pop up</option>
                  </select>
                </div>

                <label>Ad Url</label>
                <input
                  type="text"
                  name="message"
                  value={editAddUrl}
                  placeholder="Location Name"
                  class="form-control"
                  onChange={(e) => setEditAddUrl(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
                {editSuccess && (
                  <div
                    class="alert alert-success"
                    style={{ height: "50px" }}
                    role="alert"
                  >
                    <p>{editSuccess}</p>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>setEditModal(false)}>Close</Button>
            <Button variant="primary" onClick={editAdvertisement}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div> */}
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        <br />
        <div class="container">
          <TabContext value={tabValue}>
            <Box>
              <TabList
                aria-label="tab example"
                onChange={tabChange}
                TabIndicatorProps={{
                  sx: {
                    background: "transparent",
                  },
                }}
              >
                <Tab
                  label="Advertisement"
                  value="1"
                  className="tabss"
                  icon={<Ri.RiAdvertisementFill />}
                  iconPosition="start"
                />
                <Tab
                  label="Video-Ads"
                  value="2"
                  className="tabss"
                  icon={<Md.MdVideoLibrary />}
                  iconPosition="start"
                />
              </TabList>
              <TabPanel value="1">
                <Container>
                  <div class="card-header">
                    <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Advertisement
                    </h3>
                  </div>

                  {isLoading && <div class="loader"></div>}
                  <div
                    class="card "
                    style={{ width: "fit-content", marginTop: "20px" }}
                  >
                    <div className="card-header">
                      <label>File Name</label>
                      <input
                        type="text"
                        name="message"
                        placeholder="File Name"
                        class="form-control"
                        onChange={(e) => setFileName(e.target.value)}
                        style={{ marginBottom: "10px" }}
                      />
                      {/* <label>Location Name</label>
                       <input type="text" name="message" placeholder="Location Name" class="form-control" onChange={e => setLocationName(e.target.value)} style={{marginBottom:'10px'}}/> */}
                      <div class="form-group">
                        <label>Location Name</label>
                        <select
                          class="form-control select2"
                          style={{ width: "100%" }}
                          onChange={(e) => locationDetails(e.target.value)}
                        >
                          {allCountries.map((item, index) => (
                            <option key={index} value={item.isoCode}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <input
                        type="checkbox"
                        id="locationGlobal"
                        name="locationGlobal"
                        checked={locationGlobal}
                        style={{ marginBottom: "20px" }}
                        onChange={(e) => setLocationGlobal(e.target.checked)}
                      />
                      {"  "} Location Global
                      <div class="form-group">
                        <label>Position</label>
                        <select
                          class="form-control select2"
                          style={{ width: "100%" }}
                          onChange={(e) => setPosition(e.target.value)}
                        >
                          <option value="Home page banner">
                            Home page banner
                          </option>
                          <option value="Live video section">
                            Live video section
                          </option>
                          <option value="A-Z streamer">A-Z Streamer</option>
                          <option value="Side bar">Side bar</option>
                          <option value="Profile page">Profile page</option>
                          <option value="Streaming page">Streaming Page</option>
                          <option value="Pop up">Pop up</option>
                        </select>
                      </div>
                      <label>AD Url</label>
                      <input
                        type="text"
                        name="message"
                        placeholder="AD Url"
                        class="form-control"
                        onChange={(e) => setAddUrl(e.target.value)}
                        style={{ marginBottom: "10px" }}
                      />
                      <div class="form-group">
                        <label>File input</label>
                        <div class="input-group">
                          <div class="custom-file">
                            <input
                              type="file"
                              id="exampleInputFile"
                              class="custom-file-input"
                              onChange={(e) =>
                                setField("file", e.target.files[0])
                              }
                              style={{ marginBottom: "10px" }}
                              accept="image/*"
                              required
                            />
                            {/* <input type="file" class="custom-file-input" value={file}  onChange={e => setField('file', e.target.files[0])} id="exampleInputFile"/> */}
                            <label
                              class="custom-file-label"
                              for="exampleInputFile"
                            >
                              Choose file
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errorMessage && (
                      <div
                        class="alert alert-success"
                        style={{
                          height: "50px",
                          width: "300px",
                          marginLeft: "20px",
                          marginTop: "20px",
                        }}
                        role="alert"
                      >
                        <p>{errorMessage}</p>
                      </div>
                    )}
                    {fillError ? (
                      <p style={{ color: "red", marginLeft: "20px" }}>
                        Please fill all the field
                      </p>
                    ) : null}
                    <div class="form-group">
                      {/* {isLoading ?
            <button type="button" class="btn btn-success btn-new" disabled>Submit</button>: */}
                      <button
                        type="button"
                        onClick={addAdvertidement}
                        class="btn btn-success btn-new"
                        disabled={isLoading}
                      >
                        Submit
                      </button>
                      {/* } */}
                    </div>
                    {/* <Modal.Dialog show={false} onHide={() => setEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Advertisement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="modal-body">
                <div class="form-group">
                  <label>Location Name</label>
                  <select
                    class="form-control select2"
                    style={{ width: "100%" }}
                    value={editLocationCode}
                    onChange={(e) => editLocationChange(e.target.value)}
                  >
                    {allCountries.map((item, index) => (
                      <option key={index} value={item.isoCode}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <div class="form-group">
                    <label>Position</label>
                    <select
                      class="form-control select2"
                      style={{ width: "100%" }}
                      value={editSelectedPosition}
                      onChange={(e) => setEditSelectedPosition(e.target.value)}
                    >
                      <option value="Home page banner">Home page banner</option>
                      <option value="Live video section">
                        Live video section
                      </option>
                      <option value="A-Z streamer">A-Z Streamer</option>
                      <option value="Side bar">Side bar</option>
                      <option value="Profile page">Profile page</option>
                      <option value="Streaming page">Streaming Page</option>
                      <option value="Pop up">Pop up</option>
                    </select>
                  </div>

                  <label>Ad Url</label>
                  <input
                    type="text"
                    name="message"
                    value={editAddUrl}
                    placeholder="Location Name"
                    class="form-control"
                    onChange={(e) => setEditAddUrl(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  />
                  {editSuccess && (
                    <div
                      class="alert alert-success"
                      style={{ height: "50px" }}
                      role="alert"
                    >
                      <p>{editSuccess}</p>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary">Close</Button>
              <Button variant="primary" onClick={editAdvertisement}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal.Dialog> */}
                    <div class="card-body">
                      <div class="card-header">
                        <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Your Advertisement Banner List
                        </h3>
                      </div>
                      <table
                        id="example2"
                        class="table table-bordered table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>File Name</th>
                            <th>Banner</th>
                            <th>Location</th>
                            <th>Position</th>
                            <th>AD Url</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {advertisement.map((item, index) => {
                            console.log("DateString", item.date);
                            return (
                              <tr>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  {Utils.changeSlashDateFormat(item.date)}
                                </td>
                                <td>{item.filename}</td>
                                <td>
                                  <img
                                    src={`${process.env.REACT_APP_AWS_RESOURCE_PATH_IMAGES}${item.url}`}
                                    style={{ height: "150px", width: "150px" }}
                                  />
                                </td>
                                <td>
                                  {item.locationGlobal
                                    ? "Global"
                                    : item.location}
                                </td>
                                <td>{item.position}</td>
                                <td>{item.url}</td>
                                <td>
                                  {/* <button
                          type="button"
                          class="btn btn-success btn-new"
                          data-toggle="modal"
                          onClick={() => {
                            editAdvertisementCategory(item);
                          }}
                          data-target="#modal-default"
                        >
                          Edit
                        </button> */}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      deleteAdvertisement(item);
                                    }}
                                    class="btn btn-danger btn-new"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot></tfoot>
                      </table>
                    </div>
                  </div>
                </Container>
              </TabPanel>
              <TabPanel value="2">
                <VideoAds />
              </TabPanel>
            </Box>
          </TabContext>
        </div>
      </div>
    </>
  );
}

export default Advertisement;
