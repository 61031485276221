import React, {useState, useEffect} from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Subusers.css'
import $ from "jquery";
import { endpoints } from '../../endpoints/endpoint';

function Subusers() {
  const [errorMessage, setErrorMessage] = useState()
  const [editErrorMessage, setEditErrorMessage] = useState()
  const [deleteErrorMessage, setDeleteErrorMessage] = useState()
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [role, setRole] = useState()
  const [allSubUsers, setAllSubUsers] = useState([])
  const [subUserAllDetails, setSubUserAllDetails] = useState([])
  const [userRole, setUserRole] = useState()
  const [fillError, setFillError] = useState(false)
  useEffect(() => {
    listAllSubUsers()
}, []);
const listAllSubUsers = (event, values) => {
  const obj = JSON.parse(localStorage.getItem("adminResponse"))
  setUserRole(obj.role)
  
  $.ajax({
    url: endpoints.listSubUser,
    dataType: 'json',
    contentType: 'application/json',
    type: 'get',
    headers: {
      'Authorization': 'Bearer ' + obj.accessToken
    },
    // data: JSON.stringify(payload),
    processData: false,
    success: function( data, textStatus, jQxhr ){
      if(data && data.length > 0) {
        setAllSubUsers(data)
        }
    },
});
}

  const addSubUser = (e)=>{
    e.preventDefault();
    
    if(name && email && password){
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload= {
      username: name,
      // role: role,
      mail: email,
      password: password
    }
    $.ajax({
      url: endpoints.addSubUser,
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      data: JSON.stringify(payload),
      contentType: 'application/json',
      processData: false,
      success: function( data, textStatus, jQxhr ){
        
        if(data.message == "subuser added successfully") {
          setErrorMessage("subuser added successfully")
          setTimeout(myFunction, 5000);
         
        } else {
          setErrorMessage("subuser not added")
         
        }
      },
      complete: function() {
        listAllSubUsers()
      }
  });
}else{
    setFillError(true)
    setTimeout(removeFillError, 10000);
}
}

const editSubUser = (subUserDetails)=>{
  setSubUserAllDetails(subUserDetails)
}

const editSubUserClick =(e)=>{
  e.preventDefault();
  
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload= {
      subuserId: subUserAllDetails._id,
      username: subUserAllDetails.name,
      // role:subUserAllDetails.role,
      mail:subUserAllDetails.mail,
     
    }
    $.ajax({
      url: endpoints.editSubUser,
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      data: JSON.stringify(payload),
      contentType: 'application/json',
      processData: false,
      success: function( data, textStatus, jQxhr ){
        
        if(data.message == "subuser edited successfully"){
        setEditErrorMessage("Updated successfully")
        setTimeout(removeEditErrorMessage, 5000);
        listAllSubUsers()
        }else{
          setEditErrorMessage("Error please try again")
        }
      },
  });
}

const UpdateName = (name) => {
  const existingData = {...subUserAllDetails};
  existingData.username = name;
  existingData.name = name;
  setSubUserAllDetails(existingData);
}
const UpdateEmail = (email) => {
  const existingData = {...subUserAllDetails};
  existingData.mail = email;
  existingData.email = email;
  setSubUserAllDetails(existingData);
}
const UpdatePassword = (password) => {
  const existingData = {...subUserAllDetails};
  existingData.password = password;
  setSubUserAllDetails(existingData);
}
const UpdateRole = (role) => {
  const existingData = {...subUserAllDetails};
  existingData.role = role;
  setSubUserAllDetails(existingData);
}

const deleteSubscription = (subuserId)=>{
  const obj = JSON.parse(localStorage.getItem("adminResponse"))
  const payload= {
    subuserId: subuserId
  }
  $.ajax({
    url: endpoints.deleteSubUser,
    type: 'post',
    headers: {
      'Authorization': 'Bearer ' + obj.accessToken
    },
    data: JSON.stringify(payload),
    contentType: 'application/json',
    processData: false,
    success: function( data, textStatus, jQxhr ){
      if(data.message ==  "subuser deleted"){
        setDeleteErrorMessage('subuser deleted')
        setTimeout(removeDeleteErrorMessage, 5000);
        listAllSubUsers()
      }else{
        setDeleteErrorMessage('subuser not deleted')
      }
    },
});
}
const myFunction = () =>{
  setErrorMessage("")
}
const removeEditErrorMessage = () =>{
  setEditErrorMessage("")
}
const removeDeleteErrorMessage = () =>{
  setDeleteErrorMessage("")
}
const removeFillError = () =>{
  setFillError(false)
}
    return (
       <>
        <Sidebar currentPage="subusers"/>
       <div class="content-wrapper " style={{backgroundColor:"white"}}>
       <div class="card-header">
          <h3 style={{fontSize:'20px', fontWeight:'bold'}}>Subuser</h3>
       </div>
       {/* <button type="button" class="btn btn-block btn-success btn-new" data-toggle="modal" data-target="#modal-default">Add New</button> */}
       {userRole == "admin" ?
       <button type="button" class="btn btn-success btn-new" data-toggle="modal" data-target="#modal-default">Add New</button>
       :null}
       <div class="row" style={{marginTop:'20px', marginLeft:'20px'}}>
       {allSubUsers.map((item, index) => { return <div class="card " key={index} style={{width:'18rem', border:'1px solid black', marginRight:'20px'}}>
       {/* <div class="card" style={{width:'18rem'}}> */}
       <ul class="list-group list-group-flush" style={{marginRight:'30px', marginTop:'30px'}}>
            {/* <li class="nav-icon fas fa-user user"></li> */}
            <li class="list-group-item">Username : {item.username}</li>
            {/* <li class="list-group-item"><p>Email : {item.mail}</p></li> */}
            <li class="list-group-item"><p>Role : {item.role}</p></li>
           
            {userRole == "admin" ?
            <div class="card-body row">
              <button type="button" class="btn btn-success btn-new col-sm" onClick={() => editSubUser(item)} data-toggle="modal" data-target="#modal-default1" style={{cursor:'pointer'}}>Update</button>
            <button type="button" class="btn btn-danger btn-new col-sm" onClick={() => deleteSubscription(item._id)} style={{cursor:'pointer'}}>Delete</button>
            {/* <span class="right badge-ban badge badge-success" onClick={() => editSubUser(item)} data-toggle="modal" data-target="#modal-default1" style={{cursor:'pointer'}}>Update</span>
            <span class="right badge-ban badge badge-danger" onClick={() => deleteSubscription(item._id)} style={{cursor:'pointer'}}>Delete</span> */}
            </div>:null}
           
        </ul>
        </div>
         })}
         </div>
        <div className="modal fade show" id="modal-default">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add New User</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
            <div class="modal-body">
            <label>Username</label>
            <input type="text" name="message"  value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Full Name" class="form-control"  style={{marginBottom:'10px'}} required/>
            <label>Email</label>
            <input type="email" name="message" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Email" class="form-control" style={{marginBottom:'10px'}} required/>
            <label>Password</label>
            <input type="passord" name="email" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="Password" class="form-control" required/>
            {/* <label>Role</label>
            <input type="text" name="message" value={role} onChange={(e)=>{setRole(e.target.value)}} placeholder="Role" class="form-control" style={{marginBottom:'10px'}} required/> */}
            {errorMessage && <div class="alert alert-success" style={{height:'50px'}} role="alert"><p>{errorMessage}</p></div> }
            {fillError ? <p style={{color:'red'}}>Please fill all the field</p>: null}
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              <button type="submit" onClick={addSubUser} class="btn btn-primary">Add subuser</button>
            </div>
            </form>
          </div>
         
        </div>
        
      </div>
        </div>

      {/* edit modal */}

      <div className="modal fade show" id="modal-default1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Subuser</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
            <div class="modal-body">
            <label>Username</label>
            <input type="text" name="message" value={subUserAllDetails.username} onChange={(e)=>{UpdateName(e.target.value)}} placeholder="Full Name" class="form-control"  style={{marginBottom:'10px'}} required/>
            <label>Email</label>
            <input type="email" name="email" value={subUserAllDetails.mail} onChange={(e)=>{UpdateEmail(e.target.value)}} placeholder="Email" class="form-control" style={{marginBottom:'10px'}} required/>
            {/* <label>Password</label>
            <input type="passord" name="message" value={subUserAllDetails.password} onChange={(e)=>{UpdatePassword(e.target.value)}} placeholder="Password" class="form-control" required/> */}
            {/* <label>Role</label>
            <input type="text" name="message" value={subUserAllDetails.role} onChange={(e)=>{UpdateRole(e.target.value)}} placeholder="Role" class="form-control" style={{marginBottom:'10px'}} required/> */}
            {editErrorMessage && <div class="alert alert-success" style={{height:'50px'}} role="alert"><p>{editErrorMessage}</p></div> }
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
              <button type="submit" onClick={editSubUserClick} class="btn btn-primary">Edit subuser</button>
            </div>
            </form>
          </div>
         
        </div>
        
      </div>
       
       </>
    )
}

export default Subusers
