import { React, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import useLives from "../../hooks/useLives";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Tooltip } from "react-bootstrap";
import LiveVideoCard from "./LiveVideoCard";
import { AppContext } from "../../context/AppContext";
import Arn from "aws-arn";

import "./Lives.css";
import { useEffect } from "react";

function Lives() {
  const history = useHistory();
  const { liveVideos } = useContext(AppContext);

  const navigateSingleLive = (video) => {
    const arn = Arn.parse(video.arn);
    if (!arn?.resourcePart) return;
    history.push(`/viewlive/${video?._id}/${arn?.resourcePart}`);
  };

  return (
    <>
      <Sidebar currentPage="lives" />
      <div
        class="content-wrapper"
        style={{ backgroundColor: "white", padding: "0 20px" }}
      >
        <h2 className="p-2">All Lives</h2>

        <div className="row">
          {liveVideos.length !== 0 &&
            liveVideos.map((video) => {
              console.log("videoData", video);
              return (
                <LiveVideoCard
                  video={video}
                  onClick={() => navigateSingleLive(video)}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Lives;
