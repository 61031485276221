import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Reports.css'
import $ from "jquery";
import { endpoints } from '../../endpoints/endpoint';
import moment from 'moment';

function Reports() {
  const [tableClick, setTableClick] = useState('users')
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [memberReport, setMemberReport] = useState([])
  const [userReport, setUserReport] = useState([])
  const [trafficReport, setTrafficReport] = useState([])
  const [subscriptionReport, setSubscriptionReport] = useState([])
  const [total, setTotal] = useState()
  const [liveViewersReport, setLiveViewersReport] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)

  const [memberReportError, setMemberReportError] = useState()
  const [userReportError, setUserReportError] = useState()
  const [trafficReportError, setTrafficReportError] = useState()
  const [subscriptionReportError, setSubscriptionReportError] = useState()
  const [liveViewersReportError, setLiveViewersReportError] = useState()

  console.log(tableClick)
  //   useEffect(() => {
  //     listUserReport()
  //     listMemberReport()
  //     listTrafficReport()
  //     listSubscriptionReport()
  //     listLiveViewersReport()
  // }, []);
  const goButtonClick = () => {

    if (tableClick == 'users') {
      listUserReport()
    } else if (tableClick == 'members') {
      listMemberReport()
    } else if (tableClick == 'liveviewers') {
      listLiveViewersReport()
    } else if (tableClick == 'traffic') {
      listTrafficReport()
    } else if (tableClick == 'subscription') {
      listSubscriptionReport()
    }
  }

  const listUserReport = () => {
    setLiveViewersReportError("")
    setMemberReportError("")
    setSubscriptionReportError("")
    setTrafficReportError("")
    setUserReportError("")
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload = {
      from: fromDate,
      to: toDate
    }
    $.ajax({
      url: endpoints.userReport,
      dataType: 'json',
      data: JSON.stringify(payload),
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      contentType: 'application/json',
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setUserReport(data)
        setUserReportError(data.message)
        setTableClick('users')
      }
    });

  }

  const listMemberReport = () => {
    setLiveViewersReportError("")
    setMemberReportError("")
    setSubscriptionReportError("")
    setTrafficReportError("")
    setUserReportError("")
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload = {
      from: fromDate,
      to: toDate
    }
    $.ajax({
      url: endpoints.memberReport,
      dataType: 'json',
      data: JSON.stringify(payload),
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      contentType: 'application/json',
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setMemberReport(data)
        setMemberReportError(data.message)
        setTableClick('members')
      }
    });
  }

  const listTrafficReport = () => {
    setLiveViewersReportError("")
    setMemberReportError("")
    setSubscriptionReportError("")
    setTrafficReportError("")
    setUserReportError("")
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload = {
      from: fromDate,
      to: toDate
    }
    $.ajax({
      url: endpoints.trafficReport,
      dataType: 'json',
      data: JSON.stringify(payload),
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      contentType: 'application/json',
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setTrafficReport(data)
        setTrafficReportError(data.message)
      }
    });
  }
  const listSubscriptionReport = () => {
    setLiveViewersReportError("")
    setMemberReportError("")
    setSubscriptionReportError("")
    setTrafficReportError("")
    setUserReportError("")
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload = {
      from: fromDate,
      to: toDate
    }
    $.ajax({
      url: endpoints.subscriptionReport,
      dataType: 'json',
      data: JSON.stringify(payload),
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      contentType: 'application/json',
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setSubscriptionReportError(data.message)
        setSubscriptionReport(data.data)
        setTotal(data.total)

      }
    });
  }

  const listLiveViewersReport = () => {
    setLiveViewersReportError("")
    setMemberReportError("")
    setSubscriptionReportError("")
    setTrafficReportError("")
    setUserReportError("")
    const obj = JSON.parse(localStorage.getItem("adminResponse"))
    const payload = {
      from: fromDate,
      to: toDate
    }
    $.ajax({
      url: endpoints.liveViewersReport,
      dataType: 'json',
      data: JSON.stringify(payload),
      type: 'post',
      headers: {
        'Authorization': 'Bearer ' + obj.accessToken
      },
      contentType: 'application/json',
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setLiveViewersReport(data)
        setLiveViewersReportError(data.message)
      }
    });
  }

  const changeReport = (value) => {
    setMemberReport([]);
    setUserReport([])
    setSubscriptionReport([])
    setLiveViewersReport([])
    setTrafficReport([])
    setTableClick(value)
  }
  return (
    <>
      <Sidebar currentPage="reports" />
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        <div class="card ">
          <div class="card-header">
            <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>Reports</h3>
          </div>
          <div className='card-body row' >
            <div class="form-group col-lg-3" >
              <label>Select one</label>
              {/* onChange={e => setTableClick( e.target.value)} */}
              <select class="form-control select2" onChange={e => changeReport(e.target.value)} style={{ width: '100%' }}>
                <option selected="selected" value={'users'}>Users</option>
                <option value={'members'}>Members</option>
                <option value={'liveviewers'}>Live viewers</option>
                <option value={'traffic'}>Traffic</option>
                <option value={'subscription'}>Subscription</option>
              </select>
            </div>
            <div class="col-lg-3">
              <label>Start From</label>
              <input type="date" name="message" onChange={e => setFromDate(e.target.value)} class="form-control" style={{ marginBottom: '10px' }} />
            </div>
            <div class="col-lg-3">
              <label>To</label>
              <input type="date" name="message" onChange={e => setToDate(e.target.value)} class="form-control" style={{ marginBottom: '10px' }} />
            </div>
            <div>
              <button type="button" class="btn btn-success btn-new" onClick={goButtonClick} style={{ marginTop: '30px' }}>Go</button></div>
          </div>
          {/* {memberReport.length == 0 || liveViewersReport.length == 0 ? <p style={{marginLeft:'20px'}}>No data found</p> : null} */}
        </div>
      </div>

      {/* user Reports */}
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        {userReportError ? <p style={{ marginLeft: '20px' }}>No data found</p> : null}
      </div>

      {tableClick == 'users' && userReport.length > 0 ?
        <>
          <div class="content-wrapper" style={{ backgroundColor: "white", padding: '50px' }}>
            <h3 className='card-header'>User Reports</h3>
            <table id="example2" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date Added</th>
                  <th>Full name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userReport.map((item, index) => (
                  <>
                    <tr>

                      <td>{moment(item.registration_date).format("DD-MM-YYYY")}</td>
                      <td>{item.firstname + ' ' + item.lastname}</td>
                      <td>{item.mail}</td>
                      <td>{item.mobile}</td>
                      <td>{item.status}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </> : <p>No data found</p>}
      {/* member Reports */}
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        {memberReportError ? <p style={{ marginLeft: '20px' }}>No data found</p> : null}
      </div>
      {tableClick == 'members' && memberReport.length > 0 ?
        <>
          <div class="content-wrapper" style={{ backgroundColor: "white", padding: '50px' }}>
            <h3 className='card-header'>Member Reports</h3>
            <table id="example2" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date Joined</th>
                  <th>Username</th>
                  <th>nickname</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {memberReport.map((item, index) => (
                  <>
                    <tr>
                      <td>{moment(item.registration_date).format("DD-MM-YYYY")}</td>
                      <td>{item.username}</td>
                      <td>{item.nickname}</td>
                      <td>{item.mail}</td>
                      <td>{item.mobile}</td>
                      <td>{item.status}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </> : null}

      {/* live viewers count */}
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        {liveViewersReportError ? <p style={{ marginLeft: '20px' }}>No data found</p> : null}
      </div>
      {tableClick == 'liveviewers' && liveViewersReport.length > 0 ?
        <>
          <div class="content-wrapper col-lg-3" style={{ backgroundColor: "white", padding: '50px' }}>
            <h3 className='card-header'>Live viewers count</h3>
            <table id="example2" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {liveViewersReport.map((item, index) => (
                  <>
                    <tr>
                      <td>{moment(item.streamdate).format("DD-MM-YYYY")}</td>
                      <td>{item.viewers}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </> : null}
      {/* Traffic Report */}
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        {trafficReportError ? <p style={{ marginLeft: '20px' }}>No data found</p> : null}
      </div>
      {tableClick == 'traffic' && trafficReport.length > 0 ?
        <>
          <div class="content-wrapper col-lg-3" style={{ backgroundColor: "white", padding: '50px' }}>
            <h3 className='card-header'> Traffic Report</h3>

            <table id="example2" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {trafficReport.map((item, index) => (
                  <>
                    <tr>
                      <td>{item.date}</td>
                      <td>{item.count}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </> : null}

      {/* Subscriptoin Report */}
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        {subscriptionReportError ? <p style={{ marginLeft: '20px' }}>No data found</p> : null}
      </div>
      {tableClick == 'subscription' && subscriptionReport.length > 0 ?
        <>
          <div class="content-wrapper col-lg-6" style={{ backgroundColor: "white", padding: '50px' }}>
            <h3 className='card-header'>Subscription Report</h3>
            <table id="example2" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Costomer Username</th>
                  <th>Month(Duration)</th>
                  <th>Amount</th>
                  <th>Subscription Type</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionReport && subscriptionReport.map((item, index) => (
                  <>
                    <tr>
                      <td>{moment(item.subscriptionDate).format("DD-MM-YYYY")}</td>
                      <td>{item.username}</td>
                      <td>{item.duration}</td>
                      <td>{item.amount}</td>
                      <td>{item.title}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <p>Total : {total}</p>
          </div>
        </> : null}
    </>
  )
}

export default Reports
