import $ from "jquery";
import { useEffect, useState } from "react";
import axios from "axios";
import { endpoints } from "../endpoints/endpoint";

const useVideoAds = () => {
  const [loading, setLoading] = useState(false);
  const [videoAds, setVideoAds] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    getVideoAdsSettings();
  }, []);

  const getVideoAdsSettings = async () => {
    setLoading(true);

    $.ajax({
      url: endpoints.getVideoAdsSettings,
      dataType: "json",
      type: "post",
      contentType: "application/json",
      data: JSON.stringify({}),
      processData: false,
      success: function (res, textStatus, jQxhr) {
        if (res.error == false) {
          setVideoAds(res);
        }
      },
      error: function (err) {
        setVideoAds({});
        console.log("err", err);
      },
      complete: function () {
        setLoading(false);
      },
    });
  };

  const uploadAdvert = async ({ payload, onSuccess }) => {
    try {
      setUploadProgress(0);

      await axios
        .post(endpoints.addInventoryVideoAds, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          },
        })
        .then((res) => {
          console.log("res", res);
          onSuccess();
          setTimeout(() => {
            setUploadProgress(0);
          }, 2000);
        })
        .catch((error) => {
          console.error("Error", error);
          setUploadProgress(0);
        });
    } finally {
      setUploadProgress(0);
      getVideoAdsSettings();
    }
  };

  const updateSetting = async (payload, { onSuccess, onFailure }) => {
    setActionLoading(true);
    try {
      await axios
        .post(endpoints.addInventoryAdSettings, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("Response", res);
          debugger;
          if (res?.data?.response?.acknowledged == true) {
            onSuccess && onSuccess();
          } else {
            onFailure && onFailure();
          }
        })
        .catch((error) => {
          console.error("Error", error);
          onFailure && onFailure();
        });
    } finally {
      setActionLoading(false);
      getVideoAdsSettings();
    }
  };

  return {
    videoAds,
    uploadAdvert,
    adsLoading: loading,
    uploadProgress,
    updateSetting,
    actionLoading,
    getVideoAdsSettings,
  };
};

export default useVideoAds;
