import React, { useState } from "react";
import { useHistory } from "react-router";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorData, setErrorData] = useState();
  const history = useHistory();
  function submitForm(e) {
    e.preventDefault();
    if (email && password) {
      setErrorData("");
      setIsLoading(true);
    }
    const payload = {
      username: email,
      password: password,
    };
    $.ajax({
      url: endpoints.adminLogin,
      dataType: "json",
      type: "post",
      contentType: "application/json",
      data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data && data.accessToken) {
          localStorage.setItem("adminResponse", JSON.stringify(data));
          window.location.href = "/users";
        } else {
          setErrorData(data.message);
        }
      },
      error: function (jqXhr, textStatus, errorThrown) {
        setErrorData("Unable to login, Please try again");
      },
      complete: function () {
        setIsLoading(false);
      },
    });

    return false;
  }
  return (
    <>
      <div class="hold-transition login-page">
        <div class="login-box">
          <div class="card card-outline card-primary">
            <div class="card-header text-center">
              <a href="../../index2.html" class="h1">
                <b>Create</b>Royale
              </a>
            </div>
            <div class="card-body">
              <p class="login-box-msg">Sign in to start</p>

              <form>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Username"
                    required
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    class="form-control"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Password"
                    required
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                {errorData && (
                  <div
                    class="alert alert-danger"
                    style={{ height: "50px" }}
                    role="alert"
                  >
                    <p>{errorData}</p>
                  </div>
                )}
                <div class="row">
                  <div class="col-6">
                    {isLoading ? (
                      <button
                        class="btn btn-primary btn-block"
                        onClick={submitForm}
                        disabled
                      >
                        Sign In
                      </button>
                    ) : (
                      <button
                        type="submit"
                        class="btn btn-primary btn-block"
                        onClick={submitForm}
                      >
                        Sign In
                      </button>
                    )}
                  </div>
                </div>
              </form>

              {/* <p class="mb-1">
                <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p class="mb-0">
                <a href="register.html" class="text-center">Register a new membership</a>
            </p> */}
            </div>
          </div>
        </div>
      </div>
      {/* {isLoading && <div className="preloader transparentloader"></div>} */}
    </>
  );
}

export default Login;
