import "./modal.css";
import { Box, Modal } from "@mui/material";
import * as Ai from "react-icons/ai";
import useForm from "../../hooks/useForm";
import { useEffect } from "react";
const FormModal = (props) => {
  const [
    formValues,
    formErrors,
    formValidator,
    onChange,
    addValuesToForm,
    clearFormValues,
  ] = useForm({
    initialValues: props.initialValues,
    validator: (values) => props.formModalValidator(values),
  });

  useEffect(() => addValuesToForm(props.formValues), [props.formValues]);

  const handleSubmit = async () => {
    formValidator();
    if (Object.keys(props.formModalValidator(formValues)).length === 0) {
      await props.onSubmit(formValues);
      clearFormValues();
    }
  };

  return (
    <div class={props.showModal ? "formModal_bg hide" : "formModal_bg"}>
      <div class="formModal">
        <div class="form form_modal">
          <h2
            className="pb-2"
            style={props.titleStyle ?? { color: "#7777", fontWeight: "300" }}
          >
            {props.title ?? "Title"}
          </h2>
          <div class="form-group">
            <label for="firelTitle1">{props.fieldTitle1 ?? "Title"}</label>
            <input
              type="text"
              name={props.fieldName1 ?? "giftName"}
              id=""
              class="form-control form_txt"
              onChange={(e) => onChange(e)}
              value={formValues[props.fieldName1 ?? "giftName"]}
            />

            {formErrors[props.fieldName1 ?? "giftName"] && (
              <p className={"err show"}>
                {formErrors[props.fieldName1 ?? "giftName"]}
              </p>
            )}
          </div>
          <div class="form-group">
            <label for="fieldTitle2">{props.fieldTitle2 ?? "Credits"}</label>
            <input
              type="text"
              name={props.fieldName2 ?? "credits"}
              id=""
              class="form-control form_txt"
              onChange={(e) => onChange(e)}
              value={formValues[props.fieldName2 ?? "credits"]}
            />
            {formErrors[props.fieldTitle2 ?? "credits"] && (
              <p className={"err show"}>
                {formErrors[props.fieldTitle2 ?? "credits"]}
              </p>
            )}
          </div>
          {/* <div class="mb-3">
            <label for="formFile" class="form-label">
              {props.formFileFieldText ?? "Icon"}
            </label>
            <input class="form-control" type="file" id="formFile" />
          </div> */}
          <div class="form_btn_grp">
            <button className="submit" onClick={handleSubmit}>
              {props.submitBtnText ?? "Save"}
            </button>
            <button className="discard" onClick={props.onCancel}>
              {props.cancelBtnText ?? "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
