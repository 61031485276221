import { Box, Modal } from "@mui/material";

const ConfirmModal = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={props.openConfirmModal} onClose={props.closeConfirmModal}>
      <Box sx={style}>
        <h4>{props.title ?? "Confirm"}</h4>
        <p>{props.subTitle ?? "Are you sure You want to cancel ?"}</p>
        <div class="btn_grp">
          <button
            className="btn btn-primary"
            style={{ marginRight: "16px" }}
            onClick={props.onConfirm}
          >
            {props.confirmBtnText ?? "Yes"}
          </button>
          <button className="btn btn-danger" onClick={props.onCancel}>
            {props.cancelBtnText ?? "No"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
