import { useState, useEffect, useContext } from "react";
import { endpoints } from "../endpoints/endpoint";
import useLocalStorage from "./useLocalStorage";
import { SocketContext } from "../context/SocketContext";
import $ from "jquery";

const useLives = () => {
  const [liveVideos, setLiveVideos] = useState([]);
  const [loading, setLoading] = useState(null);
  const [socketEvent, setSocketEvent] = useState(null);

  const { getLoginResponseFromLocal } = useLocalStorage();

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    getAllLiveVideos();
    listenToSocket();
  }, []);

  useEffect(() => {
    handleSocketEvent();
  }, [socketEvent]);

  const getAllLiveVideos = async () => {
    setLoading(true);
    try {
      const response = getLoginResponseFromLocal();
      if (!response || !response.accessToken) return;

      await $.ajax({
        url: endpoints.listAllLives,
        dataType: "json",
        contentType: "application/json",
        type: "get",
        headers: {
          Authorization: "Bearer " + response.accessToken,
        },
        timeout: 30000,
        processData: false,
        success: function (response, textStatus, jQxhr) {
          if (!Array.isArray(response)) return;
          setLiveVideos(response);
        },
        error: function (jqXHR, exception) {
          console.error("Error", jqXHR);
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const listenToSocket = () => {
    socket.on("streamStarted", (data) => {
      setSocketEvent({ event: "streamStarted", data: data });
    });
    socket.on("streamStopped", (data) => {
      setSocketEvent({ event: "streamStopped", data: data });
    });
  };

  const handleSocketEvent = async () => {
    if (!socketEvent || !socketEvent.data) return;
    switch (socketEvent.event) {
      case "streamStarted":
        const data = socketEvent.data["data"];
        const id = data["_id"];
        const arn = data["arn"];
        const categories = data["categories"];
        const nickName = data["nickname"];
        const stream = data["stream"];
        const title = stream["title"];
        const room = stream["room"];
        const thumbnail = stream["thumbnailUrl"];
        const defaultThumbnail = stream["defaultthumbnailUrl"];
        const profilepic = data["profilepic"];
        const playbackUrl = data["playbackUrl"];

        let newLiveData = {
          _id: id,
          socketEvent: true,
          arn: arn,
          stream: {
            title: title,
            room: room,
            thumbnailUrl: thumbnail,
            defaultthumbnailUrl: defaultThumbnail,
          },
          categories: categories,
          nickname: nickName,
          profilepic: profilepic,
          no: null,
          playbackUrl: playbackUrl,
        };

        pushNewLiveToLiveList(newLiveData);
        break;
      case "streamStopped":
        const userId = socketEvent.data["userId"];
        removeStoppedVideoFromLiveList(userId);
        break;
      default:
        break;
    }
  };

  const pushNewLiveToLiveList = (newData) => {
    let locIndex = liveVideos.findIndex((video) => video._id === newData._id);
    if (locIndex != -1) return;
    let data = [...liveVideos, newData];
    setLiveVideos(data);
  };

  const removeStoppedVideoFromLiveList = (id) => {
    let newLiveVideos = liveVideos.filter((video) => video._id !== id);
    setLiveVideos(newLiveVideos);
  };

  return { liveVideos, loading };
};

export default useLives;
