import { useState, useEffect } from "react";

const useForm = ({ initialValues, hasFile, validator, fileRef }) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [intial, setIntial] = useState(false);

  useEffect(() => {
    if (intial) {
      formValidator();
    }
  }, [formValues]);

  const formValidator = () => {
    let result = validator(formValues);
    setFormErrors(result);
  };

  const onChange = (e) => {
    setIntial(true);
    const { name, value } = e.target;
    if (hasFile === true && e.target.files) {
      setFormValues({ ...formValues, [name]: e.target.files[0] });
      return;
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const addValuesToForm = (values) => {
    if (!values) return;
    setFormValues({ ...formValues, ...values });
  };

  const clearFormValues = () => {
    setIntial(false);
    setFormValues(initialValues);
    if (fileRef) fileRef.current.value = "";
    setFormErrors({});
  };

  return [
    formValues,
    formErrors,
    formValidator,
    (e) => onChange(e),
    addValuesToForm,
    clearFormValues,
  ];
};

export default useForm;
