import React, { useState, useEffect } from "react";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";
import Utils from "../../utils/utils";
import { PuffLoader } from "react-spinners";

function GiftingTable() {
  const [loading, setLoading] = useState(false);
  const [giftingHistory, setGiftingHistory] = useState([]);

  useEffect(() => {
    getGiftingHistroy();
  }, []);

  const getGiftingHistroy = () => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    $.ajax({
      url: endpoints.giftingHistory,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      success: function (res, textStatus, jQxhr) {
        if (res.data && res.data.length > 0) {
          //   debugger;
          setLoading(true);
          setGiftingHistory(res.data);
        }
      },
      complete: function () {
        setLoading(false);
      },
    });
  };
  return (
    <>
      <table class="table table-bordered table-hover text-center">
        <thead>
          <th>Gifted By</th>
          <th>Gift To</th>
          <th>Date</th>
          <th>Gift Name</th>
          <th>Credits</th>
          <th>Value(£)</th>
        </thead>
        {giftingHistory.length > 0 && !loading && (
          <tbody>
            {giftingHistory.map((xGift , index) => {
              // console.log(gift, "gifts");
              const giftData = xGift.gifthistory;
              const StreamerData = xGift.streamer;

              if (giftData == null || giftData.length == 0) return null;

              return giftData.map((gift, index) => {
                const date = Utils.getDate(gift.date);
                return <tr>
                  <td>{gift?.user?.nickname ?? "Not Available"}</td>
                  <td>{StreamerData?.nickname ?? "Not Available"}</td>
                  <td>{date ?? "Not Available"}</td>
                  <td>{gift?.giftname ?? "Not Available"}</td>
                  <td>{gift?.credit ?? "Not Available"}</td>
                  <td>{gift?.amount ?? "Not Available"}</td>
                </tr>
              })




            })}
          </tbody>
        )}
      </table>
      {loading && (
        <div class="loader_">
          <PuffLoader
            color="#eb100c"
            loading
            size={70}
            className="puff_loader"
          />
        </div>
      )}
    </>
  );
}

export default GiftingTable;
