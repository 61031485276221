import React, { useContext, useEffect, useState } from "react";
import * as Ai from "react-icons/ai";
import { endpoints } from "../../endpoints/endpoint";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { BeatLoader } from "react-spinners";

function BanModal({ BanModalopen, onBanModalClose, banData, onBanSuccess }) {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [banError, setBanError] = useState(false);

  const handleReason = (value) => {
    if (value.length < 0 && value === "") {
      setBanError(true);
      return;
    }
    setReason(value);
  };

  const handleBanMember = async (id) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: id,
      adminId: obj.adminId,
      reason: reason,
    };
    setLoading(true);
    await $.ajax({
      url: endpoints.bannMembers,
      dataType: "json",
      data: JSON.stringify(payload),
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        onBanSuccess();
        toast.success("Banned successfully!");
        setTimeout(() => {
          onBanModalClose();
        }, 1000);
      },
      complete: () => {
        setLoading(false);
        setReason("")
      },
    });
  };

  if (!BanModalopen) return null;

  return (
    <>
      <Toaster position="top-center"></Toaster>
      <div class="modal-wrapper">
        <div class="modal-container ban-modal">
          <div
            className="close"
            onClick={() => {
              onBanModalClose();
              setReason("");
            }}
          >
            <Ai.AiOutlineClose />
          </div>
          <h2>Ban A Memeber</h2>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th>Username</th>
                <th>Nickname</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{banData?.username ?? "N/A"}</td>
                <td>{banData?.nickname ?? "N/A"}</td>
                <td>{banData?.mail ?? "N/A"}</td>
                <td>
                  {banData?.mobile != "" ? banData?.mobile ?? "N/A" : "N/A"}
                </td>
                <td>{banData?.status ?? "N/A"}</td>
              </tr>
            </tbody>
          </table>
          <div class="form-group">
            <label for="Banning Reason">Banning Reason</label>
            <textarea
              className="form-control"
              value={reason}
              onChange={(e) => handleReason(e.target.value)}
            />
            {banError ? (
              <span style={{ color: "red" }}>Reason is required!</span>
            ) : (
              ""
            )}
          </div>
          <br />
          <button
            class="btn btn-danger ban_btn"
            onClick={() => handleBanMember(banData._id)}
            disabled={!loading ? false : true}
          >
            {!loading ? (
              "Ban Member"
            ) : (
              <div class="loader_">
                <BeatLoader
                  color="#fff"
                  loading
                  size={10}
                  className="puff_loader"
                />
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default BanModal;
