import React from "react";
import { Player, BigPlayButton } from "video-react";

const PreviewPlayer = (props) => {
    return <Player
        playsInline
        src={props.file ? URL.createObjectURL(props.file) : ""}
        aspectRatio="16:9"
    >
        <BigPlayButton position="center" />
    </Player>
}

export default PreviewPlayer;