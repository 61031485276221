import React, { useState, useEffect } from "react";
import "./Settings.css";

import Sidebar from "../Sidebar/Sidebar";
import { TabContext, tabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Modal, Typography } from "@mui/material";
import { Button, Container } from "react-bootstrap";
import * as Gi from "react-icons/gi";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Im from "react-icons/im";
import * as Md from "react-icons/md";
import { RiPlayListAddFill } from "react-icons/ri";

import CreditPlans from "./CreditPlanData";
import GiftData from "./GiftData";
import useSettings from "../../hooks/useSettings";
import Utils from "../../utils/utils";
import ConfirmModal from "../modal/ConfirmModal";
import FormModal from "../modal/FormModal";
import useForm from "../../hooks/useForm";
import { useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import GiftingTable from "./GiftingTable";

function Settings() {
  const fileInputFieldRef = useRef();
  const [tabValue, setTabvalue] = useState("1");
  const [textAlert, setTextAlert] = useState(false);
  const [hideCard, setHideCard] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [sureModal, setSureModal] = useState(false);
  const [selectedGiftId, setSelectedGiftId] = useState(null);
  const [showdeleteConfirm, setShowDeleteConfrim] = useState(false);
  const initialValues = { giftName: "", credits: "", file: null };
  const initialEditValues = {
    giftId: "",
    giftName: "",
    credits: "",
  };
  const [editGiftData, setEditGiftData] = useState(initialEditValues);

  const [formValues, formErrors, formValidator, onChange, clearFormValues] =
    useForm({
      initialValues: initialValues,
      hasFile: true,
      validator: (values) => creditFormValidator(values),
      fileRef: fileInputFieldRef,
    });

  const {
    creditLimit,
    setCreditLimit,
    updateCreditLimit,
    enableSaveBtn,
    creditPurchasePlans,
    updateCreditPurchasePlan,
    giftingPlans,
    loading,
    actionloading,
    updateGifting,
    addGiftingPlan,
    deleteGiftingPlan,
  } = useSettings();

  const tabChange = (eve, newValue) => {
    setTabvalue(newValue);
  };

  const creditFormValidator = (values) => {
    const errors = {};
    if (!values.giftName) {
      errors.giftName = "This field cannot be blank";
    }
    if (!values.credits) {
      errors.credits = "This field cannot be blank";
    }
    if (values.credits.length > 0 && isNaN(values.credits)) {
      errors.credits = "Numbers are not allowed";
    }
    return errors;
  };

  const onClickBtnOnPlan = (plan) => {
    updateCreditPurchasePlan(plan, {
      onSuccess: function () {
        toast.success("Credit Purchase Plan Updated Successfully");
      },
      onFailure: function () {
        toast("Failed To Credit Purchase Plan Updated", {
          icon: <Md.MdError />,
          style: {
            color: "#F20000",
          },
        });
      },
    });
  };

  const onClickAddBtn = (e) => {
    e.preventDefault();
    formValidator();
    if (Object.keys(creditFormValidator(formValues)).length === 0) {
      addGiftingPlan(formValues, {
        onSuccess: function () {
          toast.success("Gifting Plan Added Successfully");
          return clearFormValues();
        },
        onFailure: function () {
          toast("Failed To Add Gifting Plan", {
            icon: <Md.MdError />,
            style: {
              color: "#F20000",
            },
          });
        },
      });
    }
  };

  const onConfirmDelete = () => {
    if (!selectedGiftId) return;
    deleteGiftingPlan(selectedGiftId, {
      onSuccess: function () {
        toast.success("Gifting Plan Deleted Successfully");
        return clearFormValues();
      },
      onFailure: function () {
        toast("Failed To Delete Gifting Plan", {
          icon: <Md.MdError />,
          style: {
            color: "#F20000",
          },
        });
      },
    });
    closeDeleteConfrimModal();
  };

  const onConfirmUpdate = (newData) => {
    closeEditModal();
    updateGifting(newData, {
      onSuccess: function () {
        toast.success("Gifting Plan Updated Successfully");
      },
      onFailure: function () {
        toast("Failed To Update Gifting Plan", {
          icon: <Md.MdError />,
          style: {
            color: "#F20000",
          },
        });
      },
    });
  };

  const onClickSaveBtn = () => {
    updateCreditLimit({
      onSuccess: function () {
        toast.success("Credits Limit Updated");
      },
      onFailure: function () {
        toast("Credits Limit Updated", {
          icon: <Md.MdError />,
          style: {
            color: "#F20000",
          },
        });
      },
    });
  };

  const onCreditChange = (e) => {
    let value = e.target.value;
    if (value < 0) return;
    setCreditLimit(value);
  };
  const disableEnable = (index) => {
    index === selectedIndex ? setSelectedIndex(-1) : setSelectedIndex(index);
  };

  const hanldeOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const openEdit = () => {
    setEditModal(true);
  };
  const toggleEdit = () => {
    setEditModal(!editModal);
  };

  const closeEdit = () => {
    setEditModal(false);
  };

  const closeEditModal = () => {
    setEditModal(false);
    setSureModal(false);
  };

  const openConfrimModal = () => {
    setEditModal(false);
    setSureModal(true);
  };

  const closeConfrimModal = () => {
    setSureModal(false);
    setEditModal(true);
  };

  const openDeleteConfrimModal = ({ _id }) => {
    setSelectedGiftId(_id);
    setShowDeleteConfrim(true);
  };
  const closeDeleteConfrimModal = () => {
    setShowDeleteConfrim(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setEditModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const onClickEditBtn = ({ _id, giftname, credit }) => {
    setEditGiftData({ giftId: _id, giftName: giftname, credits: credit });
  };

  useEffect(() => {
    const { giftName, credits } = editGiftData;
    if (!giftName && !credits) return;
    openEdit();
  }, [editGiftData]);

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false}></Toaster>
      <ConfirmModal
        openConfirmModal={showdeleteConfirm}
        closeConfirmModal={closeDeleteConfrimModal}
        onConfirm={onConfirmDelete}
        onCancel={closeDeleteConfrimModal}
      />
      <ConfirmModal
        openConfirmModal={sureModal}
        closeConfirmModal={closeConfrimModal}
        onConfirm={closeEditModal}
        onCancel={closeConfrimModal}
      />
      <FormModal
        title={"Edit Gift Plan"}
        showModal={editModal}
        formValues={editGiftData}
        initialValues={initialEditValues}
        formModalValidator={creditFormValidator}
        onSubmit={onConfirmUpdate}
        onCancel={openConfrimModal}
      />
      <Sidebar currentPage="settings" />
      <div class="col-xl-12">
        <div className="content-wrapper">
          <div class="settings_card">
            <div class="container">
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <h1 style={{ fontSize: "30px" }}>Gifting Control</h1>
                <Button
                  className="save_btn"
                  disabled={!enableSaveBtn}
                  onClick={onClickSaveBtn}
                >
                  Save
                </Button>
              </div>
              <br />

              <TabContext value={tabValue}>
                <Box>
                  <TabList
                    aria-label="tab example"
                    onChange={tabChange}
                    TabIndicatorProps={{
                      sx: {
                        background: "transparent",
                      },
                    }}
                  >
                    <Tab
                      label="Credits"
                      value="1"
                      className="tabss"
                      icon={<Gi.GiTwoCoins />}
                      iconPosition="start"
                    />
                    <Tab
                      label="Gifting"
                      value="2"
                      className="tabss"
                      icon={<Ai.AiFillGift />}
                      iconPosition="start"
                    />
                  </TabList>
                  <TabPanel value="1">
                    <Container>
                      {/* <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                          <div class="form-group credit_grp">
                            <label for="credit limit">
                              Credits Minimum Withdrawal
                            </label>
                            <input
                              type="number"
                              name=""
                              id=""
                              class="form-control credit_txt"
                              placeholder="Credits withdrawal limit"
                              value={creditLimit}
                              onChange={onCreditChange}
                            />
                            <p className={!textAlert ? "err " : "err show"}>
                              Numbers only allowed!
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <h4 style={{ marginBottom: "20px" }}>
                        Credit Purchase Plans
                      </h4>
                      <div class="row">
                        {creditPurchasePlans.map((plan, index) => (
                          <div class="col-lg-3 col-md-3 col-sm-3">
                            <div
                              class={`card ${
                                selectedIndex == index
                                  ? "credit_card disabled"
                                  : "credit_card"
                              }`}
                              key={index}
                            >
                              <div class="card-body">
                                <p>
                                  Credits: <span>{`${plan.credit} CR`}</span>
                                </p>
                                <p>
                                  Price:{" "}
                                  <span>{`${plan.value} ${plan.currency}`}</span>
                                </p>
                                <p>
                                  CR Part:{" "}
                                  <span>{`${plan.CRpart} ${plan.currency}`}</span>
                                </p>
                                <p>
                                  User Part:{" "}
                                  <span>{`${plan.userpart} ${plan.currency}`}</span>
                                </p>
                                <br />
                                {plan.status === "Enabled" ? (
                                  <button
                                    className="credit_btn"
                                    key={index}
                                    onClick={() => onClickBtnOnPlan(plan)}
                                  >
                                    Disable Plan
                                  </button>
                                ) : (
                                  <button
                                    className="credit_btn"
                                    key={index}
                                    onClick={() => onClickBtnOnPlan(plan)}
                                  >
                                    Enable Plan
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Container>
                  </TabPanel>
                  <TabPanel value="2">
                    <Container>
                      <h2>Add Gift</h2>
                      <form onSubmit={onClickAddBtn}>
                        <div class="row mt-4">
                          <div class="col-lg-4 col-sm-4 col-md-4">
                            <div class="form-group">
                              <label for="gift-name">Name</label>
                              <input
                                type="text"
                                name="giftName"
                                class="form-control gift_txt"
                                placeholder="Gift's Name"
                                value={formValues.giftName}
                                onChange={(e) => onChange(e)}
                              />
                              {formErrors.giftName && (
                                <p className={"err show"}>
                                  {formErrors.giftName}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-4 col-md-4">
                            <div class="form-group">
                              <label for="gift-credit">Credits</label>
                              <input
                                type="text"
                                name="credits"
                                id=""
                                class="form-control gift_txt"
                                placeholder="Gift's Credit Level"
                                value={formValues.credits}
                                onChange={(e) => onChange(e)}
                              />
                              {formErrors.credits && (
                                <p className={"err show"}>
                                  {formErrors.credits}
                                </p>
                              )}
                            </div>
                          </div>
                          <div class="col-lg-4 col-sm-4 col-md-4">
                            <div class="mb-3">
                              <label for="formFile" class="form-label">
                                Icon
                              </label>
                              <input
                                class="form-control"
                                ref={fileInputFieldRef}
                                type="file"
                                id="formFile"
                                name="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <button className="gift_form_btn" type="submit">
                          <i>
                            <RiPlayListAddFill />
                          </i>
                          Add
                        </button>
                      </form>

                      <div class="gift_section mt-4">
                        <h2>Gift Plans</h2>
                        <div class="row">
                          {giftingPlans.map((gift, index) => (
                            <div class="col-lg-3 col-md-3 col-sm-3">
                              <div className="card gift_card" key={index}>
                                <div class="card-body">
                                  <div class="gift_btn_grp d-flex">
                                    <button
                                      className="edit"
                                      onClick={() => onClickEditBtn(gift)}
                                    >
                                      <Bs.BsPencilFill />
                                    </button>
                                    <button
                                      className="delete"
                                      onClick={() =>
                                        openDeleteConfrimModal(gift)
                                      }
                                    >
                                      <Im.ImBin />
                                    </button>
                                  </div>
                                  <p>
                                    Title:{" "}
                                    <span>
                                      {Utils.titleCase(gift.giftname)}
                                    </span>
                                  </p>
                                  <p>
                                    Credits: <span>{gift.credit}</span>
                                  </p>
                                  <p>
                                    Icon:{" "}
                                    <span>
                                      <img
                                        className="gift_icon"
                                        src={
                                          process.env
                                            .REACT_APP_AWS_RESOURCE_PATH_GIFTING +
                                          gift.key
                                        }
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <GiftingTable/> */}
                    </Container>
                  </TabPanel>
                </Box>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
