import React, { useState, useEffect } from "react";
import $ from "jquery";
import { endpoints } from "../endpoints/endpoint";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

export default function useMemeber({ userId }) {
  const [member, setMember] = useState([]);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    getSingleMemberById(userId);
  }, []);

  const getSingleMemberById = async (value) => {
    try {
      setUserDetailsLoading(true);
      const obj = JSON.parse(localStorage.getItem("adminResponse"));
      if (!obj || !obj.accessToken) {
        return;
      }
      const payload = { userId: value };

      $.ajax({
        url: endpoints.getSingleMember,
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        data: JSON.stringify(payload),
        contentType: "application/json",
        processData: false,
        success: function (data, textStatus, jQxhr) {
          if (data.length > 0) {
            setMember(data[0]);
          }
        },
        complete: function () {},
      });
    } finally {
      setUserDetailsLoading(false);
    }
  };

  const deletePhotoById = async (name) => {
    console.log(name, "image url");
    setLoading(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: userId,
      name: name,
    };
    console.log("payload", payload);
    await $.ajax({
      url: endpoints.deletePhotoById,
      dataType: "json",
      contentType: "application/json",
      type: "delete",
      data: JSON.stringify(payload),
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      success: function (res, textStatus, jQxhr) {
        toast.success("Photo deleted Successfully!");
      },
      complete: function () {
        setLoading(false);
        getSingleMemberById(userId);
      },
    });
  };

  const deleteVideoById = async (name) => {
    console.log(name, "video url");
    setLoading(true);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      userId: params?.id,
      name: name,
    };
    await $.ajax({
      url: endpoints.endponitname,
      dataType: "json",
      contentType: "application/json",
      type: "delete",
      data: JSON.stringify(payload),
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      success: function (res, textStatus, jQxhr) {
        toast.success("Video removed Successfully!");
      },
      complete: function () {
        setLoading(false);
        getSingleMemberById(userId);
      },
    });
  };

  return {
    getSingleMemberById,
    member,
    userDetailsLoading,
    loading,
    deletePhotoById,
    deleteVideoById,
  };
}
