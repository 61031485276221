import React from "react";
import Login from "../Component/Login/Login";
import Sidebar from "../Component/Sidebar/Sidebar";
import Subscription from "../Component/Subscription/Subscription";
import Emails from "../Component/Emails/Emails";
import Categories from "../Component/Categories/Categories";
import Subusers from "../Component/Subusers/Subusers";
import Advertisement from "../Component/Advertisement/Advertisement";
import Statistics from "../Component/Statistics/Statistics";
import Reports from "../Component/Reports/Reports";
import TermsAndConditions from "../Component/TermsAndConditions/TermsAndConditions";
import MemberDetails from "../Component/MemberDetails/MemberDetails";
import Payouts from "../Component/Payouts/Payouts";
import Settings from "../Component/Settings/Settings";
import Lives from "../Component/Lives/Lives";
import Transaction from "../Component/Transaction/Transaction";
import ViewLive from "../Component/Lives/ViewLive";
import ViewMember from "../Component/Members/ViewMember";
import RedirectError from "../Component/RedirectError/RedirectError";
import PageNotFound from "../Component/RedirectError/PageNotFound";
import Members from "../Component/Members/Members";
const appRoutes = [
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/",
    Element: Login,
  },
  {
    isProtected: true,
    path: "/sidebar",
    Element: Sidebar,
    role: ["Superadmin", "Admin"],
  },
  {
    isProtected: true,
    isProtectedAuth: true,
    path: "/users",
    Element: Members,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/subscription",
    Element: Subscription,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/emails",
    Element: Emails,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/categories",
    Element: Categories,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/subusers",
    Element: Subusers,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/advertisement",
    Element: Advertisement,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/statistics",
    Element: Statistics,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/reports",
    Element: Reports,
    role: ["Superadmin", "admin"],
  },
  {
    path: "/termsandconditions",
    Element: TermsAndConditions,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/memberdetails",
    Element: MemberDetails,
    role: ["Superadmin", "admin"],
  },

  {
    isProtected: true,
    path: "/payouts",
    Element: Payouts,
    role: ["Superadmin"],
  },
  {
    isProtected: true,
    path: "/settings",
    Element: Settings,
    role: ["Superadmin", "admin"],
  },

  {
    isProtected: true,
    path: "/transaction",
    Element: Transaction,
    role: ["Superadmin"],
  },
  {
    isProtected: true,
    path: "/lives",
    Element: Lives,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/viewlive/:id/:channelId+",
    Element: ViewLive,
    role: ["Superadmin", "admin"],
  },
  {
    isProtected: true,
    path: "/viewmember/:id",
    Element: ViewMember,
    role: ["Superadmin", "admin"],
  },

  {
    isProtected: true,
    path: "/restricted-entry",
    Element: RedirectError,
    role: [],
  },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "*",
    Element: PageNotFound,
    role: [],
  },
];

export default appRoutes;
