import { useEffect, useState } from "react";
import $ from "jquery";
import { endpoints } from "../endpoints/endpoint";

export default function useUserDetails() {
  const [allUsersCollection, setAllUsersCollection] = useState([]);
  const [allCreatorsCollection, setAllCreatorsCollection] = useState([]);
  const [allMembersCollection, setAllMembersCollection] = useState([]);
  const [bannedCreatorsCollection, setBannedCreatorsCollection] = useState([]);
  const [bannedMembersCollection, setBannedMembersCollection] = useState([]);
  const [suspendedUsersCollection, setSuspendedUsersCollection] = useState([]);
  const [reportedUsersCollection, setReportedUsersCollection] = useState([]);

  useEffect(() => {
    listAllUsers();
    listAllBannedUsers();
    listAllSuspendedUsers();
    listAllReportedUsers();
  }, []);

  const listAllUsers = async (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    if (!obj || !obj.accessToken) {
      return;
    }

    setAllCreatorsCollection([]);
    setAllMembersCollection([]);

    await $.ajax({
      url: endpoints.allMembers,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (Array.isArray(data)) {
          setAllUsersCollection(data);
          filterCreatorsFromAllUsers(data);
          filterMembersFromAllUsers(data);
        }
      },
      complete: function () {},
    });
  };

  const filterCreatorsFromAllUsers = (data) => {
    const filteredArr = data.filter((user) => user.role == "Creator");
    setAllCreatorsCollection(filteredArr);
  };

  const filterMembersFromAllUsers = (data) => {
    const filteredArr = data.filter((user) => user.role == "User");
    setAllMembersCollection(filteredArr);
  };

  const listAllBannedUsers = async (event, values) => {
    setBannedCreatorsCollection([]);
    setBannedMembersCollection([]);
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    await $.ajax({
      url: endpoints.bannedMemberList,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data && data.length > 0) {
          filterCreatorsFromAllBannedUsers(data);
          filterMembersFromAllBannedUsers(data);
        }
      },
      complete: function () {},
    });
  };

  const filterCreatorsFromAllBannedUsers = (data) => {
    const filteredArr = data.filter((user) => user.role == "Creator");
    setBannedCreatorsCollection(filteredArr);
  };

  const filterMembersFromAllBannedUsers = (data) => {
    const filteredArr = data.filter((user) => user.role == "User");
    setBannedMembersCollection(filteredArr);
  };

  const listAllSuspendedUsers = async () => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    setSuspendedUsersCollection([]);
    await $.ajax({
      url: endpoints.suspendedMemberList,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data && data.length > 0) {
          setSuspendedUsersCollection(data);
        }
      },
      complete: function () {},
    });
  };

  const listAllReportedUsers = () => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    $.ajax({
      url: endpoints.reportedStreamers,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data && data.length > 0) {
          setReportedUsersCollection(data);
        }
      },
      complete: function () {},
    });
  };

  return {
    allUsersCollection,
    allCreatorsCollection,
    allMembersCollection,
    bannedCreatorsCollection,
    bannedMembersCollection,
    suspendedUsersCollection,
    reportedUsersCollection,
    listAllUsers,
    listAllBannedUsers,
    listAllSuspendedUsers,
    listAllReportedUsers,
    setAllUsersCollection,
  };
}
