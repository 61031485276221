import React, { useEffect, useState, useContext } from "react";
import useTransactions from "../../hooks/useTransactions";
import { AppContext } from "../../context/AppContext";
import { PuffLoader } from "react-spinners";
import "./Transaction.css";
import Utils from "../../utils/utils";

function SubscriptionTable(props) {
  const findMemberDetailsById = (id) => {
    return props.memberDetails.find((x) => x._id == id);
  };
  return (
    <>
      <table id="example2" class="table table-bordered table-hover text-center">
        <thead>
          <tr>
            <th>Member</th>
            <th>Creator</th>
            <th>Date</th>
            <th>Level</th>
            <th>Total(£)</th>
            {/* <th>Vat</th> */}
            {/* <th>Platform fee</th> */}
            <th>Admin Percentage</th>
            <th>Creator Percentage</th>
            {/* <th>Currency</th> */}
          </tr>
        </thead>
        <tbody>
          {props.memberDetails.length > 0 &&
            props.transactions.length > 0 &&
            !props.loading &&
            Array.isArray(props.subscriptionHistory) &&
            props.subscriptionHistory.map((item, index) => {
              // console.log("item", item);
              const user = findMemberDetailsById(item.userId);
              if (!user) {
                return null;
              }

              const creatorPercentage =
                100 - item.CRpercentage - item.platformPercentage;

              const adminPercentage =
                item.CRpercentage + item.platformPercentage;
              const adminFee = item.collectedfee + item.stripefee;
              // console.log(
              //   100 - item.CRpercentage - item.platformPercentage,
              //   "creatorPercentage"
              // );

              return (
                <tr>
                  <td>{user?.username ?? "Not Available"}</td>
                  <td>{user?.nickname ?? "Not Available"}</td>
                  <td>{item.paymentshortdate}</td>
                  <td>{item.level}</td>
                  <td>{Utils.formatDecimal(item.amount)}</td>
                  {/* <td>
                    {"£" + item?.vat ?? "0"}
                  </td> */}
                  {/* <td>
                    {"£" +
                      Utils.formatDecimal(item?.stripefee) +
                      `(${item.platformPercentage}%)` ?? "Not Available"}
                  </td> */}
                  <td>
                    {Utils.formatDecimal(adminFee) + ` (${adminPercentage}%)` ??
                      "Not Available"}
                  </td>
                  <td>
                    {Utils.formatDecimal(item?.net) +
                      ` (${creatorPercentage}%)` ?? "Not Available"}
                  </td>
                  {/* <td>{item.currency}</td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default SubscriptionTable;
