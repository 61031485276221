import React, { createContext, useState } from "react";
import io from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_API_BASE_URL;

const socket = io.connect(SOCKET_URL);

export const SocketContext = createContext();

export const SocketContextProvider = (props) => {
  return (
    <SocketContext.Provider
      value={{
        socket,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};
