import React, { useEffect, useState, useRef } from "react";
import "./Advertisement.css";
import * as Ai from "react-icons/ai";
import { TabContext, tabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { Container, Form, ProgressBar } from "react-bootstrap";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";

import "../../../node_modules/video-react/dist/video-react.css";
import useVideoAds from "../../hooks/useVideoAds";
import PreviewPlayer from "./PreviewPlayer";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { PuffLoader } from "react-spinners";
import { Button, Modal } from "antd";
import DeleteVideoModal from "./DeleteVideoModal";
import Utils from "../../utils/utils";

const VideoAds = () => {
  const fileRef = useRef(null);
  const [videoFile, setVideoFile] = useState(null);
  const [showPreTooltip, setShowPreTooltip] = useState(false);
  const [showMidTooltip, setShowMidTooltip] = useState(false);
  const [title, setTitle] = useState(null);
  const [bufferTime, setBufferTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [preroll, setpreroll] = useState(false);
  const [midroll, setmidroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [userId, setUserId] = useState(null);

  const {
    videoAds,
    getVideoAdsSettings,
    uploadAdvert,
    uploadProgress,
    actionLoading,
    updateSetting,
  } = useVideoAds();

  useEffect(() => {
    if (videoAds && Object.keys(videoAds).length > 0) {
      setDuration(videoAds.duration);
      setBufferTime(videoAds.bufferTime);
    }
  }, [videoAds]);

  const onSelectVideo = (file) => {
    setVideoFile(file);
  };

  const showTooltipPreVideo = () => {
    setShowPreTooltip(!showPreTooltip);
  };

  const showTooltipMidVideo = () => {
    setShowMidTooltip(!showMidTooltip);
  };

  const uploadAdsToInventory = () => {
    if (!title || title.length == 0) {
      //TODO: ADD TOAST
      return;
    }

    if (!videoFile || !bufferTime || !duration) {
      return;
    }

    if (preroll == false && midroll == false) {
      return;
    }
    const bodyFormData = new FormData();

    bodyFormData.append("file", videoFile);
    bodyFormData.append("name", title);
    bodyFormData.append("bufferTime", bufferTime);
    bodyFormData.append("duration", duration);
    bodyFormData.append("midroll", midroll);
    bodyFormData.append("preroll", preroll);

    uploadAdvert({
      payload: bodyFormData,
      onSuccess: () => handleClear(),
    });
  };

  const updateVideoAdsSetting = () => {
    if (!bufferTime || !duration) {
      debugger;
      return;
    }
    const payload = { bufferTime, duration };
    updateSetting(payload, { onSuccess: () => {}, onFailure: () => {} });
  };

  const handleClear = () => {
    fileRef.current.value = null;
    setTitle("");
    setVideoFile(null);
    setpreroll((prev) => !prev);
    setmidroll((prev) => !prev);
    setVideoFile(null);
  };

  const confirmDelete = async () => {
    if (!userId) {
      toast.error("User id is undefined, Please check!");
      return;
    }
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      videoadId: userId,
    };
    setLoading(true);
    await $.ajax({
      url: endpoints.deleteVideoAds,
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        toast.success("Video Deleted Successfully");
        setTimeout(() => {
          setOpenConfirm(false);
        }, 1000);
      },
      complete: () => {
        setUserId(null);
        setLoading(false);
        getVideoAdsSettings();
      },
    });
  };

  const showModal = () => {
    setOpenConfirm(true);
  };

  const handleCancel = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <Toaster position="top-center"></Toaster>
      {openConfirm && (
        <DeleteVideoModal
          openConfirm={openConfirm}
          confirmDelete={confirmDelete}
          loading={loading}
          handleCancel={handleCancel}
        />
      )}
      <Container>
        <div class="card-header">
          <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Video Advertisements
          </h3>
        </div>
        <br />
        <div class="row">
          <div class="col-12">
            <div class="card videoAdCard">
              <h4>Manage Video Ads Inventory</h4>
              <div
                class="d-flex"
                style={{ marginTop: "30px", marginBottom: "10px" }}
              >
                <div class="form-group">
                  <div class="d-flex" style={{ position: "relative" }}>
                    <label for="buffer time">Buffer Time</label>
                    <div
                      class="info_"
                      onClick={showTooltipPreVideo}
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      placeholder="Show Buffer Duration In Seconds"
                    >
                      <Ai.AiFillInfoCircle />
                    </div>
                    {showPreTooltip ? (
                      <div class="tooltip_">
                        Delay duration for displaying the midroll ads after
                        preroll (In Seconds)
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    value={bufferTime}
                    onChange={(e) => {
                      return setBufferTime(e.target.value);
                    }}
                    style={{ width: "100%", padding: "5px" }}
                  />
                </div>
                <div class="form-group" style={{ marginLeft: "10px" }}>
                  <div class="d-flex" style={{ position: "relative" }}>
                    <label for="buffer time">Duration</label>
                    <div
                      class="info_"
                      onClick={showTooltipMidVideo}
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      placeholder="Show Video Duration In Seconds"
                    >
                      <Ai.AiFillInfoCircle />
                    </div>
                    {showMidTooltip ? (
                      <div class="tooltip_">
                        Duration for displaying the midroll ads (In Seconds)
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <input
                    value={duration}
                    onChange={(e) => {
                      return setDuration(e.target.value);
                    }}
                    style={{ width: "100%", padding: "5px" }}
                  />
                </div>
              </div>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Ad-Video</Form.Label>
                <Form.Control
                  type="file"
                  accept="video/mp4"
                  onChange={(e) => onSelectVideo(e.target.files[0])}
                  ref={fileRef}
                />
              </Form.Group>
              <div class="preview-player">
                <PreviewPlayer file={videoFile} />
              </div>
              <br />

              <div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label for="">Title</label>
                    <input
                      type="text"
                      class="form-control"
                      style={{
                        border: "1px solid #4f4f4f",
                        borderRadius: "4px",
                      }}
                      placeholder="Enter Advert Title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <br />
                <div class="alert alert-light" role="alert">
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      Pre Role: <br />
                    </span>{" "}
                    Preroll ads are a type of video advertisement that play
                    before the main content
                  </p>
                </div>
                <div class="alert alert-light" role="alert">
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      Mid Role: <br />
                    </span>{" "}
                    Midroll ads are video advertisements that play in the middle
                    of a longer piece of content.
                  </p>
                </div>
                <label for="" style={{ marginLeft: "10px" }}>
                  Type (required)
                </label>
                <div class="d-flex ">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      name=""
                      id="preRoll"
                      value={preroll}
                      onChange={(e) => {
                        setpreroll(e.target.checked);
                      }}
                      checked={preroll}
                      style={{ marginRight: "10px" }}
                    />
                    <label for="preRoll">Pre Role</label>
                  </div>

                  <div class="form-check">
                    <input
                      type="checkbox"
                      name=""
                      id="midRoll"
                      value={midroll}
                      onChange={(e) => {
                        setmidroll(e.target.checked);
                      }}
                      checked={midroll}
                      style={{ marginRight: "10px" }}
                    />
                    <label for="midRoll">Mid Role</label>
                  </div>
                </div>
                {videoFile ? (
                  <div class="col-4">
                    <button
                      class="btn upload_btn"
                      style={{
                        width: "50%",
                        marginTop: "30px",
                      }}
                      disabled={uploadProgress != 0}
                      onClick={() => uploadAdsToInventory()}
                    >
                      {uploadProgress < 1 ? (
                        "Upload & Save"
                      ) : (
                        <Spinner animation="border" />
                      )}
                    </button>
                  </div>
                ) : (
                  <div class="col-4">
                    <button
                      class="btn upload_btn"
                      style={{
                        width: "50%",
                        marginTop: "30px",
                      }}
                      disabled={actionLoading == true}
                      onClick={() => updateVideoAdsSetting()}
                    >
                      {actionLoading == false ? (
                        "Save"
                      ) : (
                        <Spinner animation="border" size="sm" />
                      )}
                    </button>
                  </div>
                )}
              </div>
              <br />
              {uploadProgress != 0 && (
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <ProgressBar
                    now={uploadProgress}
                    label={`Uploading ${uploadProgress}%`}
                  />
                </div>
              )}
              {uploadProgress == 100 && (
                <p class="text-success" style={{ margin: "10px" }}>
                  Video Uploaded Successfully !
                </p>
              )}
            </div>
          </div>
        </div>
        <br />
        <h2>Video Ad List</h2>
        <div class="row">
          <div class="card videoAdCard">
            <table class="table table-stripped videoAdTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>filename</th>
                  <th>Position</th>
                  <th>video-format</th>
                  <th>uploaded date</th>
                  <th>view</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(videoAds.videoAds) &&
                  videoAds.videoAds.length > 0 &&
                  videoAds.videoAds.map((advert, index) => {
                    const videoFormat =
                      advert?.url != null && advert.url.split(".")[1];
                    console.log(advert.uploadedDate);
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{advert?.name ?? "N/A"}</td>
                        <td>{advert?.url ?? "N/A"}</td>
                        <td>{advert?.type ?? "N/A"}</td>
                        <td>{videoFormat ?? "N/A"}</td>
                        <td style={{ width: "100%", whiteSpace: "nowrap" }}>
                          {advert.uploadedDate != null
                            ? Utils.getDate(advert.uploadedDate)
                            : "N/A"}
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              showModal();
                              setUserId(advert._id);
                            }}
                          >
                            <Ai.AiFillDelete />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </>
  );
};

export default VideoAds;
