import { React, useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import useTransactions from "../../hooks/useTransactions";
import { AppContext } from "../../context/AppContext";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import * as Md from "react-icons/md";
import * as Bs from "react-icons/bs";
import "./Transaction.css";
import "../Settings/Settings.css";
import { PuffLoader } from "react-spinners";
import GiftingTable from "../Settings/GiftingTable";
import CreditTransactionTable from "./CreditTransactionTable";
import SubscriptionTable from "./SubscriptionTable";

function Transaction() {
  const { loading, transactions, subscriptionHistory, creditsHistory } =
    useTransactions();
  const [tabValue, setTabValue] = useState("1");

  const { memberDetails } = useContext(AppContext);

  const findMemberDetailsById = (id) => {
    return memberDetails.find((x) => x._id == id);
  };

  const tabChange = (eve, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Sidebar currentPage="transaction" />
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        <div class="card transaction_main" style={{ padding: "20px" }}>
          <h1 style={{ fontSize: "30px" }}>Transactions</h1>
          <br />
          <TabContext value={tabValue}>
            <Box>
              <TabList
                aria-label="tab example"
                onChange={tabChange}
                TabIndicatorProps={{
                  sx: {
                    background: "transparent",
                  },
                }}
              >
                <Tab
                  label="Subscriptions"
                  value="1"
                  className="tabss"
                  icon={<Md.MdSubscriptions />}
                  iconPosition="start"
                />
                <Tab
                  label="Gifts to Creators"
                  value="2"
                  className="tabss"
                  icon={<Bs.BsGiftFill />}
                  iconPosition="start"
                />
                <Tab
                  label="Credits Purchased"
                  value="3"
                  className="tabss"
                  icon={<Bs.BsFillCreditCard2BackFill />}
                  iconPosition="start"
                />
              </TabList>
              <TabPanel value="1">
                <div class="card card_transaction">
                  <SubscriptionTable
                    transactions={transactions}
                    loading={loading}
                    subscriptionHistory={subscriptionHistory}
                    memberDetails={memberDetails}
                  />
                </div>
                {/* <div class="card info_card p-2" style={{width:"50%"}}>
                  <label for="">Information</label>
                  <p><b>Platform Fee:</b> Payment gateway charge</p>
                  <p>
                    <b>Creator Percentage:</b> The creator percentage refers to the
                    amount that will be transferred to the creator, and should
                    be excluded from the total amount along with the admin
                    percentage.{" "}
                  </p>
                  <p>
                    <b>Admin Percentage:</b> Amount which you'll get from the creator
                  </p>
                </div> */}
              </TabPanel>
              <TabPanel value="2">
                <div class="card card_transaction">
                  <GiftingTable />
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div class="card card_transaction">
                  <CreditTransactionTable
                    transactions={transactions}
                    loading={loading}
                    creditsHistory={creditsHistory}
                    memberDetails={memberDetails}
                  />
                </div>
              </TabPanel>
            </Box>
          </TabContext>
        </div>
      </div>
    </>
  );
}

export default Transaction;
