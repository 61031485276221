import React, { useState } from "react";
import { endpoints } from "../endpoints/endpoint";
import axios from "axios";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export default function useChat() {
  const [connection, setConnection] = useState(null);
  const [chatToken, setChatToken] = useState(null);
  const [chats, setChats] = useState([]);
  const [newChat, setNewChat] = useState("");
  const [userId, setUserId] = useState(null);

  //Create Chat Token
  const requestToken = (chatData) => {
    //Handle null
    if (chatData == null) return;

    const data = {
      roomIdentifier: chatData?.roomId,
      userId: chatData?.userId,
    };

    if (chatData?.userId != null) {
      setUserId({ userId: chatData?.userId });
    }

    axios
      .post(endpoints.createChatToken, data)
      .then((response) => {
        if (response.data?.token != null) {
          setChatToken(response.data?.token);
          initConnection(response.data?.token);
        }
      
      })
      .catch((error) => {
        setChatToken(null);
        console.error("Error:", error);
      });
  };

  const initConnection = async (token, chatData) => {
    const connectionInit = new WebSocket(
      "wss://edge.ivschat.ap-south-1.amazonaws.com",
      token
    );
    setConnection(connectionInit);
    connectionInit.onopen = (event) => {
      console.info("Connected to the chat room.");
      // renderConnect();
    };

    connectionInit.onclose = (event) => {
      // If the websocket closes, remove the current chat token
      setChatToken(null);
      // renderDisconnect(event.reason);
    };

    connectionInit.onerror = (event) => {
      console.error("Chat room websocket error observed:", event);
    };

    connectionInit.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const eventType = data["Type"];

      switch (eventType) {
        case "EVENT":
          console.info("Received event:", data);
          // handleEvent(data);
          break;
        case "ERROR":
          console.info("Received error:", data);
          setConnection(null);
          if (data.ErrorCode && data.ErrorCode === 401) {
            requestToken(chatData);
          }
          // handleError(data);
          break;
        case "MESSAGE":
          handleMessage(data);
          break;
        default:
          console.error("Unknown message received:", event);
      }
    };
  };

  const handleMessage = (data) => {
    const attributes = data["Attributes"];
    const userId = attributes["id"];
    const image = attributes["image"];
    const name = attributes["name"];
    const msg = data["Content"];
    const emoji = attributes["emoji"];
    const isEmoji = emoji != null;
    const role = attributes["role"];

    let chatMsg = {
      attributes,
      userId,
      image,
      name,
      role,
      chat: msg,
      isEmoji,
    };

    setChats((prevState) => [...prevState, chatMsg]);
  };

  const sendMessage = () => {
    if (!chatToken || !connection || !userId) {
      return;
    }
    if (newChat.trim().length > 0) {
      const uuid = uuidv4();
      const data = `{
              "requestId": "${uuid}",
              "action": "SEND_MESSAGE",
              "Attributes": {
                "name": "CR Admin👑",
                "userId": "${userId}",
                "image": "Admin.jpg",
                "role": "admin"
              },
              "content": "${newChat
                .replace(/\\/g, "\\\\")
                .replace(/"/g, '\\"')}"
             
            }`;
      connection.send(data);
    }
    setNewChat("");
  };

  return { requestToken, sendMessage, newChat, setNewChat, chats };
}
