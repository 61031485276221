import React from "react";
import { Row, Col } from "react-bootstrap";

import "./RedirectError.css";
import CustomErrors from "../constants/Errors";
import {useHistory } from "react-router-dom";

function RedirectError() {
const history= useHistory
  return (
    <div>
      <div className="container red-con">
        <Row className="red-row">
          <Col>
            <div className="mx-auto text-center">
              <h6 className="red-msg">Cant Redirect to this Page</h6>

              <p className="msg-para">{CustomErrors.UNAUTHORIZED_ACCESS}</p>

              <button
                role="button"
                className="redirect mx-auto"
                onClick={() =>history.push("/")}
              >
                <span class="text">Go to Home</span>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default RedirectError;
