import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useHistory } from "react-router-dom";

export const ProtectRoute = ({ children }) => {
  const history = useHistory();
  const { getLoginResponseFromLocal } = useLocalStorage();
  const storageValue = getLoginResponseFromLocal();

  console.log("children", children);

  const loggedUserRole = storageValue?.role;

  const { role } = children.props;

  if (storageValue == null) {
    return history.push("/");
  }

  if (!Array.isArray(role) || role == null || role.length == 0) {
    return children;
  }

  if (role.includes(loggedUserRole)) {
    return children;
  }

  return history.push("/restricted-entry");
};

export const ProtectAuthRoute = ({ children }) => {
  const { checkValueIsExist } = useLocalStorage();
  const isAuthenticated = checkValueIsExist();
  const history = useHistory();

  return !isAuthenticated ? children : history.push("/");
};
