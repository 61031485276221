import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import usePayouts, { status } from "../../hooks/usePayouts";
import Utils from "../../utils/utils";
import "./Payouts.css";
import * as Bs from "react-icons/bs";
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { PuffLoader } from "react-spinners";
import { Row } from "react-bootstrap";

function Payouts() {
  const [rowId, setRowId] = useState(-1);
  const [isActive, setIsActive] = useState("ALL");

  //Custom Hook for payouts
  const {
    viewDate,
    setViewDate,
    getAllPayouts,
    filterPayouts,
    payouts,
    modifyPaymentStatus,
    loading,
    actionLoading,
  } = usePayouts();

  const toggleTable = (row) => {
    return setRowId((prev) => (prev != row ? row : -1)); //table toggle
  };
  const handleDateChange = (e) => {
    setViewDate(e.target.value);
  };

  const toggleActive = (filter) => {
    setIsActive(filter);
    filterPayouts(filter);
  };

  const handleBtnClick = (id, status) => {
    if (!id || !status) return;
    modifyPaymentStatus(
      id,
      status,
      (res) => {
        toast.success(res.message);
      },
      (err) => {
        toast(err.message, {
          icon: <Md.MdError />,
          style: {
            color: "#F20000",
          },
        });
      }
    );
  };

  // const getActionButton = (data) => {
  //   switch (data.status.toUpperCase()) {
  //     case status.PAYMENT_PENDING:
  //       return (
  //         <>
  //           <button
  //             className="success"
  //             title="Approve"
  //             onClick={() => handleBtnClick(data._id, status.PAYMENT_APPROVED)}
  //           >
  //             <i class="fa fa-check" aria-hidden="true"></i>
  //           </button>
  //           <button
  //             className="cancel"
  //             title="Cancel"
  //             onClick={() => handleBtnClick(data._id, status.PAYMENT_CANCELLED)}
  //           >
  //             <i class="fa fa-times" aria-hidden="true"></i>
  //           </button>
  //         </>
  //       );

  //     case status.PAYMENT_APPROVED:
  //       return (
  //         <>
  //           <button
  //             className="info"
  //             title="Pay"
  //             onClick={() => handleBtnClick(data._id, status.PAYMENT_COMPLETED)}
  //           >
  //             <i class="fa fa-paper-plane" aria-hidden="true"></i>
  //           </button>
  //         </>
  //       );
  //     case status.PAYMENT_CANCELLED:
  //       return (
  //         <>
  //           <button className="info" title="Cancelled">
  //             Cancelled
  //           </button>
  //         </>
  //       );
  //     case status.PAYMENT_COMPLETED:
  //       return (
  //         <>
  //           <button className="info" title="Cancelled">
  //             Completed
  //           </button>
  //         </>
  //       );
  //     case status.PAYMENT_RETURNED:
  //       return (
  //         <>
  //           <button
  //             className="info"
  //             title="Pay"
  //             onClick={() => handleBtnClick(data._id, status.PAYMENT_COMPLETED)}
  //           >
  //             <i class="fa fa-repeat" aria-hidden="true"></i>
  //           </button>
  //         </>
  //       );
  //   }
  // };

  return (
    <>
      {actionLoading || loading ? (
        <div class="page_loading">
          <div class="loader">
            <PuffLoader color="#eb100c" loading size={70} />
          </div>
        </div>
      ) : (
        ""
      )}
      <Sidebar currentPage="payouts" />
      <div class="content-wrapper">
        <div className="col-xl-12">
          <div class="card" style={{ padding: "20px" }}>
            <div>
              <h1>Payouts</h1>
              <Toaster
                position="bottom-center"
                reverseOrder={false}
              ></Toaster>{" "}
              <div class="filter1">
                <Row>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <input
                      type="month"
                      class="date"
                      id="date"
                      value={viewDate}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="filter_grp">
                      <button
                        className={
                          isActive === "ALL"
                            ? "filter_btn active all"
                            : "filter_btn all"
                        }
                        onClick={() => toggleActive("ALL")}
                      >
                        All
                      </button>
                      <button
                        className={
                          isActive === status.PAYMENT_PENDING
                            ? "filter_btn active pending"
                            : "filter_btn pending"
                        }
                        onClick={() => toggleActive(status.PAYMENT_PENDING)}
                      >
                        Pending
                      </button>
                      <button
                        className={
                          isActive === status.PAYMENT_APPROVED
                            ? "filter_btn active approve"
                            : "filter_btn approve"
                        }
                        onClick={() => toggleActive(status.PAYMENT_APPROVED)}
                      >
                        Approved
                      </button>

                      <button
                        className={
                          isActive === status.PAYMENT_COMPLETED
                            ? "filter_btn active canceled"
                            : "filter_btn canceled"
                        }
                        onClick={() => toggleActive(status.PAYMENT_COMPLETED)}
                      >
                        Completed
                      </button>
                      <button
                        className={
                          isActive === status.PAYMENT_CANCELLED
                            ? "filter_btn active canceled"
                            : "filter_btn canceled"
                        }
                        onClick={() => toggleActive(status.PAYMENT_CANCELLED)}
                      >
                        Cancelled
                      </button>
                    </div>
                  </div>
                </Row>
              </div>
              <div style={{ margin: "10px" }}>
                <div class="payout_cont">
                  <table className="table">
                    <thead className="main_thead">
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>No of Subscribers</th>
                        <th>Total</th>
                        <th>Commission</th>
                        <th>Platform Fee</th>
                        <th>Payout Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payouts.map((data, index) => (
                        <>
                          <tr
                            onClick={() => toggleTable(index)}
                            className="main_data"
                          >
                            <td>{data.invoicenumber}</td>
                            <td>{Utils.titleCase(data.Name)}</td>
                            <td>{data.invoice.length}</td>
                            <td>{Utils.formatDecimal(data.Totalamount)}</td>
                            <td>
                              {Utils.formatDecimal(data.Totalcollectedfee)}
                            </td>
                            <td>{Utils.formatDecimal(data.Totalstripefee)}</td>
                            <td>{Utils.formatDecimal(data.netsum)}</td>
                            <td>
                              <div class="d-flex btn_grp">
                                {data.payment.toUpperCase() ==
                                status.PAYMENT_PENDING ? (
                                  <>
                                    <button
                                      className="success"
                                      title="Approve"
                                      onClick={() =>
                                        handleBtnClick(
                                          data._id,
                                          status.PAYMENT_APPROVED
                                        )
                                      }
                                    >
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      className="cancel"
                                      title="Cancel"
                                      onClick={() =>
                                        handleBtnClick(
                                          data._id,
                                          status.PAYMENT_CANCELLED
                                        )
                                      }
                                    >
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </>
                                ) : data.payment.toUpperCase() ==
                                  status.PAYMENT_APPROVED ? (
                                  <>
                                    <button
                                      className="info"
                                      title="Pay"
                                      onClick={() =>
                                        handleBtnClick(
                                          data._id,
                                          status.PAYMENT_COMPLETED
                                        )
                                      }
                                    >
                                      <i>
                                        <Fa.FaPoundSign />
                                      </i>
                                    </button>
                                  </>
                                ) : data.payment.toUpperCase() ==
                                  status.PAYMENT_COMPLETED ? (
                                  <>
                                    <button
                                      className="success"
                                      style={{ cursor: "default" }}
                                    >
                                      <i>
                                        <Md.MdOutlineDoneAll />
                                      </i>
                                    </button>
                                  </>
                                ) : data.payment.toUpperCase() ==
                                  status.PAYMENT_RETURNED ? (
                                  <>
                                    <button
                                      className="warm"
                                      title="Retry"
                                      onClick={() =>
                                        handleBtnClick(
                                          data._id,
                                          status.PAYMENT_COMPLETED
                                        )
                                      }
                                    >
                                      <i>
                                        <Bs.BsArrowRepeat />
                                      </i>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button className="cancel" disabled>
                                      Cancelled
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr
                            id="row1"
                            className={
                              rowId != index ? "row_value hide" : "row_value"
                            }
                          >
                            <td colspan="8">
                              <table class="table sub_table">
                                <thead style={{ textAlign: "center" }}>
                                  <th>Sl.No</th>
                                  <th>Currency</th>
                                  <th>Total</th>
                                  <th>Platform Fee</th>
                                  <th>Amount</th>
                                  <th>Collected Fee</th>
                                  <th>VAT</th>
                                  <th>Payout</th>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                  {data.invoice.map((x, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        {(x?.currency ?? "-").toUpperCase()}
                                      </td>
                                      <td>{Utils.formatDecimal(x.total)}</td>
                                      <td>
                                        {Utils.formatDecimal(x.stripefee)}
                                      </td>
                                      <td>{Utils.formatDecimal(x.amount)}</td>
                                      <td>
                                        {Utils.formatDecimal(x.collectedfee)}
                                      </td>
                                      <td>{Utils.formatDecimal(x.vat)}</td>
                                      <td>{Utils.formatDecimal(x.net)}</td>
                                      {/* <td>
                                        {Utils.getTokenConvertion(
                                          x.tokenvalue,
                                          x.net
                                        )}
                                      </td>
                                      <td>{x.tokenvalue}</td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </>
                      ))}
                      {/* values End */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payouts;
