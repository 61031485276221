import { registerIVSQualityPlugin, registerIVSTech } from "amazon-ivs-player";
import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "./player.css";
import "video.js/dist/video-js.css";

const Player = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [playerInstance, setPlayerInstance] = useState(null);

  let options = {
    techOrder: ["AmazonIVS"],
    responsive: true,
    fluid: true,
    autoplay: true,
    sources: [
      {
        src: props?.playbackUrl ?? "",
        type: "application/x-mpegURL",
      },
    ],
  };
  const wasmWorkerPath = `${process.env.REACT_APP_AWS_RESOURCE_PATH_VIDEOJS}amazon-ivs-wasmworker.min.js`;
  const wasmBinaryPath = `${process.env.REACT_APP_AWS_RESOURCE_PATH_VIDEOJS}amazon-ivs-wasmworker.min.wasm`;

  if (playerInstance) {
    playerInstance.bigPlayButton.on("click", function () {
      playerInstance.src(options.sources);
    });
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://player.live-video.net/1.12.0/amazon-ivs-videojs-tech.min.js";
    script.id = "amazon-ivs";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      //const _IVSPlayer = window.IVSPlayer;
      console.dir(videoElement);
      if (!playerRef.current) {
        // register the tech with videojs
        registerIVSTech(videojs, {
          wasmWorker: wasmWorkerPath,
          wasmBinary: wasmBinaryPath,
        });

        // register the quality plugin
        registerIVSQualityPlugin(videojs);
        // eslint-disable-next-line no-undef
        const player = (playerRef.current = videojs(
          videoElement,
          options,
          () => {
            console.log("Player is ready to use!");
            setPlayerInstance(player);
            // player.src(PLAYBACK_URL);
            // player.autoplay(true);
            player.muted(false);
            // player.src(options.sources);
            // enable the quality plugin
            player.enableIVSQualityPlugin();
          }
        ));
      } else {
        // update an existing player.
        const player = playerRef.current;
        player.src(options.sources);
      }
    };
  }, [videoRef]);

  return (
    <>
      <div style={{ margin: "0 auto" }}>
        <div className="center">
          <div style={{ textAlign: "center" }}>
            <video
              ref={videoRef}
              controls
              playsInline
              poster={props?.thumbnail != null && process.env.REACT_APP_AWS_RESOURCE_PATH_IMAGES + props?.thumbnail}
              className="video-js vjs-default-skin vjs-big-play-centered"
            ></video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Player;
