import { createContext, useState, useEffect } from "react";
import useLives from "../hooks/useLives";
import { endpoints } from "../endpoints/endpoint";
import $ from "jquery";
import useMemeber from "../hooks/useMember";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const { liveVideos, loading } = useLives();
  const [userDetailsCollection, setUserDetailsCollection] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  const [membersLoading, setMembersLoading] = useState(false);


  useEffect(() => {
    listAllMembers();
  }, []);

  const listAllMembers = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    if (!obj || !obj.accessToken) {
      return;
    }

    setMembersLoading(true);
    $.ajax({
      url: endpoints.allMembers,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (Array.isArray(data)) {
          setMemberDetails(data);
        }
      },
      complete: function () {
        setMembersLoading(false);
      },
    });
  };

  return (
    <AppContext.Provider
      value={{
        loading,
        liveVideos,
        membersLoading,
        setMemberDetails,
        memberDetails,
        userDetailsCollection,
        setUserDetailsCollection
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
