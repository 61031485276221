import React from "react";
import Modal from "antd/es/modal/Modal";

export default function DeleteVideoModal(props) {
  return (
    <Modal
      open={props.openConfirm}
      onOk={props.confirmDelete}
      confirmLoading={props.loading}
      onCancel={props.handleCancel}
    >
      <h4>Are You Sure?</h4>
      <span>This action will delete your video from inventory.</span>
    </Modal>
  );
}
