import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { Row, Col, Container } from "react-bootstrap";
import "./Members.css";
import { Modal, Button } from "antd";
import Lottie from "lottie-react";
import animatedGift from "../../Lottie/gift.json";
import Tab from "react-bootstrap/Tab";
import $ from "jquery";
import Tabs from "react-bootstrap/Tabs";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../context/AppContext";
import ReactPlayer from "react-player";
import Spinner from "react-bootstrap/Spinner";
import Utils from "../../utils/utils";
import Pagination from "./Pagination";
import { tr } from "date-fns/locale";
import useUserDetails from "../../hooks/useUserDetails";
import { endpoints } from "../../endpoints/endpoint";
import { type } from "@testing-library/user-event/dist/type";
import toast, { Toaster } from "react-hot-toast";
import * as Ai from "react-icons/ai";
import useMemeber from "../../hooks/useMember";
import VideoPlayer from "../../Component/player/VideoPlayer";
import { SocketContext } from "../../context/SocketContext";
import Arn from "aws-arn";

function ViewMember() {
  const params = useParams();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [showAction, setShowAction] = useState(true);

  const { member, loading, userDetailsLoading, deletePhotoById } = useMemeber({
    userId: params?.id,
  });

  // useEffect(() => {
  //   console.log("member data", member);
  // }, [member]);


  const redirectToLive = ({ arn }) => {
    debugger;
    const id = params.id;
    const parsedArn = Arn.parse(arn);
    if (!arn) return;
    history.push(`/viewlive/${id}/${parsedArn?.resourcePart}`);
  };

  return (
    <>
      <div>
        <Sidebar currentPage="members" />
      </div>
      <div className="content-wrapper">
        <main className="p-4">
          <h2 className="title pt-3">Members Details</h2>
          {member && member != null && userDetailsLoading == false && (
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 mb-2 detailed-card">
                <div className="card dash_card game-card">
                  <h6 className="card-head">Profile</h6>
                  <div className="row row-block">
                    <div
                      className="col-lg-5 col-md-6 col-sm-6 card-pro"
                      style={{ position: "relative" }}
                    >
                      <div className="profile-image">
                        {member.profilepic != null ? (
                          <img
                            src={
                              process.env.REACT_APP_AWS_RESOURCE_PATH_IMAGES +
                              member.profilepic
                            }
                            alt="Profile Photo"
                          />
                        ) : (
                          <img
                            src={require("../../image/img-placeholder.jpg")}
                            alt="Profile Photo"
                          />
                        )}
                      </div>
                      {member?.stream?.status && (
                        <div
                          class="live-dot notify-live"
                          onClick={() => redirectToLive(member)}
                        >
                          Live
                        </div>
                      )}
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-5 mb-2 details-area">
                      <div className="user-mgt">
                        <h2 className="member_name">
                          {member.firstname != null && member.lastname
                            ? member.firstname + " " + member.lastname
                            : "N/A"}
                        </h2>
                        <p className="member_nick">
                          {member.nickname != null
                            ? `@${member.nickname}`
                            : "user"}
                        </p>

                        <p className="user-details">
                          {member.followers &&
                            member.followers != null &&
                            member.followers > 0 ? (
                            <>
                              {member.followers.length}
                              <span className="span-user ml-3">Followers</span>
                            </>
                          ) : (
                            <>
                              0<span className="span-user ml-3">Followers</span>
                            </>
                          )}
                        </p>
                        <p className="user-details">
                          {member.following &&
                            member.following != null &&
                            member.following.length > 0 ? (
                            <>
                              {member.following.length}
                              <span className="span-user ml-3">Following</span>
                            </>
                          ) : (
                            <>
                              0<span className="span-user ml-3">Following</span>
                            </>
                          )}
                        </p>
                        <p className="user-details">
                          {member.subscribers &&
                            member.subscribers != null &&
                            member.subscribers.length > 0 ? (
                            <>
                              {member.subscribers.length}
                              <span className="span-user ml-3">
                                Subscribers
                              </span>
                            </>
                          ) : (
                            <>
                              0
                              <span className="span-user ml-3">
                                Subscribers
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="cate d-flex mb-3">
                    {member.categories != null &&
                      member.categories.map((category) => {
                        return <div className="cate-list">#{category}</div>;
                      })}
                    {member.categories == null && (
                      <p style={{ fontSize: "12px", color: "#747474" }}>
                        No Categories Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-5 mb-2">
                <div className="card subscription">
                  <h6 className="card-head">Subscription Plan</h6>
                  {member.subscritpionPlan !== undefined &&
                    member.subscritpionPlan !== null ? (
                    <Row>
                      <h5 className="cur-sub mt-3">
                        Current Subscription Details
                      </h5>
                      <h5 className="planned-sub cur-sub mt-2">
                        {member.subscritpionPlan.planTitle != null
                          ? member.subscritpionPlan.planTitle
                          : " NOT AVAILABLE"}
                      </h5>
                      {member.subscritpionPlan.GOLD != null ? (
                        <Col className="card-sub break-here">
                          <div className="content-sub">GOLD</div>
                          <p className="content">
                            {member.subscritpionPlan.GOLD.amount != null
                              ? `£${member.subscritpionPlan.GOLD.amount}`
                              : "NOT AVAILABLE"}
                            /{" "}
                            {member.subscritpionPlan.GOLD.duration != null
                              ? member.subscritpionPlan.GOLD.duration
                              : "NOT AVAILABLE"}
                          </p>
                          <p className="para">
                            {member.subscritpionPlan.GOLD.benefits != null
                              ? member.subscritpionPlan.GOLD.benefits
                              : "NOT AVAILABLE"}
                          </p>
                        </Col>
                      ) : (
                        <h2>PLAN IS NOT AVAILABLE</h2>
                      )}
                      {member.subscritpionPlan.SILVER != null ? (
                        <Col className="card-sub break-here">
                          <div className="content-sub">SILVER</div>
                          <p className="content">
                            {member.subscritpionPlan.SILVER.amount != null
                              ? `£${member.subscritpionPlan.SILVER.amount}`
                              : "NOT AVAILABLE"}
                            /{" "}
                            {member.subscritpionPlan.SILVER.duration != null
                              ? member.subscritpionPlan.SILVER.duration
                              : "NOT AVAILABLE"}
                          </p>
                          <p className="para">
                            {member.subscritpionPlan.SILVER.benefits != null
                              ? member.subscritpionPlan.SILVER.benefits
                              : "NOT AVAILABLE"}
                          </p>
                        </Col>
                      ) : (
                        "PLAN IS NOT AVAILABLE"
                      )}
                      {member.subscritpionPlan.BRONZE != null ? (
                        <Col className="card-sub">
                          <div className="content-sub">BRONZE</div>
                          <p className="content">
                            {member.subscritpionPlan.BRONZE.amount != null
                              ? `£${member.subscritpionPlan.BRONZE.amount}`
                              : "NOT AVAILABLE"}
                            /{" "}
                            {member.subscritpionPlan.BRONZE.duration != null
                              ? member.subscritpionPlan.BRONZE.duration
                              : "NOT AVAILABLE"}
                          </p>
                          <p className="para">
                            {member.subscritpionPlan.BRONZE.benefits != null
                              ? member.subscritpionPlan.BRONZE.benefits
                              : "NOT AVAILABLE"}
                          </p>
                        </Col>
                      ) : (
                        "PLAN IS NOT AVAILABLE"
                      )}
                    </Row>
                  ) : (
                    <h2 className="error_msg">PLAN IS NOT AVAILABLE</h2>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-3">
                <div className="card dash_card gift">
                  <h6 className="card-head">Gift Credits</h6>
                  <div className="fift-an">
                    <Lottie
                      className="gift-an"
                      animationData={animatedGift}
                      loop={true}
                    ></Lottie>
                    <div className="gift-pack text-center mx-auto">
                      <h4 className="credit">Credits</h4>
                      {member.credit ? (
                        <h2 className="point">{member.credit}</h2>
                      ) : (
                        <h2 className="error_msg">NO CREDITS</h2>
                      )}
                      {/* <button className="but-btn">Buy</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {userDetailsLoading && (
            <div className="full_page">
              <div className="loader">
                <Spinner animation="border" variant="danger" />
              </div>
            </div>
          )}
          <div className="container card">
            <Row>
              <Tabs
                defaultActiveKey="All"
                id="justify-tab-example"
                className="mb-3 mt-3"
                justify
              >
                <Tab eventKey="All" title="About">
                  <h3 className="text-center">Member Brief</h3>
                  <table className="table member-tab">
                    <tr>
                      <td>Full Name</td>
                      <td>{member?.firstname + " " + member?.lastname}</td>
                    </tr>
                    <tr>
                      <td>User Name</td>
                      <td>{member?.username ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Nick Name</td>
                      <td>{member?.nickname ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{member?.role ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{member?.mail ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{member?.mobile ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Registered Date</td>
                      <td>
                        {member.registration_date != null
                          ? Utils.getDate(member.registration_date)
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>Registration Status</td>
                      <td>{member?.registrationStatus ?? "N/A"}</td>
                    </tr>
                  </table>
                  <br />
                  <div className="container card">
                    <h3 className="mt-3 text-center">Streamer's Actions</h3>
                    <Tabs
                      defaultActiveKey="report"
                      id="justify-tab-example"
                      className="mb-3"
                      justify
                    >
                      <Tab
                        eventKey="report"
                        title="Reports"
                        className="game-card"
                      >
                        <table class=" table repo-tab card-det">
                          <thead>
                            <tr>
                              <th scope="col">Sl.no</th>
                              <th scope="col">Stream Date</th>
                              <th scope="col">Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            {member?.streamreport && (
                              <Pagination data={member.streamreport} />
                            )}
                          </tbody>
                        </table>
                        {/* <table className="table">
                      <thead class="thead-dark">
                          <tr>
                            <td>S.No</td>
                            <td>Stream Date</td>
                            <td>Title</td>
                          </tr>
                        </thead>
                        <tbody>
                     
                        </tbody>
                      </table> */}
                      </Tab>
                      <Tab eventKey="ban" title="Ban" className="game-card">
                        <table class="table repo-tab card-det">
                          <thead>
                            <th>sl no</th>
                            <th>Admin name</th>
                            <th>Banned by</th>
                            <th>Reason</th>
                            <th>Role</th>
                          </thead>
                          <tbody>
                            {member.banHistory &&
                              member.banHistory.map((ban, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{ban.adminName}</td>
                                    <td>
                                      {ban?.role}
                                    </td>
                                    <td>{ban.reason}</td>
                                    <td>{ban.role}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </Tab>
                      <Tab
                        eventKey="suspend"
                        title="Suspend"
                        className="game-card"
                      >
                        <table class="table  repo-tab">
                          <thead>
                            <th>sl no</th>
                            <th>Suspendedby</th>
                            <th>Reason</th>
                            <th>Date</th>
                          </thead>
                          <tbody>
                            {member.suspensionHistory &&
                              member.suspensionHistory.map((suspend, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {suspend?.role}
                                    </td>
                                    <td>
                                      {" "}
                                      {suspend.reason != null
                                        ? suspend.reason
                                        : "Not Available"}
                                    </td>
                                    <td>
                                      {suspend.suspendeddate_str != null
                                        ? Utils.getDate(
                                          suspend.suspendeddate_str
                                        )
                                        : "Not Available"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </Tab>
                    </Tabs>
                  </div>
                </Tab>
                <Tab eventKey="Photo" title="Photo">
                  <div className="row">
                    {member.uploaded_photos != null &&
                      member.uploaded_photos.map((photo) => {
                        if (photo == null || photo?.url == null) return null;
                        return (
                          <div className="col-lg-3 co-md-3 col-sm-3 m-2 content_wrapper">
                            <div className="dash_img">
                              <img
                                // value={name}
                                src={
                                  process.env
                                    .REACT_APP_AWS_RESOURCE_PATH_IMAGES +
                                  photo.url
                                }
                                alt=""
                              />
                              {showAction ? (
                                <div className="dlt_btn">
                                  <button
                                    onClick={() => {
                                      setShowAction(!showAction);
                                    }}
                                    style={{ backgroundColor: "#343a40" }}
                                    className="m-3"
                                  >
                                    <Bs.BsFillTrashFill />
                                  </button>
                                </div>
                              ) : (
                                <div className="dlt_btn">
                                  <p
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Are you sure you want to delete
                                  </p>
                                  <>
                                    <button
                                      className="btn btn-success"
                                      onClick={() => {
                                        setShowAction(!showAction);
                                      }}
                                    >
                                      <Ai.AiOutlineClose />
                                    </button>
                                    <button
                                      className="btn btn-danger"
                                      onClick={() =>
                                        deletePhotoById(photo.name)
                                      }
                                    >
                                      <Bs.BsCheck />
                                    </button>
                                  </>
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "block",
                                width: 700,
                                padding: 30,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                  </div>
                </Tab>
                <Tab eventKey="Video" title="Video">
                  <div className="row">
                    {member.uploaded_videos != null &&
                      member.uploaded_videos.map((video) => {
                        console.log("video", video);
                        if (video == null || video?.url == null) return;
                        console.log(
                          "Video",
                          process.env.REACT_APP_AWS_RESOURCE_PATH_VIDEOS +
                          video?.url
                        );
                        return (
                          <div className="col-lg-4 col-md-4 col-sm-4 mb-3">
                            <VideoPlayer
                              aspectRatio="16:9"
                              url={
                                process.env.REACT_APP_AWS_RESOURCE_PATH_VIDEOS +
                                video?.url
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </Tab>
              </Tabs>
            </Row>
          </div>
        </main>
      </div>
    </>
  );
}

export default ViewMember;
