import React, { useEffect, useState, useContext } from "react";
import useTransactions from "../../hooks/useTransactions";
import { AppContext } from "../../context/AppContext";
import { PuffLoader } from "react-spinners";
import "./Transaction.css";
import Utils from "../../utils/utils";

function CreditTransactionTable({
  memberDetails,
  transactions,
  loading,
  creditsHistory,
}) {
  const findMemberDetailsById = (id) => {
    return memberDetails.find((x) => x._id == id);
  };
  return (
    <>
      <table id="example2" class="table table-bordered table-hover">
        <thead style={{ textAlign: "center" }}>
          <tr>

            <th>Title</th>
            <th>UserName</th>
            <th>Nickname</th>
            <th>Date</th>
            {/* <th>Platform Fee</th> */}
            {/* <th>Vat</th> */}
            <th>Amount(£)</th>
            {/* <th></th> */}
          </tr>
        </thead>
        {memberDetails.length > 0 && transactions.length > 0 && !loading && (
          <tbody>
            {Array.isArray(creditsHistory) &&
              creditsHistory.map((item, index) => {
                const user = findMemberDetailsById(item.userId);
                if (!user) {
                  return null;
                }
                console.log(item, "items");
                return (
                  <tr>
                    <td>
                      {item?.title == "purchasecredit"
                        ? "Credit Purchase"
                        : item?.title ?? "N/A"}
                    </td>
                    <td>{user?.username ?? "N/A"}</td>
                    <td>{user?.nickname ?? "N/A"}</td>
                    <td style={{ textAlign: "center" }}>
                      {item?.paymentshortdate ?? "N/A"}
                    </td>
                    {/* <td>{item.stripefee}</td> */}
                    {/* <td>{item?.vat ?? "N/A"}</td> */}
                    <td style={{ textAlign: "center" }}>
                      {item.title == "subscription"
                        ? Utils.formatDecimal(item.total)
                        : Utils.formatDecimal(item.stripefee + item.amount)}
                    </td>
                    {/* <td>{item.currency}</td> */}
                  </tr>
                );
              })}
          </tbody>
        )}
        <tfoot></tfoot>
      </table>

      {loading && (
        <div class="loader_">
          <PuffLoader
            color="#eb100c"
            loading
            size={70}
            className="puff_loader"
          />
        </div>
      )}
    </>
  );
}

export default CreditTransactionTable;
