import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SocketContextProvider } from "./context/SocketContext";
import { AppContextProvider } from "./context/AppContext";

ReactDOM.render(
  <SocketContextProvider>
    <AppContextProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AppContextProvider>
  </SocketContextProvider>,
  document.getElementById("root")
);

reportWebVitals();
