import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import { useHistory } from "react-router";
import { FaExchangeAlt, FaPoundSign, FaGifts } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import { Turn as Hamburger } from "hamburger-react";
import useLocalStorage from "../../hooks/useLocalStorage";

function Sidebar(props) {
  const { getLoginResponseFromLocal } = useLocalStorage();
  const storageValue = getLoginResponseFromLocal();
  const loggedUserRole = storageValue?.role;

  useEffect(() => {
    console.log("loggedUserRole", loggedUserRole);
  }, []);

  const [sidebarActive, setSidebarActive] = useState(props.currentPage);
  const [checked, setChecked] = useState(true);
  const frontEndUrl = process.env.REACT_APP_FRONT_END_URL;
  const history = useHistory();
  const adminLoginResponse = JSON.parse(localStorage.getItem("adminResponse"));

  const onAllLiveClicked = (e) => {
    e.preventDefault();
    try {
      const adminLoginResponse = JSON.parse(
        localStorage.getItem("adminResponse")
      );
      const loginRespone = {
        message: adminLoginResponse.message,
        accessToken: adminLoginResponse.accessToken,
        refreshToken: adminLoginResponse.refreshToken,
        userId: adminLoginResponse.adminId,
        userName: adminLoginResponse.userName,
        role: adminLoginResponse.role,
        roomID: adminLoginResponse.roomID,
        profilepic: adminLoginResponse.profilepic,
      };

      const base64String = btoa(JSON.stringify(loginRespone));
      const loginUrl = `${frontEndUrl}/alllives/${encodeURIComponent(
        base64String
      )}/123`;
      window.open(loginUrl);
    } catch (e) {}
  };
  const LogOutClick = () => {
    localStorage.removeItem("adminResponse");
    history.push("/");
  };

  const clickCheck = (e) => {
    // console.log("checked", e.target.checked);
    setChecked(!checked);
  };
  return (
    <>
      <div class="hold-transition sidebar-mini">
        <div class="bars">
          <input type="checkbox" name="" id="checkbox" checked={checked} />
          <div class="burger" onClick={clickCheck}>
            <Hamburger />
          </div>
        </div>
        <div class="wrapper">
          <nav class="main-header navbar navbar-expand navbar-white navbar-light">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" onClick={LogOutClick} role="button">
                  <i>Log Out</i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-widget="fullscreen"
                  href="#"
                  role="button"
                >
                  <i class="fas fa-expand-arrows-alt"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <aside class="main-sidebar sidebar-dark-primary ">
          <nav class="mt-2">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {" "}
              <li class="nav-item">
                <a href="" class="nav-link">
                  {/* <i class="nav-icon fas fa-tachometer-alt"></i> */}
                  <div class="image">
                    <img
                      src="/image/image.png"
                      alt="User Image"
                      style={{ height: "70px" }}
                    />
                  </div>
                  <p>Dashboard</p>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="/users"
                  className={`nav-link ${
                    sidebarActive === "users" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-users"></i>
                  <p>Users</p>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="/subscription"
                  className={`nav-link ${
                    sidebarActive === "subscription" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-copy"></i>
                  <p>Subscription Plans</p>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="/emails"
                  className={`nav-link ${
                    sidebarActive === "emails" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-envelope"></i>
                  <p>Messages</p>
                </a>
              </li>
              {loggedUserRole != null && loggedUserRole == "Superadmin" && (
                <>
                  {" "}
                  <li class="nav-item">
                    <a
                      href="/transaction"
                      className={`nav-link ${
                        sidebarActive === "transaction" ? "active" : ""
                      }`}
                    >
                      <i>
                        <FaExchangeAlt />
                      </i>{" "}
                      <p>Transaction History</p>
                    </a>
                  </li>
                </>
              )}
              <li class="nav-item">
                <a
                  href="/categories"
                  className={`nav-link ${
                    sidebarActive === "categories" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-chart-pie"></i>
                  <p>Streaming Categories </p>
                </a>
              </li>
              {/* <li class="nav-item">
                <a
                  href="/subusers"
                  className={`nav-link ${
                    sidebarActive === "subusers" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-users"></i>
                  <p>Subuser</p>
                </a>
              </li> */}
              <li class="nav-item">
                <a
                  href="/advertisement"
                  className={`nav-link ${
                    sidebarActive === "advertisement" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-edit"></i>
                  <p>Advertisement</p>
                </a>
              </li>
              {/* <li class="nav-item">
                <a
                  href="/statistics"
                  className={`nav-link ${
                    sidebarActive === "statistics" ? "active" : ""
                  }`}
                >
                  <i class="nav-icon fas fa-table"></i>
                  <p>Statistics</p>
                </a>
              </li> */}
              {/* {adminLoginResponse.role == "admin" ? (
                <li class="nav-item">
                  <a
                    href="/reports"
                    className={`nav-link ${
                      sidebarActive === "reports" ? "active" : ""
                    }`}
                  >
                    <i class="nav-icon far fa-file"></i>
                    <p>Report</p>
                  </a>
                </li>
              ) : null} */}
              {/* <li class="nav-item">
              <a href="/termsandconditions" className={`nav-link ${sidebarActive === "t_c" ? 'active' : ''}`}>
                <i class="nav-icon far fa-image"></i>
                <p>
                  T&C
                </p>
              </a>
            </li> */}
              <li class="nav-item">
                <a
                  className={`nav-link ${
                    sidebarActive === "lives" ? "active" : ""
                  }`}
                  href="/lives"
                >
                  <i class="nav-icon far fa-image"></i>
                  <p>All Lives</p>
                </a>
              </li>
              {loggedUserRole != null && loggedUserRole == "Superadmin" && (
                <>
                  <li class="nav-item">
                    <a
                      className={`nav-link ${
                        sidebarActive === "payouts" ? "active" : ""
                      }`}
                      href="/payouts"
                    >
                      <i>
                        <FaPoundSign />
                      </i>{" "}
                      <p>Payouts</p>
                    </a>
                  </li>
                </>
              )}
              <li class="nav-item">
                <a
                  className={`nav-link ${
                    sidebarActive === "settings" ? "active" : ""
                  }`}
                  href="/settings"
                >
                  <i>
                    <FaGifts />
                  </i>{" "}
                  <p>Gifting Control</p>
                </a>
              </li>
            </ul>
          </nav>
        </aside>

        <div class={checked ? "mob-nav" : "mob-nav show"}>
          <div class="logo-area">
            <img
              src={require("../../image/mob_logo.png")}
              alt="Create Royale"
            />
          </div>
          <nav>
            <ul class="nav-list">
              <li class="nav-items" data-tooltip="Users">
                <a
                  href="/users"
                  className={sidebarActive === "users" ? "selected" : ""}
                >
                  <i class="nav-icon fas fa-users"></i>
                </a>
              </li>
              <li class="nav-items" data-tooltip="Subscription">
                <a
                  href="/subscription"
                  className={sidebarActive === "subscription" ? "selected" : ""}
                >
                  <i class="nav-icon fas fa-copy"></i>
                </a>
              </li>
              <li class="nav-items" data-tooltip="Email">
                <a
                  href="/emails"
                  className={sidebarActive === "emails" ? "selected" : ""}
                >
                  {" "}
                  <i class="nav-icon fas fa-envelope"></i>
                </a>
              </li>
              <li class="nav-items" data-tooltip="category">
                <a
                  href="/categories"
                  className={sidebarActive === "categories" ? "selected" : ""}
                >
                  <i class="nav-icon fas fa-chart-pie"></i>
                </a>
              </li>
              {/* <li class="nav-items" data-tooltip="Subuser">
                <a
                  href="/subusers"
                  className={sidebarActive === "subusers" ? "selected" : ""}
                >
                  <i class="nav-icon fas fa-users"></i>
                </a>
              </li> */}
              <li class="nav-items" data-tooltip="Advertisement">
                <a
                  href="/advertisement"
                  className={
                    sidebarActive === "advertisement" ? "selected" : ""
                  }
                >
                  <i class="nav-icon fas fa-edit"></i>
                </a>
              </li>
              {/* <li class="nav-items" data-tooltip="Statistics">
                <a
                  href="/statistics"
                  className={sidebarActive === "statistics" ? "selected" : ""}
                >
                  <i class="nav-icon fas fa-table"></i>
                </a>
              </li> */}
              <li class="nav-items" data-tooltip="Reports">
                <a
                  href="/lives"
                  className={sidebarActive === "lives" ? "selected" : ""}
                >
                  <i class="nav-icon far fa-image"></i>
                </a>
              </li>
              {/* <li class="nav-items" data-tooltip="Reports">
                <a
                  href="/reports"
                  className={sidebarActive === "reports" ? "selected" : ""}
                >
                  <i class="nav-icon far fa-file"></i>
                </a>
              </li> */}

              <li class="nav-items" data-tooltip="Payouts">
                <a
                  href="/payouts"
                  className={sidebarActive === "payouts" ? "selected" : ""}
                >
                  <FaPoundSign />
                </a>
              </li>
              <li class="nav-items" data-tooltip="settings">
                <a
                  href="/settings"
                  className={sidebarActive === "settings" ? "selected" : ""}
                >
                  <FaGifts />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
