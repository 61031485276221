import React, { useState, useEffect } from "react";
import $ from "jquery";
import { endpoints } from "../endpoints/endpoint";
import Utils from "../utils/utils";
import toast, { Toaster } from "react-hot-toast";
import format from "date-fns/format";

const usePayouts = () => {
  const [payoutsCollection, setPayoutsCollection] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  
  let currentDate = new Date();
  
  let formattedValue = format(currentDate, 'yyyy-MM');

  const [viewDate, setViewDate] = useState(formattedValue);

  const PAYMENT_PENDING = "PENDING";
  const PAYMENT_APPROVED = "APPROVED";
  const PAYMENT_COMPLETED = "COMPLETED";
  const PAYMENT_RETURNED = "RETURNED";
  const PAYMENT_CANCELLED = "CANCELLED";

  const loginRes = JSON.parse(localStorage.getItem("adminResponse"));

  useEffect(() => getAllPayouts(), [viewDate]);

  const getAllPayouts = (noLoading) => {
    const date = Utils.parseMonthAndYearFromDate(viewDate, 10, 1);
    if (!date) return;
    const payload = { date: date };
    !noLoading && setLoading(true);
    $.ajax({
      url: endpoints.getInvoices,
      dataType: "json",
      contentType: "application/json",
      type: "post",
      data: JSON.stringify(payload),
      headers: {
        Authorization: "Bearer " + loginRes.accessToken,
      },
      timeout: 30000,
      processData: false,
      success: function (response, textStatus, jQxhr) {
        if (!response && !response.Data) return;
        if (!Array.isArray(response.Data)) return;
        setPayouts([...response.Data]);
        setPayoutsCollection([...response.Data]);
      },
      error: function (jqXHR, exception) {
        const error = jqXHR.responseJSON?.message ?? "Something went wrong";
        console.log("Error from getAllPayouts", error);
        throw error;
      },
      complete: function () {
        !noLoading && setLoading(false);
      },
    });
  };

  const filterPayouts = (filter) => {
    let filteredList = [];
    switch (filter) {
      case "ALL":
        setPayouts(payoutsCollection);
        break;
      case PAYMENT_PENDING:
        filteredList = filterByStatus("pending");
        setPayouts([...filteredList]);
        break;
      case PAYMENT_APPROVED:
        filteredList = filterByStatus("approved");
        setPayouts(filteredList);
        break;
      case PAYMENT_CANCELLED:
        filteredList = filterByStatus("cancelled");
        setPayouts(filteredList);
        break;
      case PAYMENT_RETURNED:
        filteredList = filterByStatus("returned");
        setPayouts(filteredList);
        break;
      case PAYMENT_COMPLETED:
        filteredList = filterByStatus("completed");
        setPayouts(filteredList);
        break;
      default:
        setPayouts(payoutsCollection);
    }
  };

  const filterByStatus = (status) => {
    return payoutsCollection.filter((x) => x.payment.toLowerCase() === status);
  };

  const modifyPaymentStatus = (id, status, onSuccess, onError) => {
    let payload = {};
    const errMsg = { message: "Something went wrong", error: true };
    const succMsg = { message: "Your Request has been Succeed", error: true };

    switch (status) {
      case PAYMENT_APPROVED:
      case PAYMENT_CANCELLED:
        setActionLoading(true);
        payload = { invoiceId: id, status: status };
        $.ajax({
          url: endpoints.modifypaymentstatus,
          dataType: "json",
          contentType: "application/json",
          type: "post",
          data: JSON.stringify(payload),
          headers: {
            Authorization: "Bearer " + loginRes.accessToken,
          },
          timeout: 30000,
          processData: false,
          success: function (response, textStatus, jQxhr) {
            setActionLoading(false);
            if (!response.error) {
              onSuccess && onSuccess(succMsg);
              return setTimeout(() => {
                getAllPayouts(true);
              }, 1000);
            }

            throw errMsg;
          },
          error: function (jqXHR, exception) {
            const error = jqXHR.responseJSON;
            const errorMsg = jqXHR.responseJSON?.message;
            setActionLoading(false);
            onError && onError({ error: true, message: getErrorMsg(error) });
            console.error("ERROR RESPONSE", error);
            return setTimeout(() => {
              getAllPayouts(true);
            }, 1000);
          },
        });

        break;
      case PAYMENT_COMPLETED:
      case PAYMENT_RETURNED:
        setActionLoading(true);
        payload = { invoiceId: id };
        console.log("payload", payload);
        $.ajax({
          url: endpoints.transferapproved,
          dataType: "json",
          contentType: "application/json",
          type: "post",
          data: JSON.stringify(payload),
          headers: {
            Authorization: "Bearer " + loginRes.accessToken,
          },
          timeout: 30000,
          processData: false,
          success: function (response, textStatus, jQxhr) {
            setActionLoading(false);
            if (!response.error) {
              onSuccess && onSuccess(succMsg);
              if (!response.error) {
                onSuccess && onSuccess(succMsg);
                return setTimeout(() => {
                  getAllPayouts(true);
                }, 1000);
              }
            }
            throw errMsg;
          },
          error: function (jqXHR, exception) {
            const error = jqXHR.responseJSON;
            const errorMsg = jqXHR.responseJSON?.message;
            setActionLoading(false);
            onError && onError({ error: true, message: getErrorMsg(error) });
            console.error("ERROR RESPONSE", error);
            return setTimeout(() => {
              getAllPayouts(true);
            }, 1000);
          },
        });
    }
  };

  const getErrorMsg = (error) => {
    const { code, param } = error;

    switch (code) {
      case "resource_missing":
        return param == "destination"
          ? "Subscriber doesn't have Connected Account"
          : "Something is missing in Stripe Configuration";
      case "bank_account_restricted":
        return "The Subscriber’s account cannot be used with the payment method.";
      case "bank_account_declined":
        return "The bank account provided can not be used to charge, either because it is not verified yet or it is not supported.";
      case "balance_insufficient":
        return "The transfer could not be completed because the associated account does not have a sufficient balance available.";
      default:
        return "Something went wrong, Please contact support";
    }
  };

  return {
    loading,
    actionLoading,
    viewDate,
    setViewDate,
    getAllPayouts,
    payouts,
    filterPayouts,
    modifyPaymentStatus,
  };
};

export default usePayouts;

export const status = {
  PAYMENT_PENDING: "PENDING",
  PAYMENT_APPROVED: "APPROVED",
  PAYMENT_COMPLETED: "COMPLETED",
  PAYMENT_RETURNED: "RETURNED",
  PAYMENT_CANCELLED: "CANCELLED",
};
