import React, {useState, useEffect} from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Statistics.css'
import $ from "jquery";
import { endpoints } from '../../endpoints/endpoint';

function Statistics() {
    const [liveStatistics, setLiveStatistics] = useState({})
    const [trafficStatistics, setTrafficStatistics] = useState({})
    useEffect(() => {
       listLiveStatistics()
       listTrafficStatistics()
    }, []);
  
    const listLiveStatistics = (event, values) => {
      const obj = JSON.parse(localStorage.getItem("adminResponse"))
      $.ajax({
        url: endpoints.liveStatistics,
        dataType: 'json',
        contentType: 'application/json',
        type: 'get',
        headers: {
          'Authorization': 'Bearer ' + obj.accessToken
        },
        // data: JSON.stringify(payload),
        processData: false,
        success: function( data, textStatus, jQxhr ){
            setLiveStatistics(data)
        },
    });
    }

    const listTrafficStatistics = (event, values) => {
        const obj = JSON.parse(localStorage.getItem("adminResponse"))
        $.ajax({
          url: endpoints.trafficStatistics,
          dataType: 'json',
          contentType: 'application/json',
          type: 'get',
          headers: {
            'Authorization': 'Bearer ' + obj.accessToken
          },
          // data: JSON.stringify(payload),
          processData: false,
          success: function( data, textStatus, jQxhr ){
                setTrafficStatistics(data)
          },
      });
      }
    return (
       <>
       <Sidebar currentPage="statistics"/>
       <div class="content-wrapper" style={{backgroundColor:"white"}}>
        <div class="card-header">
                <h3 style={{fontSize:'20px', fontWeight:'bold'}}>Statistics</h3>
              </div>
        <div class="row" style={{marginLeft:'20px'}}>
            <div class="col" style={{marginTop:'20px'}}>
            <label style={{marginLeft:'20px', marginBottom:'20px'}}>Live Viewers</label>
           
            <div class="card" style={{width:'18rem', backgroundColor:'gray'}}>
                <ul class="list-group list-group-flush" style={{border:'2px solid black'}}>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Today</span> : <span style={{color:'red', fontWeight:'bold'}}>{liveStatistics.todayViewers ? liveStatistics.todayViewers : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>This week</span> : <span style={{color:'red', fontWeight:'bold'}}> {liveStatistics.thisWeekViewers ? liveStatistics.thisWeekViewers : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Last week </span>: <span style={{color:'red', fontWeight:'bold'}}>{liveStatistics.lastWeekViewers ? liveStatistics.lastWeekViewers : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Yesterday </span>: <span style={{color:'red', fontWeight:'bold'}}> {liveStatistics.yesterdayViewers ? liveStatistics.yesterdayViewers : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Last month </span>:<span style={{color:'red', fontWeight:'bold'}}> {liveStatistics.lastMonthViewers ? liveStatistics.lastMonthViewers : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Total</span> : <span style={{color:'red', fontWeight:'bold'}}>{liveStatistics.totalViewers ? liveStatistics.totalViewers : 0}</span></p></li>
                </ul>
            </div>
            </div>
            <div class="col" style={{marginTop:'20px'}}>
            <label style={{marginLeft:'20px', marginBottom:'20px'}}>Traffic</label>
           
            <div class="card" style={{width:'18rem'}}>
                <ul class="list-group list-group-flush" style={{border:'2px solid black'}}>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Today</span> :<span style={{color:'red', fontWeight:'bold'}}>{trafficStatistics.todayTraffic ? trafficStatistics.todayTraffic : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>This week</span> :<span style={{color:'red', fontWeight:'bold'}}>{trafficStatistics.thisWeekTraffic ? trafficStatistics.thisWeekTraffic : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Last week</span> :<span style={{color:'red', fontWeight:'bold'}}>{trafficStatistics.lastWeekTraffic ? trafficStatistics.lastWeekTraffic : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Yesterday </span>:<span style={{color:'red', fontWeight:'bold'}}>{trafficStatistics.yesterdayTraffic ? trafficStatistics.yesterdayTraffic : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Last month </span>:<span style={{color:'red', fontWeight:'bold'}}>{trafficStatistics.lastMonthTraffic ? trafficStatistics.lastMonthTraffic : 0}</span></p></li>
                    <li class="list-group-item"><p><span style={{fontWeight:'bold'}}>Total</span> :<span style={{color:'red', fontWeight:'bold'}}>{trafficStatistics.totalTraffic ? trafficStatistics.totalTraffic : 0}</span></p></li>
                </ul>
            </div>
            </div>
        </div>
        </div>
       </>
    )
}

export default Statistics
