import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Categories.css";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [categoryId, setCategoryId] = useState();
  const [description, setDescription] = useState();
  const [photo, setPhoto] = useState();
  const [file, setFile] = useState();
  const [form, setForm] = useState({});
  const [fillError, setFillError] = useState(false);

  const frontEndUrl = process.env.REACT_APP_AWS_RESOURCE_PATH_IMAGES;

  useEffect(() => {
    listAllCategory();
  }, []);
  const listAllCategory = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));

    $.ajax({
      url: endpoints.listCategory,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        setCategories(data);
        if (data && data.length > 0) {
        }
      },
    });
  };
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const addCategory = (e) => {
    e.preventDefault();
    const { file } = form;
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const bodyFormData = new FormData();
    if (file && category) {
      console.log("file", file);
      bodyFormData.append("file", file);
      bodyFormData.append("categoryname", category);
      $.ajax({
        url: endpoints.addCategory,
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        data: bodyFormData,
        contentType: false,
        processData: false,
        success: function (data, textStatus, jQxhr) {
          setErrorMessage("category added successfully");
          setCategoryId(data.categoryId);
          setTimeout(myFunction, 5000);
          listAllCategory();
        },
      });
    } else {
      setFillError(true);
      setTimeout(removeFillError, 10000);
    }
  };

  const deleteCategory = (categoryId) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    const payload = {
      categoryId: categoryId,
    };
    $.ajax({
      url: endpoints.deleteCategory,
      type: "post",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      data: JSON.stringify(payload),
      contentType: "application/json",
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (data.message == "category deleted") {
          setErrorMessage("category deleted");
          setTimeout(myFunction, 5000);
          listAllCategory();
        } else {
          setErrorMessage("category not deleted");
        }
      },
    });
  };
  const myFunction = () => {
    setErrorMessage("");
  };
  const removeFillError = () => {
    setFillError(false);
  };
  console.log(categories);
  return (
    <>
      <Sidebar currentPage="categories" />
      <div class="content-wrapper" style={{ backgroundColor: "white" }}>
        <button
          type="button"
          class="btn btn-success btn-new"
          data-toggle="modal"
          data-target="#modal-default"
        >
          Add New
        </button>
        <div class="card " style={{ width: "fit-content" }}>
          <div class="card-header">
            <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>Streaming Categories</h3>
          </div>
          <div class="card-body">
            <table id="example2" class="table table-bordered table-hover">
              <thead>
                <tr>
                  {/* <th>SI.No:</th> */}
                  <th>Category Name</th>
                  {/* <th>Description</th> */}
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((item, index) => {
                  return (
                    <tr>
                      {/* <td>{item.SIno}</td> */}
                      <td>{item.categoryname}</td>
                      {/* <td style={{width:"500px"}}>{item.description}</td> */}
                      <td>
                        <img
                          style={{ height: "200px", width: "200px" }}
                          src={frontEndUrl.concat(item.url)}
                        ></img>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => deleteCategory(item._id)}
                          class="btn btn-danger btn-new"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </div>
        <div className="modal fade show" id="modal-default">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Add Category</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div class="modal-body">
                  <label>Category Name</label>
                  <input
                    type="text"
                    name="message"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    placeholder="Category Name"
                    class="form-control"
                    style={{ marginBottom: "10px" }}
                    required
                  />
                  {/* <label>Description</label>
                    <input type="text" name="message" value={description} onChange={(e)=>{setDescription(e.target.value)}}  placeholder="Description" class="form-control"  style={{marginBottom:'10px'}} required/> */}
                  <label>Upload Photo</label>
                  <input
                    type="file"
                    name="message"
                    value={photo}
                    onChange={(e) => setField("file", e.target.files[0])}
                    placeholder="Description"
                    class="form-control"
                    style={{ marginBottom: "10px" }}
                    required
                  />
                  {errorMessage && (
                    <div
                      class="alert alert-success"
                      style={{ height: "50px" }}
                      role="alert"
                    >
                      <p>{errorMessage}</p>
                    </div>
                  )}
                </div>
                {fillError ? (
                  <p style={{ marginLeft: "20px", color: "red" }}>
                    Please fill all the field
                  </p>
                ) : null}
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    onClick={addCategory}
                    class="btn btn-primary"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
