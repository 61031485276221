import { useState } from "react";
import "./Lives.css";
import * as Cg from "react-icons/cg";
import * as Ai from "react-icons/ai";

const LiveVideoCard = (props) => {
  const [elipsis, setElipsis] = useState(false);

  const collapseElipsis = () => {
    document.getElementById("collapse_desc").scrollTo(0, 0);
    setElipsis(!elipsis);
  };

  return (
    <>
      <div className="col-lg-5 col-md-6 col-sm-6">
        <div
          className={
            elipsis ? "card live_cards show p-4" : "card live_cards p-4"
          }
        >
          <div class="live-dot notify-live card-live">
            <Cg.CgMediaLive />
          </div>
          <div class="live-cont d-flex">
            <label for="Title">Title:</label>
            <p>{props.video.stream?.title}</p>
          </div>
          <div class="live-cont d-flex">
            <label for="Streamer">Streamer:</label>
            <p>{props.video?.nickname}</p>
          </div>
          <div class="live-cont description_live d-flex ">
            <label for="Description">Description:</label>
            {props.video.stream?.description &&
            props.video.stream?.description !== "undefined" ? (
              <>
                <p
                  className={`desc ${!elipsis ? "" : "show"}`}
                  id="collapse_desc"
                >
                  {props.video.stream.description}
                </p>{" "}
                <button className="read_more" onClick={collapseElipsis}>
                  {!elipsis ? "read more" : "read less"}
                </button>
              </>
            ) : (
              <p>Not Available</p>
            )}
          </div>
          <div class="live-cont d-flex">
            <button className="live_btn" onClick={props.onClick}>
              View Live
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveVideoCard;
