import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Emails.css";
import $ from "jquery";
import { endpoints } from "../../endpoints/endpoint";
import { Tabs, Tab } from "react-bootstrap";
import Utils from "../../utils/utils";

function Emails() {
  const [userDetails, setUserDetails] = useState([]);
  const [userDetailsCollections, setUserDetailsCollections] = useState([]);
  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [selectedTab, setSelectedTab] = useState("admin");
  const [adminMessage, setAdminMessage] = useState([]);
  const [adminMessageCollection, setAdminMessageCollection] = useState([]);

  useEffect(() => {
    listAllEmails();
    listAdminEmails();
  }, []);

  const listAllEmails = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    $.ajax({
      url: endpoints.allEmails,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (!Array.isArray(data)) return;
        setUserDetails(data);
        setUserDetailsCollections(data);
      },
    });
  };

  const listAdminEmails = (event, values) => {
    const obj = JSON.parse(localStorage.getItem("adminResponse"));
    $.ajax({
      url: endpoints.adminEmails,
      dataType: "json",
      contentType: "application/json",
      type: "get",
      headers: {
        Authorization: "Bearer " + obj.accessToken,
      },
      // data: JSON.stringify(payload),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        if (Array.isArray(data)) {
          setAdminMessage(data);
          setAdminMessageCollection(data);
        }
      },
    });
  };

  const filterMessageByQuery = (query, messages) => {
    console.log("messages", messages);
    if (!Array.isArray(messages) || !query) return;
    const filteredMessages = messages.filter((msg) =>
      msg.subject.includes(query)
    );
    setUserDetails(filteredMessages);
    console.log("filteredMessages", filteredMessages);
  };

  useEffect(() => {
    switch (selectedTab) {
      case "admin":
        setAdminMessage(adminMessageCollection);
        break;
      case "users":
        setUserDetails(userDetailsCollections);
        break;
      case "follows":
        filterMessageByQuery("Follow", userDetailsCollections);
        break;
      case "subscriptions":
        filterMessageByQuery("Subscription", userDetailsCollections);
        break;
      case "reports":
        filterMessageByQuery("Reported", userDetailsCollections);
        break;
    }
  }, [selectedTab]);

  const replyClick = (e) => {
    e.preventDefault();
    if (to && from && subject && message) {
      const obj = JSON.parse(localStorage.getItem("adminResponse"));
      const payload = {
        to: to,
        subject: subject,
        message: message,
      };
      $.ajax({
        url: endpoints.replyMail,
        type: "post",
        headers: {
          Authorization: "Bearer " + obj.accessToken,
        },
        data: JSON.stringify(payload),
        contentType: "application/json",
        processData: false,
        success: function (data, textStatus, jQxhr) {
          if (data.message == "Mail send successfully") {
            setErrorMessage("Mail send successfully");
          } else {
            setErrorMessage("Mail not send ");
          }
        },
      });
    }
  };
  return (
    <>
      <Sidebar currentPage="emails" />
      <div class="content-wrapper">
        <div class="card ">
          <div class="card-header">
            <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>Messages</h3>
          </div>
          <Tabs
            defaultActiveKey="profile"
            style={{ marginTop: "20px" }}
            id="uncontrolled-tab-example"
            activeKey={selectedTab}
            onSelect={(k) => setSelectedTab(k)}
            className="mb-3"
          >
            <Tab eventKey="admin" title="Admin"></Tab>
            <Tab eventKey="users" title="Users"></Tab>
            <Tab eventKey="follows" title="Follow"></Tab>
            <Tab eventKey="subscriptions" title="Subscriptions"></Tab>
            <Tab eventKey="reports" title="Reports"></Tab>
          </Tabs>
          {selectedTab == "users" ||
          selectedTab == "follows" ||
          selectedTab == "subscriptions" ||
          selectedTab == "reports" ? (
            <div class="card-body">
              <table id="example2" class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Subject</th>
                    <th>Message</th>
                    {/* <th>Email</th> */}
                    {/* <th>Status</th> */}
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {userDetails.map((item, index) => {
                    return (
                      <tr>
                        <td>{Utils.checkAndFormatDate(item.date)}</td>
                        <td>{item.subject}</td>
                        <td style={{ width: "520px" }}>{item.text}</td>
                        {/* <td>{item.mail}</td> */}
                        {/* <td>{item.status}</td> */}
                        {/* <td>
                        <button type="button" class="btn btn-success btn-new" data-toggle="modal" data-target="#modal-default">Reply</button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          ) : null}
          {selectedTab == "admin" ? (
            <div class="card-body">
              <table id="example2" class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Subject</th>
                    <th>Phone number</th>
                    <th>Address</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {adminMessage.map((item, index) => {
                    console.log("item", typeof item.date);
                    return (
                      <tr>
                        <td>{Utils.checkAndFormatDate(item.date)}</td>
                        <td>{item.name ? item.name : null}</td>
                        <td>{item.email ? item.email : null}</td>
                        <td>{item.subject ? item.subject : null}</td>
                        <td>{item.phonenumber ? item.phonenumber : null}</td>
                        <td>{item.address ? item.address : null}</td>
                        <td>{item.message ? item.message : null}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          ) : null}
        </div>
        <div className="modal fade show" id="modal-default">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Reply</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div class="modal-body">
                  <label>To</label>
                  <input
                    type="email"
                    name="message"
                    value={to}
                    onChange={(e) => {
                      setTo(e.target.value);
                    }}
                    placeholder="To"
                    class="form-control"
                    style={{ marginBottom: "10px" }}
                    required
                  />
                  <label>From</label>
                  <input
                    type="text"
                    name="message"
                    value={from}
                    onChange={(e) => {
                      setFrom(e.target.value);
                    }}
                    placeholder="From"
                    class="form-control"
                    style={{ marginBottom: "10px" }}
                    required
                  />
                  <label>Subject</label>
                  <input
                    type="text"
                    name="message"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    placeholder="Subject"
                    class="form-control"
                    required
                  />
                  <label>Message</label>
                  <textarea
                    type="text"
                    name="message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    placeholder="Message"
                    style={{ marginBottom: "10px" }}
                    class="form-control"
                    required
                  />
                  {errorMessage && (
                    <div
                      class="alert alert-success"
                      style={{ height: "50px" }}
                      role="alert"
                    >
                      <p>{errorMessage}</p>
                    </div>
                  )}
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    value="Submit"
                    class="btn btn-primary"
                    onClick={replyClick}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Emails;
